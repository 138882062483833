import { Component, OnInit } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../../services/user.service';
import {FormCreatorService} from '../../../../services/form-creator.service';
import Swal from 'sweetalert2';
import {Spinner} from 'ngx-spinner/lib/ngx-spinner.enum';
import {NgxSpinnerService} from 'ngx-spinner';
import {FormControl, FormGroup} from '@angular/forms';
import {ConfigService} from '../../../../services/config.service';
import {LoginService} from "../../../../services/login.service";



@Component({
  selector: 'app-auto-inscription',
  templateUrl: './auto-inscription.component.html',
  styleUrls: ['./auto-inscription.component.css']
})
export class AutoInscriptionComponent implements OnInit {
  passwordIsValid = false;
  // tslint:disable-next-line:variable-name
  public forms: any;
  // tslint:disable-next-line:variable-name
  public group_id: any;
  public countries: any;
  // tslint:disable-next-line:variable-name
  public group_id_check: any;
  loadingAdd: boolean;
  showSSOMEssage: boolean;
  // tslint:disable-next-line:max-line-length variable-name
  public profile_id = parseInt(localStorage.getItem('profile_id'));

  login = new FormControl();
  firstname = new FormControl();
  lastname = new FormControl();
  password = new FormControl();
  email = new FormControl();
  // tslint:disable-next-line:variable-name
  confirm_password = new FormControl();
  // tslint:disable-next-line:variable-name
  public nlmk_user = {
    login: null,
    firstname: null,
    lastname: null,
    password: null,
    confirm_password: null,
    start_date: null,
    end_date: null,
    site_id: null,
    email: null,
    profile_id: 3,
    custom_level_id: 0,
    group_id: null,
    field20_details: {
      cid: null,
      registrenational: null,
      // nationalite: null,
      // sexe: null,
      // travailleuretranger: null,
      datenaissance: null,
      // gsm: null,
      // plaquevehicule: null,
      employeur: null,
      // employeurtva: null,
      // fonction: null,
      limosa: null,
      e101: null,
      // brevets: [],
      site_user: null,
      manageremail: null,
      unite_service: null,
      numero_installation: null,
      service: null,
    }
  };
  // tslint:disable-next-line:max-line-length variable-name
  public url_details: string;
  // tslint:disable-next-line:variable-name
  public show_nlk_form: boolean = false;
  public show_auto_inscription_form: boolean = false;
  public show_auto_inscription_form_BASF: boolean = false;
  public show_auto_inscription_form_BASF_Lyon: boolean = false;
  public show_form_home_security: boolean = false;
  public show_auto_inscription_form_ORIL_FR: boolean = false;
  public show_auto_inscription_form_ORIL_EN: boolean = false;
  public show_form_AS_SACLAY: boolean = false;
  public show_form_AS_FONTENAY: boolean = false;

  public afficherInfoBulle: boolean = false;

  // tslint:disable-next-line:max-line-length
  public token: any;
  // tslint:disable-next-line:max-line-length variable-name
  public content_form: any;
  // tslint:disable-next-line:max-line-length variable-name
  public form_site_id: any;
  // tslint:disable-next-line:max-line-length
  showLimosaAndE101 = false;
  // tslint:disable-next-line:max-line-length
  choose: any;
  public entityType = this.configService.entityType;

  // tslint:disable-next-line:max-line-length variable-name radix
  constructor(private loginService: LoginService, private configService: ConfigService, private spinner: NgxSpinnerService, public meta: Meta, public pageTitle: Title, private activatedRoute: ActivatedRoute, private router: Router, private userService: UserService, private formCreatorService: FormCreatorService) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.token = params.token;
      this.group_id_check = params.group;
    });
  }

  ngOnInit() {
    this.get_form(this.token);
    this.get_country();
  }

  getCurrentDate() {
    // tslint:disable-next-line:prefer-const
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    return dd + '-' + mm + '-' + yyyy;
  }

  get_form(token) {
    this.formCreatorService.getOneForm(token)
      .subscribe(res => {
        this.group_id = res.data.group_id;
        this.form_site_id = res.data.form_site_id;
        this.forms = res.data;
        if (this.entityType == 'IP') {
          if (res.success === false) {
            this.router.navigate(['/']);
            return false;
          } else {
            this.form_site_id = res.data.site_id;
            if (res.data.site_id === 23) {
              this.show_nlk_form = true;
            } else {
              this.show_nlk_form = false;
              this.form_site_id = res.data.site_id;
              if (res.data.content !== '') {
                this.content_form = JSON.parse(res.data.content);
              }
            }
          }
        } else {
          if (res.success === false) {
            this.router.navigate(['/']);
            return false;
          } else {
            this.form_site_id = res.data.site_id;
            if (res.data.site_id === 23) {
              this.show_nlk_form = true;
            } else if (res.data.site_id === 58) {
              if (this.group_id == 3019) {
                this.show_auto_inscription_form_BASF = true;
              } else if (this.group_id == 3668) {
                this.show_auto_inscription_form_BASF_Lyon = true;
              }
            } else if (res.data.site_id === 11) {
              this.show_form_home_security = true;
            } else if (res.data.site_id === 3) {
              //ORIL form FR
              if (this.group_id === 3471) {
                this.show_form_AS_SACLAY = true;
              }
              //ORIL form EN
              else {
                this.show_form_AS_FONTENAY = true;
              }
            } else if (res.data.site_id === 48) {
              //ORIL form FR
              if (this.group_id === 2333) {
                this.show_auto_inscription_form_ORIL_FR = true;
              }
              //ORIL form EN
              else {
                this.show_auto_inscription_form_ORIL_EN = true;
              }
            } else {
              this.show_auto_inscription_form = true;
              this.form_site_id = res.data.site_id;
              if (res.data.content !== '') {
                this.content_form = JSON.parse(res.data.content);
              }
            }
          }
        }
      }, (err) => {
        console.log(err);
      });
  }

  get_country() {
    this.formCreatorService.getCountryList()
      .subscribe(res => {
        this.countries = res.data;
      }, (err) => {
        console.log(err);
      });
  }

  auto_form_registration_nlmk() {
    this.spinner.show();
    if (
      this.nlmk_user.lastname === null || this.nlmk_user.lastname == undefined || this.nlmk_user.lastname == ''
      || this.nlmk_user.firstname === null || this.nlmk_user.firstname == undefined || this.nlmk_user.firstname == ''
      || this.nlmk_user.field20_details.datenaissance === null || this.nlmk_user.field20_details.datenaissance == undefined || this.nlmk_user.field20_details.datenaissance == ''
      || this.nlmk_user.field20_details.employeur === null || this.nlmk_user.field20_details.employeur == undefined || this.nlmk_user.field20_details.employeur == ''
    ) {
      this.spinner.hide();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Vérifiez vos saisies, les champs avec la mention (*) sont obligatoires '
      });
    } else {
      const body = {
        login: this.nlmk_user.login,
        firstname: this.nlmk_user.firstname,
        lastname: this.nlmk_user.lastname,
        password: this.nlmk_user.password,
        start_date: this.getCurrentDate(),
        end_date: null,
        site_id: this.form_site_id,
        email: this.nlmk_user.email,
        profile_id: 3,
        custom_level_id: 0,
        group_id: this.group_id,
        group_id_check: this.group_id_check,
        token: this.token,
        field20_details: {
          cid: this.nlmk_user.field20_details.cid,
          registrenational: this.nlmk_user.field20_details.registrenational,
          datenaissance: this.nlmk_user.field20_details.datenaissance,
          employeur: this.nlmk_user.field20_details.employeur,
          limosa: this.nlmk_user.field20_details.limosa,
          e101: this.nlmk_user.field20_details.e101,
          site_user: this.nlmk_user.field20_details.site_user,
          group_id: this.group_id,
        }
      };
      this.formCreatorService.createFormNlmk(body)
        .subscribe(res => {
          if (res.success === true) {
            this.spinner.hide();
            this.nlmk_user.login = '';
            this.nlmk_user.firstname = '';
            this.nlmk_user.lastname = '';
            this.nlmk_user.password = '';
            this.nlmk_user.start_date = '';
            this.nlmk_user.end_date = '';
            this.nlmk_user.site_id = '';
            this.nlmk_user.email = '';
            // @ts-ignore
            this.nlmk_user.profile_id = '';
            // @ts-ignore
            this.nlmk_user.custom_level_id = '';
            this.nlmk_user.field20_details.cid = '';
            this.nlmk_user.field20_details.registrenational = '';
            this.nlmk_user.field20_details.datenaissance = '';
            this.nlmk_user.field20_details.employeur = '';
            this.sso_certikap_new(res.login);
          }
          if (res.success === false) {
            this.nlmk_user.login = '';
            this.nlmk_user.firstname = '';
            this.nlmk_user.lastname = '';
            this.nlmk_user.password = '';
            this.nlmk_user.start_date = '';
            this.nlmk_user.end_date = '';
            this.nlmk_user.site_id = '';
            this.nlmk_user.email = '';
            // @ts-ignore
            this.nlmk_user.profile_id = '';
            // @ts-ignore
            this.nlmk_user.custom_level_id = '';
            this.nlmk_user.field20_details.cid = '';
            this.nlmk_user.field20_details.registrenational = '';
            // this.nlmk_user.field20_details.nationalite = '';
            // this.nlmk_user.field20_details.sexe = '';
            // this.nlmk_user.field20_details.travailleuretranger = '';
            this.nlmk_user.field20_details.datenaissance = '';
            // this.nlmk_user.field20_details.gsm = '';
            // this.nlmk_user.field20_details.plaquevehicule = '';
            this.nlmk_user.field20_details.employeur = '';
            // this.nlmk_user.field20_details.employeurtva = '';
            // this.nlmk_user.field20_details.fonction = '';
            // this.nlmk_user.field20_details.brevets = [];
            this.spinner.hide();
            Swal.fire({
              icon: 'error',
              title: 'Erreur...',
              text: 'Veuillez essayer à nouveau '
            });
          }
        }, (err) => {
          console.log(err);
        });
    }
  }

  makeRandomPassword() {
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890,./;\'[]\=-)(*&^%$#@!~`';
    const lengthOfCode = 40;
    let text = '';
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  passwordValid(event) {
    this.passwordIsValid = event;
  }

  isStranger(event) {
    this.choose = event.target.value;
    if (this.choose === 1) {
      this.showLimosaAndE101 = true;
    } else {
      this.showLimosaAndE101 = false;
    }

  }

  sso_certikap() {
    this.showSSOMEssage = true;
    this.spinner.show();
    const body = {
      // ssoKey : 'h-kaptitude'
      ssoKey: this.form_site_id === 23 ? this.nlmk_user.login : this.login.value
    };
    this.formCreatorService.sso_connexion_form_redirection(body)
      .subscribe(res => {
        // @ts-ignore
        if (res.sso_data === '') {
          this.spinner.hide();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Votre compte est inconnu sur Talensoft, veuillez contacter l\'administrateur pour plus détails.',
            footer: '<a href>Equipe Kaptitude</a>'
          });
          return false;
        }
        this.spinner.hide();
        this.showSSOMEssage = false;
        // @ts-ignore
        window.location.replace(this.configService.ELMS_url + 'sso.php?skey=' + res.sso_data);

      }, (err) => {
        console.log(err);
      });
  }

  sso_certikap_nlmk(login) {
    this.showSSOMEssage = true;
    this.spinner.show();
    const body = {
      // ssoKey : 'h-kaptitude'
      ssoKey: login
    };
    this.formCreatorService.sso_connexion_form_redirection(body)
      .subscribe(res => {
        // @ts-ignore
        if (res.sso_data === '') {
          this.spinner.hide();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Votre compte est inconnu sur Talensoft, veuillez contacter l\'administrateur pour plus détails.',
            footer: '<a href>Equipe Kaptitude</a>'
          });
          return false;
        }
        this.spinner.hide();
        this.showSSOMEssage = false;
        // @ts-ignore
        window.location.replace(this.configService.ELMS_url + 'sso.php?skey=' + res.sso_data);

      }, (err) => {
        console.log(err);
      });
  }

  auto_form_registration_other() {
    this.spinner.show();
    if (typeof this.content_form !== 'undefined') {
      // tslint:disable-next-line:variable-name
      // tslint:disable-next-line:variable-name
      for (const form_element of this.content_form) {
        // tslint:disable-next-line:max-line-length
        if (form_element.is_required === 1
          && (document.getElementById(form_element.ng_model_name) as HTMLInputElement).value.length === 0) {
          // tslint:disable-next-line:max-line-length
          // if () {
          this.spinner.hide();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Vérifiez vos saisies, les champs avec la mention (*) sont obligatoires '
          });
          this.loadingAdd = false;
          return;
        }
      }
      if (this.password.value !== this.confirm_password.value) {
        this.spinner.hide();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Mot de passe non identique !'
        });
        this.loadingAdd = false;
        return false;
      } else if (this.lastname.value === null
        || this.lastname.value.length === 0
        || this.password.value === null
        || this.password.value.length === 0
        || this.login.value === null
        || this.login.value.length === 0
        || this.email.value === null
        || this.email.value.length === 0
        || this.firstname.value === null
        || this.firstname.value.length === 0) {
        this.spinner.hide();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Vérifiez vos saisies, les champs avec la mention (*) sont obligatoires '
        });
        this.loadingAdd = false;
        return false;
      } else {
        const body = {
          // (document.getElementById('societe') as HTMLInputElement).value
          login: this.login.value,
          firstname: this.firstname.value,
          lastname: this.lastname.value,
          password: this.password.value,
          start_date: this.getCurrentDate(),
          end_date: null,
          site_id: this.form_site_id,
          email: this.email.value,
          profile_id: 3,
          custom_level_id: 0,
          group_id: this.group_id,
          group_id_check: this.group_id_check,
          token: this.token,
          other_fields: {
            // tslint:disable-next-line:max-line-length
            service: (document.getElementById('service') as HTMLInputElement) ? (document.getElementById('service') as HTMLInputElement).value : '',
            // tslint:disable-next-line:max-line-length
            rppsadeli: (document.getElementById('rppsadeli') as HTMLInputElement) ? (document.getElementById('rppsadeli') as HTMLInputElement).value : '',
            // tslint:disable-next-line:max-line-length
            matricule: (document.getElementById('matricule') as HTMLInputElement) ? (document.getElementById('matricule') as HTMLInputElement).value : '',
            // tslint:disable-next-line:max-line-length
            societe: (document.getElementById('societe') as HTMLInputElement) ? (document.getElementById('societe') as HTMLInputElement).value : '',
            // tslint:disable-next-line:max-line-length
            ville: (document.getElementById('ville') as HTMLInputElement) ? (document.getElementById('ville') as HTMLInputElement).value : '',
            // tslint:disable-next-line:max-line-length
            managernom: (document.getElementById('managernom') as HTMLInputElement) ? (document.getElementById('managernom') as HTMLInputElement).value : '',
            // tslint:disable-next-line:max-line-length
            managerprenom: (document.getElementById('managerprenom') as HTMLInputElement) ? (document.getElementById('managerprenom') as HTMLInputElement).value : '',
            // tslint:disable-next-line:max-line-length
            manageremail: (document.getElementById('manageremail') as HTMLInputElement) ? (document.getElementById('manageremail') as HTMLInputElement).value : '',
            // tslint:disable-next-line:max-line-length
            statusprofession: (document.getElementById('statusprofession') as HTMLInputElement) ? (document.getElementById('statusprofession') as HTMLInputElement).value : '',
            // tslint:disable-next-line:max-line-length
            fonction: (document.getElementById('fonction') as HTMLInputElement) ? (document.getElementById('fonction') as HTMLInputElement).value : '',
            // tslint:disable-next-line:max-line-length
            date_de_naissance: (document.getElementById('date_de_naissance') as HTMLInputElement) ? (document.getElementById('date_de_naissance') as HTMLInputElement).value : '',
            // tslint:disable-next-line:max-line-length
            adresse1: (document.getElementById('adresse1') as HTMLInputElement) ? (document.getElementById('adresse1') as HTMLInputElement).value : '',
            // tslint:disable-next-line:max-line-length
            adresse2: (document.getElementById('adresse2') as HTMLInputElement) ? (document.getElementById('adresse2') as HTMLInputElement).value : '',
            // tslint:disable-next-line:max-line-length
            codepostal: (document.getElementById('codepostal') as HTMLInputElement) ? (document.getElementById('codepostal') as HTMLInputElement).value : '',
            pays: (document.getElementById('pays') as HTMLInputElement) ? (document.getElementById('pays') as HTMLInputElement).value : '',
            // tslint:disable-next-line:max-line-length
            unite_service: (document.getElementById('unite_service') as HTMLInputElement) ? (document.getElementById('unite_service') as HTMLInputElement).value : '',
            // tslint:disable-next-line:max-line-length
            mode_exercice_dominant: (document.getElementById('mode_exercice_dominant') as HTMLInputElement) ? (document.getElementById('mode_exercice_dominant') as HTMLInputElement).value : '',
            // tslint:disable-next-line:max-line-length
            numero_installation: (document.getElementById('numero_installation') as HTMLInputElement) ? (document.getElementById('numero_installation') as HTMLInputElement).value : '',
            // tslint:disable-next-line:max-line-length
            nom_naissance_diferent: (document.getElementById('nom_naissance_diferent') as HTMLInputElement) ? (document.getElementById('nom_naissance_diferent') as HTMLInputElement).value : '',
            // tslint:disable-next-line:max-line-length
            profession: (document.getElementById('profession') as HTMLInputElement) ? (document.getElementById('profession') as HTMLInputElement).value : '',
          }
        };
        this.formCreatorService.createFormAutoInscription(body)
          .subscribe(res => {
            if (res.success === true) {
              this.spinner.hide();
              Swal.fire({
                title: 'Bravo !',
                text: res.message,
                icon: 'success',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Se connecter',
                cancelButtonText: 'Annuler'
              }).then((result) => {
                if (result.isConfirmed) {
                  this.sso_certikap();
                }
              });
            }
            if (res.success === false) {
              this.spinner.hide();
              Swal.fire({
                icon: 'error',
                title: 'Erreur...',
                text: res.message,
              });
            }
          }, (err) => {
            console.log(err);
          });
      }
    } else {
      // Field de base
      if (this.password.value !== this.confirm_password.value) {
        this.spinner.hide();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Mot de passe non identique !'
        });
        this.loadingAdd = false;
        return false;
      } else if (this.lastname.value === null
        || this.password.value === null
        || this.login.value === null
        || this.email.value === null
        || this.firstname.value === null) {
        this.spinner.hide();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Vérifiez vos saisies, les champs avec la mention (*) sont obligatoires '
        });
        this.loadingAdd = false;
        return false;
      } else {
        const body = {
          // (document.getElementById('societe') as HTMLInputElement).value
          login: this.login.value,
          firstname: this.firstname.value,
          lastname: this.lastname.value,
          password: this.password.value,
          start_date: this.getCurrentDate(),
          end_date: null,
          site_id: this.form_site_id,
          email: this.email.value,
          profile_id: 3,
          custom_level_id: 0,
          group_id: this.group_id,
          group_id_check: this.group_id_check,
          token: this.token,
          other_fields: {
            // tslint:disable-next-line:max-line-length
            service: (document.getElementById('service') as HTMLInputElement) ? (document.getElementById('service') as HTMLInputElement).value : '',
            // tslint:disable-next-line:max-line-length
            rppsadeli: (document.getElementById('rppsadeli') as HTMLInputElement) ? (document.getElementById('rppsadeli') as HTMLInputElement).value : '',
            // tslint:disable-next-line:max-line-length
            matricule: (document.getElementById('matricule') as HTMLInputElement) ? (document.getElementById('matricule') as HTMLInputElement).value : '',
            // tslint:disable-next-line:max-line-length
            societe: (document.getElementById('societe') as HTMLInputElement) ? (document.getElementById('societe') as HTMLInputElement).value : '',
            // tslint:disable-next-line:max-line-length
            ville: (document.getElementById('ville') as HTMLInputElement) ? (document.getElementById('ville') as HTMLInputElement).value : '',
            // tslint:disable-next-line:max-line-length
            managernom: (document.getElementById('managernom') as HTMLInputElement) ? (document.getElementById('managernom') as HTMLInputElement).value : '',
            // tslint:disable-next-line:max-line-length
            managerprenom: (document.getElementById('managerprenom') as HTMLInputElement) ? (document.getElementById('managerprenom') as HTMLInputElement).value : '',
            // tslint:disable-next-line:max-line-length
            manageremail: (document.getElementById('manageremail') as HTMLInputElement) ? (document.getElementById('manageremail') as HTMLInputElement).value : '',
            // tslint:disable-next-line:max-line-length
            statusprofession: (document.getElementById('statusprofession') as HTMLInputElement) ? (document.getElementById('manageremail') as HTMLInputElement).value : '',
            // tslint:disable-next-line:max-line-length
            fonction: (document.getElementById('fonction') as HTMLInputElement) ? (document.getElementById('fonction') as HTMLInputElement).value : '',
          }
        };
        this.formCreatorService.createFormAutoInscription(body)
          .subscribe(res => {
            if (res.success === true) {
              this.spinner.hide();
              Swal.fire({
                title: 'Bravo !',
                text: res.message,
                icon: 'success',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Se connecter',
                cancelButtonText: 'Annuler'
              }).then((result) => {
                if (result.isConfirmed) {
                  this.sso_certikap();
                }
              });
            }
            if (res.success === false) {
              this.spinner.hide();
              Swal.fire({
                icon: 'error',
                title: 'Erreur...',
                text: 'Veuillez essayer à nouveau '
              });
            }
          }, (err) => {
            console.log(err);
          });
      }
    }
  }

  sso_certikap_new(login) {
    this.spinner.show();
    const body = {
      ssoKey: login
    };
    this.formCreatorService.sso_connexion_form_redirection(body)
      .subscribe(res => {
        // @ts-ignore
        if (res.sso_data === '') {
          this.spinner.hide();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Vous n\'avez pas de droit à ce contenu, veuillez contacter l\'administrateur pour plus détails.',
            footer: '<a href>Equipe Kaptitude</a>'
          });

          return false;
        } else {
          // @ts-ignore
          setTimeout(() => {
            this.configService.redirectTools();
          }, 500); // délai de 2000 ms (2 secondes)
          setTimeout(() => {
            this.sso_login_to_certikap(login);
          }, 1000); // délai de 2000 ms (2 secondes)
        }
      }, (err) => {
        console.log(err);
      });
  }

  sso_login_to_certikap(login) {
    this.spinner.show();
    const body = {
      login: login,
    };
    this.loginService.connexion_with_login(body)
      .subscribe(res => {
        // @ts-ignore
        if (res.status === 'success') {
          this.loginService.logout_take_in_hand();
          localStorage.removeItem('currentUser');
          localStorage.removeItem('tokenCurrentConnected');
          // @ts-ignore
          localStorage.setItem('currentUser', JSON.stringify(res));
          // @ts-ignore
          localStorage.setItem('tokenCurrentConnected', JSON.stringify(res.token));
          // @ts-ignore
          this.loginService.currentUserSubject.next(res);
          // @ts-ignore
          localStorage.setItem('profile_id', res.profile_id);
          // @ts-ignore
          localStorage.setItem('site_id', res.site_id);
          // @ts-ignore
          this.configService.redirectTools();
          window.location.reload();
          return res;

        } else {
          this.router.navigate(['/']);
          this.spinner.hide();
        }
      }, (err) => {
        this.router.navigate(['/']);
        this.spinner.hide();
      });
  }

  auto_form_registration_basf() {
    this.spinner.show();
    if (
      this.nlmk_user.lastname === null
      || this.nlmk_user.firstname === null
      || this.nlmk_user.field20_details.datenaissance === null
      || this.nlmk_user.field20_details.employeur === null
      || this.nlmk_user.email === null
    ) {
      this.spinner.hide();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Vérifiez vos saisies, les champs avec la mention (*) sont obligatoires '
      });
    } else if (!this.nlmk_user.email.includes('@')) {
      this.spinner.hide();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Vérifiez votre adresse mail'
      });
    } else {
      const body = {
        login: this.nlmk_user.login,
        firstname: this.nlmk_user.firstname,
        lastname: this.nlmk_user.lastname,
        password: this.nlmk_user.password,
        start_date: this.getCurrentDate(),
        end_date: null,
        site_id: this.form_site_id,
        email: this.nlmk_user.email,
        profile_id: 3,
        custom_level_id: 0,
        group_id: this.group_id,
        group_id_check: this.group_id_check,
        token: this.token,
        field20_details: {
          cid: this.nlmk_user.field20_details.cid,
          registrenational: this.nlmk_user.field20_details.registrenational,
          datenaissance: this.nlmk_user.field20_details.datenaissance,
          employeur: this.nlmk_user.field20_details.employeur,
          manageremail: this.nlmk_user.field20_details.manageremail,
          limosa: this.nlmk_user.field20_details.limosa,
          e101: this.nlmk_user.field20_details.e101,
          site_user: this.nlmk_user.field20_details.site_user,
          group_id: this.group_id,
        }
      };
      this.formCreatorService.createFormNlmk(body)
        .subscribe(res => {
          if (res.success === true) {
            this.spinner.hide();
            this.nlmk_user.login = '';
            this.nlmk_user.firstname = '';
            this.nlmk_user.lastname = '';
            this.nlmk_user.password = '';
            this.nlmk_user.start_date = '';
            this.nlmk_user.end_date = '';
            this.nlmk_user.site_id = '';
            this.nlmk_user.email = '';
            // @ts-ignore
            this.nlmk_user.profile_id = '';
            // @ts-ignore
            this.nlmk_user.custom_level_id = '';
            this.nlmk_user.field20_details.cid = '';
            this.nlmk_user.field20_details.registrenational = '';
            this.nlmk_user.field20_details.datenaissance = '';
            this.nlmk_user.field20_details.employeur = '';
            this.nlmk_user.field20_details.manageremail = '';

            this.sso_certikap_new(res.login);
          }
          if (res.success === false) {
            this.nlmk_user.login = '';
            this.nlmk_user.firstname = '';
            this.nlmk_user.lastname = '';
            this.nlmk_user.password = '';
            this.nlmk_user.start_date = '';
            this.nlmk_user.end_date = '';
            this.nlmk_user.site_id = '';
            this.nlmk_user.email = '';
            // @ts-ignore
            this.nlmk_user.profile_id = '';
            // @ts-ignore
            this.nlmk_user.custom_level_id = '';
            this.nlmk_user.field20_details.cid = '';
            this.nlmk_user.field20_details.registrenational = '';
            this.nlmk_user.field20_details.datenaissance = '';
            this.nlmk_user.field20_details.employeur = '';
            this.nlmk_user.field20_details.manageremail = '';
            this.spinner.hide();
            Swal.fire({
              icon: 'error',
              title: 'Erreur...',
              text: 'Veuillez essayer à nouveau '
            });
          }
        }, (err) => {
          console.log(err);
        });
    }
  }

  //register for form ORIL
  auto_form_registration_ORIL_FR() {
    this.spinner.show();
    if (
      this.nlmk_user.lastname === null
      || this.nlmk_user.firstname === null
      || this.nlmk_user.field20_details.datenaissance === null
      || this.nlmk_user.field20_details.employeur === null
      || this.nlmk_user.email === null
      || this.nlmk_user.field20_details.manageremail === null
    ) {
      this.spinner.hide();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Vérifiez vos saisies, les champs avec la mention (*) sont obligatoires '
      });
    } else if (!this.nlmk_user.email.includes('@')) {
      this.spinner.hide();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Vérifiez votre adresse mail'
      });
    } else if (!this.nlmk_user.field20_details.manageremail.includes('@')) {
      this.spinner.hide();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Vérifiez  l\'adresse mail du manager'
      });
    } else {
      const body = {
        login: this.nlmk_user.login,
        firstname: this.nlmk_user.firstname,
        lastname: this.nlmk_user.lastname,
        password: this.nlmk_user.password,
        start_date: this.getCurrentDate(),
        end_date: null,
        site_id: this.form_site_id,
        email: this.nlmk_user.email,
        profile_id: 3,
        custom_level_id: 0,
        group_id: this.group_id,
        group_id_check: this.group_id_check,
        token: this.token,
        field20_details: {
          cid: this.nlmk_user.field20_details.cid,
          registrenational: this.nlmk_user.field20_details.registrenational,
          datenaissance: this.nlmk_user.field20_details.datenaissance,
          employeur: this.nlmk_user.field20_details.employeur,
          manageremail: this.nlmk_user.field20_details.manageremail,
          limosa: this.nlmk_user.field20_details.limosa,
          e101: this.nlmk_user.field20_details.e101,
          site_user: this.nlmk_user.field20_details.site_user,
          group_id: this.group_id,
        }
      };
      this.formCreatorService.createFormNlmk(body)
        .subscribe(res => {
          this.nlmk_user.firstname = '';
          this.nlmk_user.lastname = '';
          this.nlmk_user.email = '';
          this.nlmk_user.field20_details.datenaissance = '';
          this.nlmk_user.field20_details.employeur = '';
          this.nlmk_user.field20_details.manageremail = '';
          if (res.success === true) {
            this.sso_certikap_new(res.login);
          } else {
            this.spinner.hide();
            Swal.fire({
              icon: 'error',
              title: 'Erreur...',
              text: 'Veuillez essayer à nouveau '
            });
          }
        }, (err) => {
          console.log(err);
        });
    }
  }

  auto_form_registration_ORIL_EN() {
    this.spinner.show();
    if (
      this.nlmk_user.lastname === null
      || this.nlmk_user.firstname === null
      || this.nlmk_user.field20_details.datenaissance === null
      || this.nlmk_user.field20_details.employeur === null
      || this.nlmk_user.email === null
      || this.nlmk_user.field20_details.manageremail === null
    ) {
      this.spinner.hide();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Vérifiez vos saisies, les champs avec la mention (*) sont obligatoires '
      });
    } else if (!this.nlmk_user.email.includes('@')) {
      this.spinner.hide();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Vérifiez votre adresse mail'
      });
    } else if (!this.nlmk_user.field20_details.manageremail.includes('@')) {
      this.spinner.hide();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Vérifiez  l\'adresse mail du manager'
      });
    } else {
      const body = {
        login: this.nlmk_user.login,
        firstname: this.nlmk_user.firstname,
        lastname: this.nlmk_user.lastname,
        password: this.nlmk_user.password,
        start_date: this.getCurrentDate(),
        end_date: null,
        site_id: this.form_site_id,
        email: this.nlmk_user.email,
        profile_id: 3,
        custom_level_id: 0,
        group_id: this.group_id,
        group_id_check: this.group_id_check,
        token: this.token,
        field20_details: {
          cid: this.nlmk_user.field20_details.cid,
          registrenational: this.nlmk_user.field20_details.registrenational,
          datenaissance: this.nlmk_user.field20_details.datenaissance,
          employeur: this.nlmk_user.field20_details.employeur,
          manageremail: this.nlmk_user.field20_details.manageremail,
          limosa: this.nlmk_user.field20_details.limosa,
          e101: this.nlmk_user.field20_details.e101,
          site_user: this.nlmk_user.field20_details.site_user,
          group_id: this.group_id,
        }
      };
      this.formCreatorService.createFormNlmk(body)
        .subscribe(res => {
          this.nlmk_user.firstname = '';
          this.nlmk_user.lastname = '';
          this.nlmk_user.email = '';
          this.nlmk_user.field20_details.datenaissance = '';
          this.nlmk_user.field20_details.employeur = '';
          this.nlmk_user.field20_details.manageremail = '';
          if (res.success === true) {
            this.sso_certikap_new(res.login);
          } else {
            this.spinner.hide();
            Swal.fire({
              icon: 'error',
              title: 'Erreur...',
              text: 'Veuillez essayer à nouveau '
            });
          }
        }, (err) => {
          console.log(err);
        });
    }
  }

  form_AS_SACLAY() {
    this.spinner.show();
    if (
      this.nlmk_user.lastname === null
      || this.nlmk_user.firstname === null
      || this.nlmk_user.field20_details.datenaissance === null
      || this.nlmk_user.email === null
      || this.nlmk_user.field20_details.service === null
      || this.nlmk_user.field20_details.employeur === null
      || this.nlmk_user.field20_details.manageremail === null
      || this.nlmk_user.field20_details.numero_installation === null
    ) {
      this.spinner.hide();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Vérifiez vos saisies, les champs avec la mention (*) sont obligatoires '
      });
    } else if (!this.nlmk_user.email.includes('@')) {
      this.spinner.hide();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Vérifiez votre adresse mail'
      });
    } else if (!this.nlmk_user.field20_details.manageremail.includes('@')) {
      this.spinner.hide();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Vérifiez  l\'adresse mail du manager'
      });
    } else {
      const body = {
        login: this.nlmk_user.login,
        firstname: this.nlmk_user.firstname,
        lastname: this.nlmk_user.lastname,
        password: this.nlmk_user.password,
        start_date: this.getCurrentDate(),
        end_date: null,
        site_id: this.form_site_id,
        email: this.nlmk_user.email,
        profile_id: 3,
        custom_level_id: 0,
        group_id: this.group_id,
        group_id_check: this.group_id_check,
        token: this.token,
        field20_details: {
          cid: this.nlmk_user.field20_details.cid,
          registrenational: this.nlmk_user.field20_details.registrenational,
          datenaissance: this.nlmk_user.field20_details.datenaissance,
          employeur: this.nlmk_user.field20_details.employeur,
          manageremail: this.nlmk_user.field20_details.manageremail,
          limosa: this.nlmk_user.field20_details.limosa,
          e101: this.nlmk_user.field20_details.e101,
          site_user: this.nlmk_user.field20_details.site_user,
          group_id: this.group_id,
          service: this.nlmk_user.field20_details.service,
          numero_installation: this.nlmk_user.field20_details.numero_installation,
        }
      };
      this.formCreatorService.createFormNlmk(body)
        .subscribe(res => {
          this.nlmk_user.firstname = '';
          this.nlmk_user.lastname = '';
          this.nlmk_user.email = '';
          this.nlmk_user.field20_details.datenaissance = '';
          this.nlmk_user.field20_details.employeur = '';
          this.nlmk_user.field20_details.manageremail = '';
          this.nlmk_user.field20_details.service = '';
          this.nlmk_user.field20_details.numero_installation = '';
          if (res.success === true) {
            this.sso_certikap_new(res.login);
          } else {
            this.spinner.hide();
            Swal.fire({
              icon: 'error',
              title: 'Erreur...',
              text: 'Veuillez essayer à nouveau '
            });
          }
        }, (err) => {
          console.log(err);
        });
    }
  }

  form_AS_FONTENAY() {
    this.spinner.show();
    if (
      this.nlmk_user.lastname === null
      || this.nlmk_user.firstname === null
      || this.nlmk_user.field20_details.datenaissance === null
      || this.nlmk_user.email === null
      || this.nlmk_user.field20_details.service === null
      || this.nlmk_user.field20_details.employeur === null
      || this.nlmk_user.field20_details.manageremail === null
      || this.nlmk_user.field20_details.numero_installation === null
    ) {
      this.spinner.hide();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Vérifiez vos saisies, les champs avec la mention (*) sont obligatoires '
      });
    } else if (!this.nlmk_user.email.includes('@')) {
      this.spinner.hide();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Vérifiez votre adresse mail'
      });
    } else if (!this.nlmk_user.field20_details.manageremail.includes('@')) {
      this.spinner.hide();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Vérifiez  l\'adresse mail du manager'
      });
    } else {
      const body = {
        login: this.nlmk_user.login,
        firstname: this.nlmk_user.firstname,
        lastname: this.nlmk_user.lastname,
        password: this.nlmk_user.password,
        start_date: this.getCurrentDate(),
        end_date: null,
        site_id: this.form_site_id,
        email: this.nlmk_user.email,
        profile_id: 3,
        custom_level_id: 0,
        group_id: this.group_id,
        group_id_check: this.group_id_check,
        token: this.token,
        field20_details: {
          cid: this.nlmk_user.field20_details.cid,
          registrenational: this.nlmk_user.field20_details.registrenational,
          datenaissance: this.nlmk_user.field20_details.datenaissance,
          employeur: this.nlmk_user.field20_details.employeur,
          manageremail: this.nlmk_user.field20_details.manageremail,
          limosa: this.nlmk_user.field20_details.limosa,
          e101: this.nlmk_user.field20_details.e101,
          site_user: this.nlmk_user.field20_details.site_user,
          group_id: this.group_id,
          service: this.nlmk_user.field20_details.service,
          numero_installation: this.nlmk_user.field20_details.numero_installation,
        }
      };
      this.formCreatorService.createFormNlmk(body)
        .subscribe(res => {
          this.nlmk_user.firstname = '';
          this.nlmk_user.lastname = '';
          this.nlmk_user.email = '';
          this.nlmk_user.field20_details.datenaissance = '';
          this.nlmk_user.field20_details.employeur = '';
          this.nlmk_user.field20_details.manageremail = '';
          this.nlmk_user.field20_details.service = '';
          this.nlmk_user.field20_details.numero_installation = '';
          if (res.success === true) {
            this.sso_certikap_new(res.login);
          } else {
            this.spinner.hide();
            Swal.fire({
              icon: 'error',
              title: 'Erreur...',
              text: 'Veuillez essayer à nouveau '
            });
          }
        }, (err) => {
          console.log(err);
        });
    }
  }

  createUserByForm() {
    const body = {
      login: this.nlmk_user.login,
      firstname: this.nlmk_user.firstname,
      lastname: this.nlmk_user.lastname,
      password: this.nlmk_user.password,
      start_date: this.getCurrentDate(),
      end_date: null,
      site_id: this.form_site_id,
      email: this.nlmk_user.email,
      profile_id: 3,
      custom_level_id: 0,
      group_id: this.group_id,
      group_id_check: this.group_id_check,
      token: this.token,
      field20_details: {
        cid: this.nlmk_user.field20_details.cid,
        registrenational: this.nlmk_user.field20_details.registrenational,
        datenaissance: this.nlmk_user.field20_details.datenaissance,
        employeur: this.nlmk_user.field20_details.employeur,
        manageremail: this.nlmk_user.field20_details.manageremail,
        limosa: this.nlmk_user.field20_details.limosa,
        e101: this.nlmk_user.field20_details.e101,
        site_user: this.nlmk_user.field20_details.site_user,
        group_id: this.group_id,
      }
    };
    this.formCreatorService.createFormNlmk(body)
      .subscribe(res => {
        if (res.success === true) {
          this.spinner.hide();
          this.nlmk_user.login = '';
          this.nlmk_user.firstname = '';
          this.nlmk_user.lastname = '';
          this.nlmk_user.password = '';
          this.nlmk_user.start_date = '';
          this.nlmk_user.end_date = '';
          this.nlmk_user.site_id = '';
          this.nlmk_user.email = '';
          // @ts-ignore
          this.nlmk_user.profile_id = '';
          // @ts-ignore
          this.nlmk_user.custom_level_id = '';
          this.nlmk_user.field20_details.cid = '';
          this.nlmk_user.field20_details.registrenational = '';
          this.nlmk_user.field20_details.datenaissance = '';
          this.nlmk_user.field20_details.employeur = '';
          this.nlmk_user.field20_details.manageremail = '';
          this.sso_certikap_new(res.login);
        }
        if (res.success === false) {
          this.nlmk_user.login = '';
          this.nlmk_user.firstname = '';
          this.nlmk_user.lastname = '';
          this.nlmk_user.password = '';
          this.nlmk_user.start_date = '';
          this.nlmk_user.end_date = '';
          this.nlmk_user.site_id = '';
          this.nlmk_user.email = '';
          // @ts-ignore
          this.nlmk_user.profile_id = '';
          // @ts-ignore
          this.nlmk_user.custom_level_id = '';
          this.nlmk_user.field20_details.cid = '';
          this.nlmk_user.field20_details.registrenational = '';
          this.nlmk_user.field20_details.datenaissance = '';
          this.nlmk_user.field20_details.employeur = '';
          this.nlmk_user.field20_details.manageremail = '';
          this.spinner.hide();
          Swal.fire({
            icon: 'error',
            title: 'Erreur...',
            text: 'Veuillez essayer à nouveau '
          });
        }
      }, (err) => {
        console.log(err);
      });
  }

  auto_form_registration_basf_lyon() {
    this.spinner.show();
    if (
      this.nlmk_user.lastname === null
      || this.nlmk_user.firstname === null
      || this.nlmk_user.field20_details.datenaissance === null
      || this.nlmk_user.field20_details.employeur === null
      || this.nlmk_user.email === null
    ) {
      this.spinner.hide();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Vérifiez vos saisies, les champs avec la mention (*) sont obligatoires '
      });
    } else if (!this.nlmk_user.email.includes('@')) {
      this.spinner.hide();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Vérifiez votre adresse mail'
      });
    } else if (this.nlmk_user.firstname.length === 1 || this.nlmk_user.lastname.length === 1) {
      this.spinner.hide();
      Swal.fire({
        icon: 'question',
        title: '<div><span style="font-size: 22px;">Confirmez-vous que votre</span> <br/> <span style="font-size: 20px;">nom/prénom ne contient qu\'une lettre ?</span></div>',
        html: '<div style="margin-top: 20px;"></div><span style="font-style: italic;">Can you confirm that your first</span><br/><span style="font-style: italic;">name/surname contain only one letter ?</span>',
        showCancelButton: true,
        confirmButtonText: 'Oui / <span style="font-style: italic;font-size: 15px;">Yes</span>',
        cancelButtonText: 'Non / <span style="font-style: italic;font-size: 15px;">No</span>',
        customClass: {
          confirmButton: 'btn btn-sm btn-primary',
          cancelButton: 'btn btn-sm btn-secondary'
        }
      }).then((result) => {
        if (result.value) {
          // L'utilisateur a cliqué sur "Oui", continuer le traitement
          this.createUserByForm();
        } else {
          // L'utilisateur a cliqué sur "Non", ne rien faire
        }
      });
    } else {
      this.createUserByForm();
    }
  }

}

import { HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./config.service";
// @ts-ignore
const token = JSON.parse(localStorage.getItem('tokenCurrentConnected'));
const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
    }),
};
export class VerificationService {
    constructor(http, config) {
        this.http = http;
        this.config = config;
    }
    handleError(error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(`Backend returned code ${error}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }
    extractData(res) {
        const body = res;
        return body || {};
    }
    getVerification() {
        return this.http.get(this.config.urlDEV + '/verifications', httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    getListVerification() {
        return this.http.get(this.config.urlDEV + '/verifications/admin', httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    getOneVerification(id) {
        return this.http.get(this.config.urlDEV + '/verifications/' + id, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    getCertificationByVerification(id) {
        return this.http.get(this.config.urlDEV + '/certifications_by_verification/' + id, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    // tslint:disable-next-line:variable-name
    getListVerificationbySiteId(id_site) {
        return this.http.get(this.config.urlDEV + '/verifications/verification_by_site/' + id_site, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    // tslint:disable-next-line:variable-name
    getListVerificationbyCErtificat(id_cert) {
        return this.http.get(this.config.urlDEV + '/verifications_by_certificat/' + id_cert, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    // tslint:disable-next-line:variable-name
    get_verification_sites_list(id_site) {
        return this.http.get(this.config.urlDEV + '/verifications/site/' + id_site, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    getVerificationBySiteUser(user_id) {
        return this.http.get(this.config.urlDEV + '/verifications/user/' + user_id, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    addVerification(data) {
        return this.http.post(this.config.urlDEV + '/verifications', data, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    updateVerification(data) {
        return this.http.post(this.config.urlDEV + '/upd_verifications', data, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    liveUpdateCertification(data) {
        return this.http.post(this.config.urlDEV + '/verifications/live_update_cert', data, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    deleteVerification(data) {
        return this.http.post(this.config.urlDEV + '/del_verifications', data, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    delete_content_verification(data) {
        return this.http.post(this.config.urlDEV + '/verifications/delete_content_verification', data, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    group_user_verificateur(id_verificateur, id_user) {
        return this.http.get(this.config.urlDEV + '/users/group_verificateur_user/' + id_verificateur + '/' + id_user, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    getVerificationByLogin(login) {
        return this.http.get(this.config.urlDEV + '/verifications/user/information/' + login, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
}
VerificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VerificationService_Factory() { return new VerificationService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ConfigService)); }, token: VerificationService, providedIn: "root" });

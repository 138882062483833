import {Groupe} from "./groupe.model";

export class ChoiceScreen {
  id: number;
  titre: string;
  description: string;
  groupe_id: number;
  groupe : Groupe;
  created_at: Date;
  updated_at: Date;
  groupes: Groupe[];
}

export class User {
  id: number;
  name: string;
  firstname: string;
  matricule: string;
  email: string;
  profile_id: number;
  u_remote_id:string;
  is_on_catalogue: number;
  society:string;
  address:string;
  profession:string;
  service:string;
  photo:string;
  publish: number;
  default_language:string;
  active: number;
  end_date:string;
  start_date:string;
  login : string;
  email_verified_at :string;
  password: string;
  remember_token:string;
  field20:string;
  token:string;
  is_croned: number;
}

<app-header></app-header>
<app-sidebar></app-sidebar>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"  >
  <p style="font-size: 20px; color: white">Chargement de la liste en cours...</p>
</ngx-spinner>
<div class="main-content">
  <div class="page-content">
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-flex align-items-center justify-content-between">
            <h4 class="mb-0 font-size-18">
              <i class="bx bx-home-circle"></i>  Bienvenue dans votre espace
              <app-fildariane></app-fildariane>
            </h4>
            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item"><a href="javascript: void(0);">Accueil</a></li>
                <li class="breadcrumb-item active">Catalogue
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div id="accordion">
            <div class="card">
              <div class="card-body">
                <h2>{{catalogue.titre}}</h2>
                <ng-container *ngIf="catalogue.langue == 'anglais'"><div class="text-right mr-5"><strong>Select</strong></div></ng-container>
                <ng-container *ngIf="catalogue.langue == 'français'"><div class="text-right mr-4" ><strong>Sélectionnez</strong></div></ng-container>
                <div class="table-responsive mt-3 mb-2 col-md-12">
                  <table class="table mb-4 justify-content-center col-md-12">
                    <tbody class="col-md-12">
                    <ng-container *ngFor="let chapitre of catalogue.chapitres">
                    <ng-container *ngIf="chapitre.sessions_chapitres.length > 0">
                    <div class="col-md-12 mb-3 pb-2"  style="background-color: #F2F2F2;" >
                        <div class="title mb-2" style="font-size: 15px;"><strong>{{chapitre.titre}}</strong></div>
                        <div class="row mb-2" *ngFor="let session of chapitre.sessions_chapitres">
                          <div class="col-11">
                            <mat-expansion-panel class="custom-expansion-panel" style="background-color: #F2F2F2;">
                              <mat-expansion-panel-header style="background-color: #F2F2F2;">
                                <mat-panel-title class="col-7">
                                  <div class="col-12"> {{session.title_catalogue}}</div>
                                </mat-panel-title>
                                <mat-panel-description>
                                  <ng-container *ngIf="catalogue.langue == 'français'">
                                    <div class="col-12">durée : {{session.duree}}</div>
                                  </ng-container>
                                  <ng-container *ngIf="catalogue.langue == 'anglais'">
                                    <div class="col-12">duration : {{session.duree}}</div>
                                  </ng-container>
                                </mat-panel-description>
                              </mat-expansion-panel-header>
                              <mat-expansion-panel-content style="background-color: #F2F2F2;">
                                <p [innerHTML]="formatDescription(session.description)"></p>
                              </mat-expansion-panel-content>
                            </mat-expansion-panel>
                          </div>
                          <div class="col-md-1 d-flex align-items-center">
                            <div class="collapse-checkbox">
                              <input type="checkbox" (change)="checkSession(session.id, $event.target.checked);" #c value="{{session.id_cron_session}}">
                            </div>
                          </div>
                        </div>
                    </div>
                    </ng-container>
                    </ng-container>
                    </tbody>

                  </table>
                  <div class="row justify-content-end mb-3">
                    <div class="col-3">
                      <button type="button" class="btn btn-primary btn-block waves-effect waves-light" (click)="inscriptionSession()">
                        <ng-container *ngIf="catalogue.langue == 'français'">
                          Valider mes sélections et quitter
                        </ng-container>
                        <ng-container *ngIf="catalogue.langue == 'anglais'">
                          Confirm my selections and quit
                        </ng-container>
                      </button>
                    </div>
                  </div>
                  <div class="row justify-content-end">
                    <div class="col-3">
                      <button type="button" class="btn btn-primary btn-block waves-effect waves-light" (click)="redirection()">
                        <ng-container *ngIf="catalogue.langue == 'français'">
                          Quitter sans valider
                        </ng-container>
                        <ng-container *ngIf="catalogue.langue == 'anglais'">
                          Quit without validating
                        </ng-container>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- End Page-content -->
    </div>
  </div>
  </div>
    <app-footer></app-footer>



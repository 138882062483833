import { Component, OnInit } from '@angular/core';
import {Catalogue} from "../../../../models/catalogue.model";
import {Session} from "../../../../models/session.model";
import {FormArray, FormBuilder, FormControl} from "@angular/forms";
import {Router} from "@angular/router";
import {CatalogueService} from "../../../../services/catalogue.service";
import {SessionService} from "../../../../services/session.service";
import Swal from "sweetalert2";
import {ConfigService} from "../../../../services/config.service";

@Component({
  selector: 'app-create-catalogue-session',
  templateUrl: './create-catalog-management.component.html',
  styleUrls: ['./create-catalog-management.component.css']
})
export class CreateCatalogManagementComponent implements OnInit {

  public user_conntected = JSON.parse(localStorage.getItem('currentUser'));
  public profile_id = parseInt(localStorage.getItem('profile_id'));

  //Initialisation
  Catalogue = new Catalogue();

  //create ChoiceScreen
  responseMessage : string;

  constructor(private catalogueService: CatalogueService,
              private formBuilder: FormBuilder,
              private configService: ConfigService,
              private router: Router) { }
  ngOnInit() {
  }

  createCatalogue(){
// tslint:disable-next-line:max-line-length
    if (this.Catalogue.titre === undefined || this.Catalogue.titre.trim() === '') {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Le titre est manquant'
      });
      return false;
      // tslint:disable-next-line:align
    }
    else if (this.Catalogue.description === undefined || this.Catalogue.description.trim() === '') {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Veuillez mettre une description',
      });
      return false;
      // tslint:disable-next-line:align
    }
    else{
      const body = {
        titre: this.Catalogue.titre,
        description: this.Catalogue.description,
        langue : this.Catalogue.langue,
      };
      this.catalogueService.createCatalogue(body)
        .subscribe(res => {
          this.responseMessage = res.message;
          if(res.success == false){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: this.responseMessage
            });
          }
          else{
            // @ts-ignore: Unreachable code error
            this.Catalogue.titre = '';
            this.Catalogue.description = '';
            Swal.fire({
              title: 'Catalogue créé !',
              text: res.message,
              icon: 'success',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Revenir sur la liste des catalogues',
              cancelButtonText: 'Rester sur la page'
            }).then((result) => {
              if (result.isConfirmed) {
                this.router.navigate(['/dashboard/administrator/catalogue-management']);
              }
            });
          }

        }, (err) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: this.responseMessage
          });
        });
    }
  }


}

<ngx-spinner></ngx-spinner>
<section id="front_wrap">
<section id="connect_page">
  <div id="connect_title">
    <p>Connectez-vous à </p>
    <img src="assets/img/logo_alaya_blc.png">
  </div>
  <div id="connect_content">
    <form [formGroup]="loginForm" (ngSubmit)="login()">
      <input type="email"  formControlName="email" placeholder="Identifiant" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
      <input type="password"  placeholder="Mot de passe" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
      <div *ngIf="submitted && f.password.errors" class="alert alert-danger" role="alert">
        <div *ngIf="f.password.errors.required">Email et mot de passe obligatoire</div>
      </div>
      <div *ngIf="error_logo" class="alert alert-danger" role="alert">
        <div>Mot de passe ou Email incorrect</div>
      </div>
      <button class="blue_btn"  type="submit" color="accent">Se connecter</button>
      <p style="margin-top: 25px"><a href="/password/forget">Mot de passe oublié ?</a></p>
    </form>
  </div>
  <div id="connect_legal">
    <p>Institut PASTEUR - par <strong>Kaptitude</strong> - 2021</p>

  </div>
</section>
</section>

<app-header></app-header>
<app-sidebar></app-sidebar>
<div class="main-content">
  <div class="page-content">
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-flex align-items-center justify-content-between">
            <h4 class="mb-0 font-size-18">
              <i class="bx bx-home-circle"></i>  Bienvenue dans votre espace
              <app-fildariane></app-fildariane>
            </h4>
            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item"><a href="javascript: void(0);">Accueil</a></li>
                <li class="breadcrumb-item active">Gestion des utilisateurs
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->

      <div class="row">
        <a href="javascript:window.history.back();" class="btn btn-primary waves-effect waves-light btn-sm mb-3"><i class="mdi mdi-chevron-left ml-1"></i> Retour </a>
        <ng-container *ngIf="form_validate">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Edition d'un utilisateur</h4>
              <div class="alert alert-success text-center" role="alert" *ngIf="success_Message">
                {{text}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div class="alert alert-danger text-center" role="alert" *ngIf="not_success_Message">
                {{text}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div class="form-group row">
                <label class="col-md-2 col-form-label">Nom</label>
                <div class="col-md-10">
                  <input class="form-control" type="text" [(ngModel)]="single_client.name">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-2 col-form-label">Prénom</label>
                <div class="col-md-10">
                  <input class="form-control" type="text" [(ngModel)]="single_client.firstname">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-2 col-form-label">E-mail</label>
                <div class="col-md-10">
                  <input class="form-control" type="email" [(ngModel)]="single_client.email">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-2 col-form-label">Société</label>
                <div class="col-md-10">
                  <input class="form-control" type="text" [(ngModel)]="single_client.society">
                </div>
              </div>
              <div class="form-group row">
                <div class="input-group">
                  <label for="password" class="col-md-2 col-form-label">Mot de passe :</label>
                  <input type="password" name="password" id="password" #password1 class="col-md-10 form-control" style="margin-left: 12px;border-radius: 5px;" placeholder="Mot de passe" value="" [(ngModel)]="single_client.password" [ngModelOptions]="{standalone: true}">
                  <div class="input-group-append" style="margin-right: 11px;border-radius: 5px !important;" (click)="changeType(password1, 1)">
                    <span class="input-group-text" ><i [ngClass]="toggle1 ? 'fas fa-eye' : 'fas fa-eye-slash'"></i></span>
                  </div>
                </div>
                <p class="font-italic">Laissez le champ mot de passe vide, si vous ne souhaitez pas le modifier.</p>
              </div>
              <div class="form-group row">
                <div class="input-group">
                  <label for="c_password" class="col-md-2 col-form-label">Confirmation : </label>
                  <input type="password" name="c_password" id="c_password" #password2 class="col-md-10 form-control" style="margin-left: 12px;border-radius: 5px;" placeholder="Confirmez votre mot de passe"  value="" [(ngModel)]="single_client.password_confirm" [ngModelOptions]="{standalone: true}">
                  <div class="input-group-append" style="margin-right: 11px;border-radius: 5px !important;" (click)="changeType(password2, 2)">
                    <span class="input-group-text"><i [ngClass]="toggle2 ? 'fa fa-eye' : 'fa fa-eye-slash'"></i></span>
                  </div>
                </div>
                <p class="font-italic">Laissez le champ confirmez mot de passe vide, si vous ne souhaitez pas le modifier.</p>
              </div>
              <div class="form-group row">
                <label class="col-md-2 col-form-label">Identifiant</label>
                <div class="col-md-10">
                  <input class="form-control" type="text" [(ngModel)]="single_client.login" readonly>
                </div>
              </div>
              <div class="form-group text-center">
                <ng-container *ngIf="loadingAdd"><img src="assets/img/loaderkapi.gif" height="20"><br></ng-container>
                <button type="button" class="btn btn-primary waves-effect waves-light" (click)="update_client()">
                  Valider
                </button>
              </div>

            </div>
          </div>
        </div>
          </ng-container>

      </div>
      <!-- end row -->
      <!-- end row -->
    </div>
    <!-- container-fluid -->
  </div>
  </div>
  <!-- End Page-content -->
  <app-footer></app-footer>

import {Catalogue} from "./catalogue.model";
import {ChapitreSession} from "./chapitre-session.model";

export class Chapitre {
  id: number;
  catalogue: Catalogue; // Relation à un objet Catalogue
  titre: string;
  description: string;
  created_at: Date;
  updated_at: Date;
  sessions_chapitres: ChapitreSession[];
}

import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginService} from '../../services/login.service';
import {ConfigService} from '../../services/config.service';
import {UserService} from '../../services/user.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {VerificationService} from '../../services/verification.service';
import {OrderPipe} from 'ngx-order-pipe';
import {User} from "../../models/user.model";
import Swal from "sweetalert2";
// @ts-ignore

@Component({
  selector: 'app-inspector',
  templateUrl: './inspector.component.html',
  styleUrls: ['./inspector.component.css']
})
export class InspectorComponent implements OnInit {
  order = 'name';
  reverse = false;
  private resultSociety_all_e: any;
  unique_society_all: any;
  private society_choice: any;
  private verrifications_length: any;
  private verif_id: any;
  constructor(public translate: TranslateService,
              public pageTitle: Title,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private loginService: LoginService,
              private verificationService: VerificationService,
              private userService: UserService,
              private spinner: NgxSpinnerService,
              private orderPipe: OrderPipe,
              private config: ConfigService) {
    translate.addLangs(['fr', 'en']);
    translate.setDefaultLang('fr');
    pageTitle.setTitle('Espace Vérificateur | Plateforme ALAYA');
    this.photo_url = this.config.urlDEV_images;
    if (this.user_conntected !== null) {
      this.get_single_user_connected_details(this.user_conntected.user_id ? this.user_conntected.user_id : this.user_conntected.id);
      this.sortedCollection = orderPipe.transform(this.results, 'name');
    }

   }
  sortedCollection: any[];
  // tslint:disable-next-line:variable-name
  public users_list_for_verifGroups: any;
  // tslint:disable-next-line:variable-name
  public users_list_for_verificateurs: any;
  public takeInHand = localStorage.getItem('takeInHand');
  results: any;
  values = '';
  verifications: any;
  // tslint:disable-next-line:variable-name
  admin_verifications: any;
  term: any;
  // tslint:disable-next-line:variable-name
  public user_conntected = JSON.parse(localStorage.getItem('currentUser'));
  // tslint:disable-next-line:variable-name radix
  public site_id = parseInt(localStorage.getItem('site_id'));
  // tslint:disable-next-line:variable-name
  public profile_id = this.user_conntected.profile_id;
  // tslint:disable-next-line:variable-name
  public verificateur_id = this.user_conntected.user_id ? this.user_conntected.user_id : this.user_conntected.id;
  // tslint:disable-next-line:variable-name
  public photo_url ;
  public name_search: any;
  public user_id: any;
  // tslint:disable-next-line:variable-name
  public admin_login = localStorage.getItem('admin_login');
  // tslint:disable-next-line:variable-name
  public single_user = {
    name : null,
    photo : null,
  };
  // tslint:disable-next-line:variable-name
  vQuery: any;
  // tslint:disable-next-line:variable-name
  verification_id: any;
  // tslint:disable-next-line:variable-name
  resultSociety_value: any;
  // tslint:disable-next-line:variable-name
  showGif: boolean;
  Allusers: User[] = [];
  // tslint:disable-next-line:variable-name
  userFind_all: User[] = [];
  resultSociety_all = [];

  ngOnInit() {
    this.config.redirectTools();

      this.getVerificationByUser(this.verificateur_id);
      this.result_search_all();
  }

  onKey(event: any) { // without type info
    this.term = event.target.value;
  }
  switchLang(lang: string) {
    this.translate.use(lang);
  }

  logout_user() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('tokenCurrentConnected');
    localStorage.removeItem('profile_id');
    localStorage.removeItem('site_id');
    localStorage.removeItem('takeInHand');
    localStorage.removeItem('connect_like_verificateur');
    this.loginService.logout().subscribe(
      res => {
        if(res.status == 'success'){
          localStorage.removeItem('currentUser');
          localStorage.removeItem('tokenCurrentConnected');
          localStorage.removeItem('profile_id');
          localStorage.removeItem('site_id');
          localStorage.removeItem('takeInHand');
          localStorage.removeItem('connect_like_verificateur');
          this.router.navigate(['/']);
          setTimeout(() => {
            location.reload();
          }, 1000);
        }
        else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Une erreur s\'est produite lors de la déconnexion',
            footer: '<a href>Equipe Kaptitude</a>'
          });
        }
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Une erreur s\'est produite lors de la déconnexion',
          footer: '<a href>Equipe Kaptitude</a>'
        });
      }
    );
  }

  // tslint:disable-next-line:variable-name
  get_single_user_connected_details(id_user: number) {
    this.userService.getUser(id_user)
      .subscribe(res => {
        this.single_user.name = res.data.name;
        this.single_user.photo = this.photo_url + '' + res.data.photo;
      }, (err) => {
        console.log(err);
      });
  }

  search() {
    alert(this.vQuery);
  }

  // tslint:disable-next-line:variable-name
  result_search_all() {
    const body = {
      site_id: this.site_id,
      user_login: this.user_conntected.login,
      profile_id: this.profile_id,
    };
    this.userService.search_intervenant(body)
      .subscribe(res => {
        // @ts-ignore
        this.userFind_all = res.userFind_all;
        this.Allusers = [ ...this.userFind_all ];
        this.resultSociety_all = res.resultSociety_all;
        this.unique_society_all = res.unique_society_all;
      }, (err) => {
        console.log(err);
      });
  }

  // tslint:disable-next-line:variable-name
  get_list_verification(id_site) {
    this.spinner.show();
    this.verificationService.getListVerificationbySiteId(id_site)
      .subscribe(res => {
        // @ts-ignore
        this.count_verification = res.data.length;
        // @ts-ignore
        this.verifications = res.data;
        this.verrifications_length = this.verifications.length;
        if(this.verrifications_length == 1){
          this.verifications.forEach(element =>{
            let id = element.id;
            this.verificationChoice(id)
          });
        }
        else if(this.verrifications_length > 1){
          let firstElementId = this.verifications[0].id;
          this.verificationChoice(firstElementId);
        }
        this.spinner.hide();
      }, (err) => {
        console.log(err);
      });
  }

  getVerificationByUser(user_id) {
    this.spinner.show();
    this.verificationService.getVerificationBySiteUser(user_id)
      .subscribe(res => {
        // @ts-ignore
        this.count_verification = res.data.length;
        // @ts-ignore
        this.verifications = res.data;
        this.verrifications_length = this.verifications.length;
        if(this.verrifications_length == 1){
          this.verifications.forEach(element =>{
            let id = element.id;
            this.verificationChoice(id)
          });
        }
        else if(this.verrifications_length > 1){
          let firstElementId = this.verifications[0].id;
          this.verificationChoice(firstElementId);
        }
        this.spinner.hide();
      }, (err) => {
        console.log(err);
      });
  }

  get_list_admin_verification() {
    this.spinner.show();
    this.verificationService.getListVerification()
      .subscribe(res => {
        // @ts-ignore
        this.count_verification = res.data.length;
        // @ts-ignore
        this.admin_verifications = res.data;
        // console.log(this.admin_verifications);
        this.spinner.hide();
      }, (err) => {
        console.log(err);
      });
  }

  verificationChoice(event: any) {
    if(this.verrifications_length == 1){
      this.verification_id = event;
    }else{
      this.verification_id = event;
    }
  }

  societyChoice(event: any) {
    this.society_choice = event.target.value;
    this.search_name(this.society_choice, this.name_search);
  }

  transform(value: string): string {
    const first = value.substr(0,1).toUpperCase();
    return first + value.substr(1);
  }

  // tslint:disable-next-line:variable-name
  returner(login_t) {
    this.spinner.show();
    const body = {
      login: login_t
    };
    // @ts-ignore
    this.userService.take_in_hand(body)
      .subscribe(res => {
        this.loginService.logout_take_in_hand();
        // @ts-ignore
        if (res.status === 'success') {
          // @ts-ignore
          localStorage.setItem('currentUser', JSON.stringify(res.user));
          localStorage.setItem('takeInHandOf', 'no');
          // @ts-ignore
          localStorage.setItem('tokenCurrentConnected', JSON.stringify(res.token));
          // @ts-ignore
          this.loginService.currentUserSubject.next(res.user);
          // @ts-ignore
          localStorage.setItem('profile_id', res.profile_id);
          // @ts-ignore
          localStorage.setItem('site_id', res.site_id);
          // @ts-ignore
          window.location.reload();
          this.router.navigate(['dashboard/administrator']);
          return false;
        }
        this.spinner.hide();
      }, (err) => {
        console.log(err);
      });
  }

  search_name(society,nom) {
    if (nom && nom.length > 0 && this.society_choice == null) {
      this.userFind_all = this.Allusers.filter((userFind) => userFind.name.toLocaleLowerCase().includes(nom.toLocaleLowerCase()));
    }
    if (this.society_choice || this.society_choice!='null' && nom == null) {
      this.userFind_all = [ ...this.Allusers ];
      if(this.society_choice =='all'){
        this.userFind_all = this.Allusers;
      }else{
        this.userFind_all = this.Allusers.filter(userFind => {
          if (userFind.society != null) {
            return userFind.society.toLocaleLowerCase().includes(this.society_choice.toLocaleLowerCase());
          }
        });
      }
    }
    if(this.society_choice && this.society_choice!='null'  && nom && nom.length > 0){
      this.userFind_all = [ ...this.Allusers ];
      if(this.society_choice =='all'){
        this.userFind_all = this.userFind_all.filter((userFind) => userFind.name.toLocaleLowerCase().includes(nom.toLocaleLowerCase()));
      }else{
        this.userFind_all = this.Allusers.filter(userFind => {
          if (userFind.society != null) {
            return userFind.society.toLocaleLowerCase().includes(this.society_choice.toLocaleLowerCase());
          }
        });
        this.userFind_all = this.userFind_all.filter((userFind) => userFind.name.toLocaleLowerCase().includes(nom.toLocaleLowerCase()));
      }
    }
    if(this.society_choice == null && nom == null) {
      this.userFind_all = [ ...this.Allusers ];
    }
  }

  name_value(event : any) {
    this.name_search = event.target.value;
    this.search_name(this.society_choice, this.name_search);
  }
}

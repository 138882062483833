<section id="front_wrap">
  <section id="front_page" class="profile_step">
    <section id="menu_front">
    <span class="badge badge-danger" *ngIf="takeInHand && takeInHand === 'yes'">
        <h6 style="color: #ffffff;font-size: 12px;">
        <span class="bx bx-info-circle"></span> Prise en main en cours <br>de ce compte
       </h6>
         <button type="button" class="btn btn-primary waves-effect waves-light" (click)="returner(admin_login)">
            Retour au compte précédent
          </button>
      </span>
      <a class="menu_item menu_search" href="dashboard/inspector"><img src="assets/img/icon_search.png"> <p>Recherche</p></a>
      <a class="menu_item menu_profile" href="dashboard/inspector/profile"><img src="assets/img/icon_profile.png"> <p>Profil</p></a>
      <a class="menu_item menu_export current" href="dashboard/inspector/inspector-export.html"><img src="assets/img/icon_profile.png"> <p>Export</p></a>
      <a (click)="logout_user()" class="menu_item menu_logout" ><img src="assets/img/icon_logout.png"> <p>Déconnexion</p></a>
    </section>
    <ngx-spinner
      bdColor="rgba(51,51,51,0.8)"
      size="medium"
      color="#fff"
      type="ball-scale-multiple"  >
      <p style="font-size: 20px; color: white">Exportation en cours...</p>
    </ngx-spinner>
    <div class="main-content"   style="width: 50%">
      <div class="page-content">
        <div class="container-fluid">
          <!-- start page title -->
          <div class="row">
            <div class="col-12">
              <div class="page-title-box d-flex align-items-center justify-content-between">
                <h4 class="mb-0 font-size-18">
                  <i class="bx bx-home-circle"></i>  Bienvenue dans votre espace
                  <app-fildariane></app-fildariane>
                </h4>
              </div>
            </div>
          </div>
          <!-- end page title -->
          <!-- end row -->
          <div class="row">
            <div class="card col-xl-12">
              <div class="card-body">
                <h4 class="card-title mb-4">Export</h4>
                <div class="alert alert-success text-center" role="alert" *ngIf="successMessage">
                  {{text}}.
                  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <label class="col-md-12col-form-label">Veuillez sélectionner la donnée à exporter</label>
                <div>
                  <div class="form-group row">
                    <div class="col-md-9">
                      <select class="custom-select" [(ngModel)]="exportate.type" (change)="chooseType($event)">
<!--                          <option value="7">Vérification</option>-->
                      </select>
                    </div>
                  </div>
                  <ng-container *ngIf="choose && choose==7">
                    <label class="col-form-label">Veuillez sélectionner la vérification</label>
                    <div class="form-group row">
                      <div class="col-md-9">
                        <select class="custom-select" (change)="chooseVerifications($event)">
                        </select>
                      </div>
                    </div>
                  </ng-container>
                  <div class="alert alert-danger" role="alert" *ngIf="messeNotUser">
                    Pas d'internevants inscrits dans cette session
                  </div>
                  <ng-container *ngIf="choose && choose!=-1">
                    <div class="form-group row">
                      <div class="form-group text-center">
                        <button type="button" class="btn btn-primary waves-effect waves-light" (click)="exportation()">
                          Exporter
                        </button>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <!-- end card -->

            <!-- end card -->
          </div>

        </div>
        <!-- end row -->
      </div>
      <!-- container-fluid -->
    </div>
  </section>
  <div id="front_legal">
    <p>CERTIKAP - par <strong>Kaptitude</strong> - 2020</p>
  </div>
</section>



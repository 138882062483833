import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import {SessionService} from "../../../services/session.service";
import {Session} from "../../../models/session.model";
import {CatalogueService} from "../../../services/catalogue.service";
import {Catalogue} from "../../../models/catalogue.model";
import {ActivatedRoute, Router} from "@angular/router";
import Swal from "sweetalert2";
import {ConfigService} from "../../../services/config.service";
import {ChapitreService} from "../../../services/chapitre.service";

@Component({
  selector: 'app-session',
  templateUrl: './catalog-management.component.html',
  styleUrls: ['./catalog-management.component.css']
})
export class CatalogManagementComponent implements OnInit {

  constructor( private spinner: NgxSpinnerService,
               private sessionService: SessionService,
               private catalogueService: CatalogueService,
               private chapitreService: ChapitreService,
               private configService: ConfigService,
               private router: Router,
               private route: ActivatedRoute) { }

  //get informations about user
  public user_connected = JSON.parse(localStorage.getItem('currentUser'));
  public profile_id = parseInt(localStorage.getItem('profile_id'));
  public catalogue_id;
  ngOnInit() {
    this.configService.checkUSerAccessByProfile(this.profile_id);
    this.getAllCatalogues()
  }

  //variables
  order = 'catalog-management.titre';
  reverse = false;
  responseMessage : string;

  searchTerm: string = '';
  vQuery: string;
  show = 10;
  page = 1;
  //FRONT
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }

  catalogues: Catalogue[] = []; // Catalogues

  handlePageChange(event) {
    this.page = event;
  }
  increaseShow() {
    this.spinner.show();
    this.show += 10;
    this.spinner.hide();
  }

  getAllCatalogues(){
    this.catalogueService.getAllCatalogue()
      .subscribe(res => {
        console.log(res)
        this.catalogues = res.catalogues;
        this.spinner.hide();
      }, (err) => {
        console.log(err);
        this.spinner.hide();
      });
  }

  // tslint:disable-next-line:variable-name
  update(id) {
    this.router.navigate(['/dashboard/administrator/catalogue-management/edit/' + id], { relativeTo: this.route });
  }

  // tslint:disable-next-line:variable-name
  delete(id) {
    Swal.fire({
      title: 'Etes-vous sûr(e) ?',
      text: 'Cette action est irréversible',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui supprimer',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.value) {
        // @ts-ignore
        this.catalogueService.deleteCatalogue(id)
          .subscribe(res => {
            this.responseMessage = res.message;
            Swal.fire(
              'Supprimé !',
              'Catalogue supprimé avec succès !',
              'success'
            ).then(() => {
              window.location.reload();
              this.getAllCatalogues();
            });
          }, (err) => {
            Swal.fire(
              'Erreur',
              'Une erreur s\'est produite lors de la suppression',
              'error'
            );
          });
      }
    });
  }

  // tslint:disable-next-line:variable-name
  update_chapitre(id_catalogue,id) {
    this.router.navigate(['/dashboard/administrator/catalogue-chapitre/'+ id_catalogue+'/edit/' + id], { relativeTo: this.route });
  }

// tslint:disable-next-line:variable-name
  delete_chapitre(id) {
    Swal.fire({
      title: 'Etes-vous sûr(e) ?',
      text: 'Cette action est irréversible',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui supprimer',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.value) {
        // @ts-ignore
        this.chapitreService.deleteChapitre(id)
          .subscribe(res => {
            this.responseMessage = res.message;
            Swal.fire(
              'Supprimé !',
              'Chapitre supprimé avec succès !',
              'success'
            ).then(() => {
              window.location.reload();
              this.getAllCatalogues();
            });
          }, (err) => {
            Swal.fire(
              'Erreur',
              'Une erreur s\'est produite lors de la suppression',
              'error'
            );
          });
      }
    });
  }

  toggleCollapse(chapitre: any) {
    chapitre.showDescription = !chapitre.showDescription;
  }

  // tslint:disable-next-line:variable-name
  synchronisation_session() {
    this.spinner.show();
    this.sessionService.synchronisation_session_cron()
      .subscribe(res => {
        this.spinner.hide();
        if(res.success == false){
          Swal.fire(
            'Erreur',
            'Une erreur s\'est produite lors de la synchronisation des données',
            'error'
          );
        }else{
          Swal.fire(
            'Synchronisation effectuée !',
            'Les sessions ont été synchronisées',
            'success'
          ).then(() => {
            window.location.reload();
          });
        }
      }, (err) => {
        this.spinner.hide();
        Swal.fire(
          'Erreur',
          'Une erreur s\'est produite lors de la synchronisation des données',
          'error'
        );
      });  }
}

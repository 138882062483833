<app-header></app-header>
<app-sidebar></app-sidebar>
<div class="main-content">
  <div class="page-content">
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-flex align-items-center justify-content-between">
            <h4 class="mb-0 font-size-18">
              <i class="bx bx-home-circle"></i>  Bienvenue dans votre espace
              <app-fildariane></app-fildariane>
            </h4>
            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item"><a href="javascript: void(0);">Accueil</a></li>
                <li class="breadcrumb-item active">Gestion des vérifications
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->

      <div class="row">
        <div class="col-lg-12">
          <div class="alert alert-success text-center" role="alert" *ngIf="addingSuccess">
            Action effectuée avec <a href="#" class="alert-link">succès</a>.
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <a href="javascript:window.history.back();" class="btn btn-primary waves-effect waves-light btn-sm mb-3"><i class="mdi mdi-chevron-left ml-1"></i> Retour </a>
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Ajout d'une vérification</h4>
              <div class="form-group row">
                <label class="col-md-2 col-form-label">Libellé</label>
                <div class="col-md-10">
                  <input class="form-control" type="text" [(ngModel)]="verification.name" [ngModelOptions]="{standalone: true}">
                </div>
              </div>
              <ng-container *ngIf="entityType == 'EKAMPUS'">
              <div class="form-group row">
                <label class="col-md-2 col-form-label">Site</label>
                <div class="col-md-10">
                  <select class="custom-select" [(ngModel)]="verification.site_id">
                    <option value="null" disabled selected>Veuillez sélectionner le site</option>
                    <option *ngFor="let site of sites" value="{{site.id}}" >{{site.name}}</option>
                  </select>
                </div>
              </div>
              </ng-container>

              <button type="button" class="btn btn-primary waves-effect waves-light" (click)="addFormSite()"><i class="bx bxs-plus-square"></i> Ajouter un certificat</button>
              <form #addressForm="ngForm" class="container mt-3">
                <section *ngFor="let s_f of site_form; let i = index;">
                  <div class="form-group row">
                    <label class="col-md-2 col-form-label">Certificat</label>
                    <div class="col-md-8">
                      <select class="custom-select" [(ngModel)]="s_f.certificat_ID" name="certif_id_{{s_f.id}}">
                        <option selected="">Veuillez sélectionner le certificat</option>
                        <option *ngFor="let certificat of certificats" value="{{certificat.id}}">{{certificat.name}}</option>
                      </select>
                    </div>
                    <label class="col-md-2 col-form-label">
                      <button type="button" class="btn btn-danger btn-sm" (click)="removeFormSite(i)"><i class="bx bx-trash-alt"></i>Supprimer</button>
                    </label>
                  </div>
                </section>
                <div class="form-group text-center">
                  <button type="button" class="btn btn-primary waves-effect waves-light" (click)="ajouter_verification()">
                    Valider
                  </button>
                </div>
              </form>
            </div>
          </div>
          <!-- end row -->
          <!-- end row -->
        </div>
        <!-- container-fluid -->
      </div>
      </div>
      </div>
      </div>
      <!-- End Page-content -->
      <app-footer></app-footer>

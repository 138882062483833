import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginService} from '../../../services/login.service';
import {UserService} from '../../../services/user.service';
import {ConfigService} from '../../../services/config.service';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ExportToCsv } from 'export-to-csv';
import {NgxSpinnerService} from 'ngx-spinner';
import {ExportationService} from '../../../services/exportation.service';
import {CertificationService} from "../../../services/certification.service";
import {VerificationService} from '../../../services/verification.service';
import {HttpClient} from '@angular/common/http';
import Swal from "sweetalert2";



@Component({
  selector: 'app-inspector-export',
  templateUrl: './inspector-export.component.html',
  styleUrls: ['./inspector-export.component.css']
})
export class InspectorExportComponent implements OnInit {
// tslint:disable-next-line:variable-name
  public user_conntected = JSON.parse(localStorage.getItem('currentUser'));
  constructor(public translate: TranslateService,
              public pageTitle: Title,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private loginService: LoginService,
              private userService: UserService,
              private config: ConfigService,
              private spinner: NgxSpinnerService,
              private exportationService: ExportationService,
              private certificationService: CertificationService,
              private formBuilder: FormBuilder,
              private verificationService: VerificationService,
              private http: HttpClient) {
    this.urlDownload = config.urlDownload;
    translate.addLangs(['fr', 'en']);
    translate.setDefaultLang('fr');
    pageTitle.setTitle('Mon profil >> Espace Vérificateur | Plateforme ALAYA');
    this.photo_url = this.config.urlDEV_images;
    if (this.user_conntected !== null) {
      this.get_single_user_connected_details(this.user_conntected.user_id ? this.user_conntected.user_id : this.user_conntected.id);
    }

  }

  // tslint:disable-next-line:variable-name
  public photo_url ;
  // tslint:disable-next-line:variable-name
  public single_user = {
    name : null,
    photo : null,
    email : null,
    password : null,
    password_confirm : null,
    firstname : null,
    id : null,
  };
  public choose;
  public certificate_choose;
  public verification_choose;
  public dataToExport = [];
  public label: any;
  public certificate_label: any;
  public verification_label: any;
  public urlDownload: any;
  exportate = {
    type: null,
    completude: null,
  };
  // @ts-ignore
  public sessions: any;
  public u_remote_id: any;
  // tslint:disable-next-line:variable-name
  public site_id: any;
  // tslint:disable-next-line:variable-name
  public link: any;
  // tslint:disable-next-line:variable-name
  public link_status: any;
  // tslint:disable-next-line:variable-name
  public this_link: any;
  public session_id: any;
  public profile_id: any;
  public messeNotUser: boolean;
  public showDownloadButton: boolean;
  // tslint:disable-next-line:variable-name
  public date_export: any;
  public certificats: any;
  public verifications: any;
  public lenght_certificats: any;
  public value_certificat: any;
  successMessage: boolean;
  // tslint:disable-next-line:variable-name
  not_success_Message: boolean;
  text: any;
  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  // tslint:disable-next-line:variable-name
  addingSuccess: boolean;
  public takeInHand = localStorage.getItem('takeInHand');
  // tslint:disable-next-line:variable-name
  public admin_login = localStorage.getItem('admin_login');
  public connectLikeVerifcateur = localStorage.getItem('connect_like_verificateur');
  public myFormVerifications: any;
  public VerificationFormArray: any;


  ngOnInit() {
    this.spinner.hide();

    if (this.connectLikeVerifcateur === 'no') {
      this.router.navigate(['dashboard/administrator']);
      return false;
    }
    if (!localStorage.getItem('currentUser')) {
      this.router.navigate(['/']);
    }

    this.get_list_sessions_infos();
    this.config.checkUSerAccessByProfile(this.profile_id);
    if (!localStorage.getItem('currentUser')) {
      this.router.navigate(['/']);
    }
    this.get_list_verification_by_site_user(this.user_conntected.id);

    this.u_remote_id = this.user_conntected.u_remote_id;
    this.profile_id = this.user_conntected.profile_id;

    this.myFormVerifications = this.formBuilder.group({
      verification_id: this.formBuilder.array([])
    });
    this.VerificationFormArray = <FormArray>this.myFormVerifications.controls.verification_id;
  }
  switchLang(lang: string) {
    this.translate.use(lang);
  }

  logout_user() {
    this.loginService.logout().subscribe(
      res => {
        if(res.status == 'success'){
          localStorage.removeItem('currentUser');
          localStorage.removeItem('tokenCurrentConnected');
          localStorage.removeItem('profile_id');
          localStorage.removeItem('site_id');
          localStorage.removeItem('takeInHand');
          localStorage.removeItem('connect_like_verificateur');
          this.router.navigate(['/']);

          setTimeout(() => {
            location.reload();
          }, 1000);
        }
        else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Une erreur s\'est produite lors de la déconnexion',
            footer: '<a href>Equipe Kaptitude</a>'
          });
        }
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Une erreur s\'est produite lors de la déconnexion',
          footer: '<a href>Equipe Kaptitude</a>'
        });
      }
    );
  }

  // tslint:disable-next-line:variable-name
  get_single_user_connected_details(id_user: number) {
    this.userService.getUser(id_user)
      .subscribe(res => {
        this.single_user.name = res.data.name;
        this.single_user.email = res.data.email;
        this.single_user.firstname = res.data.firstname;
        //this.single_user.password = res.data.password;
        this.single_user.id = res.data.id;
        this.single_user.photo = this.photo_url + '' + res.data.photo;
      }, (err) => {
        console.log(err);
      });
  }

  fileProgress(fileInput: any) {
    this.fileData = fileInput.target.files[0] as File;
    this.preview();
  }

  preview() {
    // Show preview
    // tslint:disable-next-line:prefer-const
    let mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    // tslint:disable-next-line:prefer-const
    let reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    // tslint:disable-next-line:variable-name
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    };
  }

// tslint:disable-next-line:variable-name
  returner(login_t) {
    const body = {
      login: login_t
    };
    // @ts-ignore
    this.userService.take_in_hand(body)
      .subscribe(res => {
        this.loginService.logout_take_in_hand();
        // @ts-ignore
        if (res.status === 'success') {
          // @ts-ignore
          localStorage.setItem('currentUser', JSON.stringify(res.user));
          localStorage.setItem('takeInHandOf', 'no');
          localStorage.setItem('connect_like_verificateur', 'no');
          // @ts-ignore
          localStorage.setItem('tokenCurrentConnected', JSON.stringify(res.token));
          // @ts-ignore
          this.loginService.currentUserSubject.next(res.user);
          // @ts-ignore
          localStorage.setItem('profile_id', res.profile_id);
          // @ts-ignore
          localStorage.setItem('site_id', res.site_id);
          // @ts-ignore
          if (res.profile_id === 10 || res.profile_id === 8 || res.profile_id === 13) {
            window.location.reload();
            this.router.navigate(['dashboard/inspector']);
            return false;
          }
          window.location.reload();
          this.router.navigate(['dashboard/administrator']);
          return false;
        }

      }, (err) => {
        console.log(err);
      });
  }

  // tslint:disable-next-line:variable-name
  get_list_sessions_infos() {
    this.userService.get_sessions_list()
      .subscribe(res => {
        this.sessions = res.data;
      }, (err) => {
        console.log(err);
      });
  }

  chooseType(e) {
    this.choose = e.target.value;
    this.label = e.target.options[e.target.options.selectedIndex].text;
  }

  chooseCertificats(e) {
    this.certificate_choose = e.target.value;
    this.certificate_label = e.target.options[e.target.options.selectedIndex].text;

  }

  chooseVerifications(e) {
    this.verification_choose = e.target.value;
    this.verification_label = e.target.options[e.target.options.selectedIndex].text;
  }


  formatDate() {
    // tslint:disable-next-line:one-variable-per-declaration
    const d = new Date();
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    const hour = d.getHours();
    const minute = d.getMinutes();
    const seconde = d.getSeconds();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    const dateJoin = [year, month, day].join('');
    const hourJOin = [hour, minute, seconde].join('');
    return [dateJoin, hourJOin].join('_');
  }

  choose_config(choose) {
    if (choose === '0') {
      const options = {
        fieldSeparator: ';',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'Exportation des ' + this.label,
        filename: this.label + '_' + this.formatDate(),
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        // tslint:disable-next-line:max-line-length
        headers: ['ID', 'Intitulé']
      };
      return options;
    }
    if (choose === '1') {
      const options = {
        fieldSeparator: ';',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'Exportation des ' + this.label,
        filename: this.label + '_' + this.formatDate(),
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        // tslint:disable-next-line:max-line-length
        headers: ['ID', 'Titre du parcours', 'Description', 'Mot clés', 'Date d import ', 'Durée estimée ', 'Date de création', 'Référence', 'Pas commencé', 'En cours', 'Non validé', 'Validé', 'Total', 'Score', 'Temps moyen', 'Site', 'ID Site', 'Session', 'ID session']
      };
      return options;
    }
    if (choose === '2') {
      const options = {
        fieldSeparator: ';',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'Exportation des ' + this.label,
        filename: this.label + '_' + this.formatDate(),
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        // tslint:disable-next-line:max-line-length
        headers: ['ID', 'Intitulé', 'Date de début', 'Date de fin', 'Etat ', 'Pas commencé', 'En cours', 'Non validé', 'Validé', 'Total', 'Score', 'Temps moyen', 'ID Site', 'Nom du site']
      };
      return options;
    }
    if (choose === '3') {
      const options = {
        fieldSeparator: ';',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'Exportation des ' + this.label,
        filename: this.label + '_' + this.formatDate(),
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        // tslint:disable-next-line:max-line-length
        headers: ['ID', 'Titre de l\'UA ', 'Description', 'Durée estimée', 'Sites ', 'Parcours', 'Id Parcours']
      };
      return options;
    }
    if (choose === '4') {
      const options = {
        fieldSeparator: ';',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'Exportation des ' + this.label,
        filename: this.label + '_' + this.formatDate(),
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        // tslint:disable-next-line:max-line-length
        headers: ['Nom de l\'apprenant', 'Est connecté(e) ?']
      };
      return options;
    }
    // certificat choose
    if (choose === '5') {
      const options = {
        fieldSeparator: ';',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'Exportation du certificat ' + this.certificate_label,
        filename: this.certificate_label + '_' + this.formatDate(),
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        // tslint:disable-next-line:max-line-length
        // headers: ['Entreprise', 'GroupeModel', 'nom', 'prénom', 'email ', 'identifiant', 'Code CertiKap', 'nom Certificat', 'Titre du parcours','ID du parcours', 'date de certification', 'date de fin de validité', 'statut du certificat']
        headers: ['']
      };
      return options;
    }
    //certificats all
    if (choose === '6') {
      if(this.certificate_choose){
        const options = {
          fieldSeparator: ';',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true,
          showTitle: true,
          title: 'Exportation des certificats',
          filename: this.label + '_' + this.formatDate(),
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: false,
          // tslint:disable-next-line:max-line-length
          headers: ['']
        };
        return options;
      }
    }

    //verificat choose
    if (choose === '7') {
      const options = {
        fieldSeparator: ';',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'Exportation de la vérification ' + this.verification_label,
        filename: this.verification_label + '_' + this.formatDate(),
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        // tslint:disable-next-line:max-line-length
        headers: ['']
      };
      return options;
    }
  }



  exportation() {
    this.spinner.show();
    this.link_status = false;
    this.link = 'En cours de téléchargement';
    const body = {
      export_type: this.choose,
      export_certificat: this.certificate_choose,
      export_certificat_label: this.certificate_label,
      export_verification: this.verification_choose,
      export_verification_label: this.verification_label,
      completude: this.exportate.completude,
      session_id: this.session_id,
      user_id: this.u_remote_id,
      profile_id: this.user_conntected.profile_id,
      id: this.user_conntected.id,
      array_verification: this.VerificationFormArray.value,
    };

    this.userService.exportation(body)
      .subscribe(res => {
        // Sites Export
        if (res.export_type === '0') {
          const options = this.choose_config(this.choose);
          res.data.forEach((rdata, index) => {
            const data = {
              ID: rdata.site_id,
              Intitule: rdata.site_name,
            };
            this.dataToExport.push(data);
          });
          const csvExporter = new ExportToCsv(options);
          csvExporter.generateCsv(this.dataToExport);
        }

        // Parcours Export
        if (res.export_type == '1') {
          console.log('Downlaod Parcours');
          if (res.success === true) {
            this.showDownloadButton = true;
            this.date_export = res.data.date_export + '.csv';
            window.location.href = this.urlDownload + '' + this.date_export;
          }
        }

        // Session Export
        if (res.export_type == '2') {
          if (res.success === true) {
            this.showDownloadButton = true;
            this.date_export = res.data.date_export + '.csv';
            window.location.href = this.urlDownload + '' + this.date_export;
          }

        }
        // Ua Export
        // if (res.export_type === '3') {
        //   console.log('Downlaod Uas');
        //   if (res.success === true) {
        //     this.showDownloadButton = true;
        //     this.date_export = res.data.date_export + '.csv';
        //     window.location.href = this.urlDownload + '' + this.date_export;
        //   }
        //
        // }
        // Export des personnes connectés
        if (res.export_type == '4') {
          this.dataToExport = [];
          const options = this.choose_config(this.choose);
          if (res.data.length === 0) {
            this.messeNotUser = true;
            this.spinner.hide();
            return false;
          }
          res.data.forEach((rdata, index) => {
            const data = {
              nom_apprenant: rdata.user.fullname,
              est_connecte: rdata.user.is_connected.length === 0 ? 'Non' : 'Oui',
            };
            this.dataToExport.push(data);
          });
          const csvExporter = new ExportToCsv(options);
          csvExporter.generateCsv(this.dataToExport);
        }
        if (res.export_type == '5') {
          if (res.success === true) {
            this.showDownloadButton = true;
            this.date_export = res.data.date_export;
            window.location.href = this.urlDownload + '' + this.date_export;
            this.link = window.location.href = this.urlDownload + '' + this.date_export;
            console.log(this.link);
          }
        }
        if (res.export_type == '6') {
          if (res.success === true) {
            this.showDownloadButton = true;
            this.date_export = res.data.date_export;
            window.location.href = this.urlDownload + this.date_export;
          }
        }
        if (res.export_type == '7') {
          if (res.success === true) {
            this.showDownloadButton = true;
            this.date_export = res.data.date_export;
            window.location.href = this.urlDownload + '' + this.date_export;
          }

        }
        if (res.export_type == '8') {
          if (res.success === true) {
            this.showDownloadButton = true;
            this.date_export = res.data.date_export;
            window.location.href = this.urlDownload + '' + this.date_export;
          }
        }
        this.spinner.hide();
      }, (err) => {
        console.log(err);
      });
  }

  chooseCompletude(e) {
    this.exportate.completude = e.target.value;
  }

  get_list_certification_export() {
    this.certificationService.getCertificat()
      .subscribe(res => {
        // @ts-ignore
        this.certificats = res.data.certificat;
      }, (err) => {
        console.log(err);
      });
  }

  get_list_verification_export() {
    this.verificationService.getVerification()
      .subscribe(res => {
        // @ts-ignore
        this.verifications = res.data.verifications;
      }, (err) => {
        console.log(err);
      });
  }

  choose_certificate(certificate_choose) {
    const options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Exportation des ' + this.label,
      filename: this.label + '_' + this.formatDate(),
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      // tslint:disable-next-line:max-line-length
      headers: ['ID', 'Intitulé']
    };
    return options;
  }

  get_list_verification_by_site_user(user_id) {
    this.verificationService.getVerificationBySiteUser(user_id)
      .subscribe(res => {
        // @ts-ignore
        this.AllVerifications = res.data;
      }, (err) => {
        console.log(err);
      });
  }
}


import { Component, OnInit, Input } from '@angular/core';
import {FormCreatorService} from '../../../../services/form-creator.service';
import {ConfigService} from '../../../../services/config.service';
import Swal from "sweetalert2";

@Component({
  selector: 'app-index-form',
  templateUrl: './index-form.component.html',
  styleUrls: ['./index-form.component.css']
})
export class IndexFormComponent implements OnInit {
  constructor( private formCreatorService: FormCreatorService, private configService: ConfigService) {
    this.urlFormRegistration = this.configService.formURL;
  }
  public entityType = this.configService.entityType

  // tslint:disable-next-line:variable-name
  public user_conntected = JSON.parse(localStorage.getItem('currentUser'));
  // tslint:disable-next-line:variable-name radix
  public profile_id = parseInt(localStorage.getItem('profile_id'));
  // tslint:disable-next-line:variable-name radix
  public site_id = parseInt(localStorage.getItem('site_id'));
  // tslint:disable-next-line:variable-name
  public connected_id = this.user_conntected.id;
  public forms: any;
  // tslint:disable-next-line:variable-name
  public cpt_forms: any;
  public urlFormRegistration: any;
  page = 1;
  // tslint:disable-next-line:variable-name
  showCopyDone = false;

  ngOnInit() {
    this.configService.checkUSerAccessByProfile(this.profile_id);
    this.getListForm(this.profile_id, this.connected_id);
  }
  // tslint:disable-next-line:variable-name
  getListForm(profile_id, user_id) {
    this.formCreatorService.getFormList(profile_id, user_id)
      .subscribe(res => {
        // @ts-ignore
        this.cpt_forms = res.data.length;
        this.forms = res.data;
      }, (err) => {
        console.log(err);
      });
  }
  copyToClipboard(item) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }
  copyMessage(val){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    this.showCopyDone = true;
    // tslint:disable-next-line:only-arrow-functions
    setTimeout(function(){
      this.showCopyDone = false;
    }, 2000);
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  // tslint:disable-next-line:variable-name
  delete_form(id_form) {
    Swal.fire({
      title: 'Etes-vous sûr(e) ?',
      text: 'Cette action est irréversible',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui supprimer',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.value) {
        const body = {
          id: id_form
        };
        // @ts-ignore
        this.formCreatorService.deleteform(body)
          .subscribe(res => {
            this.getListForm(this.profile_id, this.connected_id);
          }, (err) => {
            console.log(err);
          });
        Swal.fire(
          'Supprimé !',
          'Formulaire supprimé avec succès !',
          'success'
        );
      }
    });
  }
}

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"  >
  <p style="font-size: 20px; color: white">Connexion à Certikap en cours...</p>
</ngx-spinner>
<div class="container banner" *ngIf="showForrbidden">
  <div class="jumbotron jumbotron-fluid" style="background-color: #1d81c1">
    <div class="container">
      <h1 class="display-4" style="color:#FFFFFF">Accès interdit !</h1>
      <p class="lead">Vous n'avez pas de droit à ce contenu.</p>
      <p class="lead">Pour plus d'information, veuillez contacter l'équipe Kaptitude</p>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import {User} from '../models/user.model';
import {ConfigService} from './config.service';
import {Observable, throwError} from 'rxjs';
// @ts-ignore
const token = JSON.parse(localStorage.getItem('tokenCurrentConnected'));
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': 'Bearer ' + token
  }),
};

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient, private config: ConfigService) { }

  public entityType = this.config.entityType;

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error}, ` +
        `body was: ${error.error}`);
    }
    console.log(error);
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  private extractData(res: Response) {
    const body = res;
    return body || { };
  }

  getUserDetails(id): Observable<any> {
    return this.http.get(this.config.urlDEV + '/users/' + id, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }

  getUser(id): Observable<any> {
    return this.http.get(this.config.urlDEV + '/users/' + id, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }

  getUserDetailsForPassword(id): Observable<any> {
    return this.http.get(this.config.urlDEV + '/users/details/' + id, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }

  getApprenantDetailWithSite(id): Observable<any> {
    return this.http.get(this.config.urlDEV + '/users/show_details_with_site/' + id, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }
  updateUSer(data): Observable<any> {
    return this.http.post(this.config.urlDEV + '/users/update', data, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }

  editUser(data): Observable<any> {
    return this.http.post(this.config.urlDEV + '/users/edit', data, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }
  getListClient() {
    return this.http.get(this.config.urlDEV + '/users/clients', httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }
  getCountClient() {
    return this.http.get(this.config.urlDEV + '/number_clients', httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }

  // tslint:disable-next-line:variable-name
  getListSuperviseur(profile_id, site_id) {
    return this.http.get(this.config.urlDEV + '/users/superviseurs/' + profile_id + '/' + site_id, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }
  // tslint:disable-next-line:variable-name
  getCountSuperviseur(profile_id, site_id) {
    return this.http.get(this.config.urlDEV + '/number_superviseurs/' + profile_id + '/' + site_id, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }
  // tslint:disable-next-line:variable-name
  getListVerificateur(profile_id, site_id, user_id) {
    return this.http.get(this.config.urlDEV + '/users/verificateurs/' + profile_id + '/' + site_id + '/' + user_id, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }
  // tslint:disable-next-line:variable-name
  getCountVerificateur(profile_id, site_id, user_id) {
    return this.http.get(this.config.urlDEV + '/number_verificateurs/' + profile_id + '/' + site_id + '/' + user_id, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }
  // tslint:disable-next-line:variable-name
  get_user_site(user_id) {
    return this.http.get(this.config.urlDEV + '/users/user_site/' + user_id, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }
  // tslint:disable-next-line:variable-name
  get_user_site_list(user_id) {
    return this.http.get(this.config.urlDEV + '/users/list_user_site/' + user_id, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }
  // tslint:disable-next-line:variable-name
  getListIntervenant(profile_id, site_id, user_id) {
    return this.http.get(this.config.urlDEV + '/users/intervenants/' + profile_id + '/' + site_id + '/' + user_id, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }
  // tslint:disable-next-line:variable-name
  getListUser(profile_id, site_id, user_id) {
    return this.http.get(this.config.urlDEV + '/users/admin/' + profile_id + '/' + site_id + '/' + user_id, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }

  // tslint:disable-next-line:variable-name
  getCountIntervenant(profile_id, site_id, user_id) {
    return this.http.get(this.config.urlDEV + '/number_apprenants/' + profile_id + '/' + site_id + '/' + user_id, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }

  // tslint:disable-next-line:variable-name
  getClient_export() {
    return this.http.get(this.config.urlDEV + '/users/clients_export', httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }

  // tslint:disable-next-line:variable-name
  getSuperviseurs_export(profile_id, site_id) {
    return this.http.get(this.config.urlDEV + '/users/superviseurs_export/' + profile_id + '/' + site_id, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }

  getUserInfos(data) {
    return this.http.post(this.config.urlDEV + '/users/details', data, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }

  // tslint:disable-next-line:variable-name
  getVerificateurs_export(profile_id, site_id, user_id) {
    // tslint:disable-next-line:max-line-length
    return this.http.get(this.config.urlDEV + '/users/verificateurs_export/' + profile_id + '/' + site_id + '/' + user_id, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }
  // tslint:disable-next-line:variable-name
  getIntervenants_export(profile_id, site_id, user_id) {
    // tslint:disable-next-line:max-line-length
    return this.http.get(this.config.urlDEV + '/users/intervenants_export/' + profile_id + '/' + site_id + '/' + user_id, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }

  // tslint:disable-next-line:variable-name
  getUserSiteAnd_Profil_InFront(user_id, profile_id) {
    return this.http.get(this.config.urlDEV + '/get_user_site/' + user_id + '/' + profile_id , httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }
  addUser(data) {
    return this.http.post(this.config.urlDEV + '/users', data, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }
  take_in_hand(data) {
    return this.http.post(this.config.urlDEV + '/users/take/in/hand', data, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }

  deleteClient(id) {
    return this.http.delete(this.config.urlDEV + '/users/' + id, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }
  // tslint:disable-next-line:variable-name
  search_intervenant(data): Observable<any> {
    return this.http.post(this.config.urlDEV + '/users/search', data, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }
  // tslint:disable-next-line:variable-name
  get_intervenant_result(id, verification_id): Observable<any> {
    return this.http.get(this.config.urlDEV + '/users/user_result/' + id + '/' + verification_id, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }
  get_intervenant_cert_details(id): Observable<any> {
    return this.http.get(this.config.urlDEV + '/gaip/pasteur/get_all_user_cert/' + id, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }
  get_all_intervenant_certifications_status(id): Observable<any> {
      return this.http.get(this.config.urlDEV + '/users/user_certifications/' + id, httpOptions).pipe(
        map(this.extractData),
        catchError(this.handleError));
  }
  // tslint:disable-next-line:variable-name
  exportintervenant_certifications_status(certification_id): Observable<any> {
    return this.http.get(this.config.urlDEV + '/gaip/pasteur/export_all_certifications/' + certification_id, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }
  // tslint:disable-next-line:variable-name
  get_user_group_list(id_user): Observable<any> {
    return this.http.get(this.config.urlDEV + '/gaip/pasteur/get_list_user_group/' + id_user, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }
  // tslint:disable-next-line:variable-name
  get_user_verificateur_list(verificateur_id): Observable<any> {
    return this.http.get(this.config.urlDEV + '/users/groups/list_for_search/' + verificateur_id, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }
  // tslint:disable-next-line:variable-name
  get_sessions_list(): Observable<any> {
    return this.http.get(this.config.urlDEV + '/talensoft/session_list', httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }

  sso_connexion_direct_to_talensoft(data) {
    return this.http.post(this.config.urlDEV + '/talensoft/sso/sso_certicap_connexion', data, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }

  forget_password_user(data): Observable<any> {
    return this.http.post(this.config.urlDEV + '/users/forget_password', data, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }
  change_password_user(data): Observable<any> {
    return this.http.post(this.config.urlDEV + '/users/change_password_user', data, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }

  exportation(data): Observable<any> {
    // @ts-ignore
    return this.http.post(this.config.urlDEV + '/talensoft/exportation', data, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }

  sso_connexion_active(data) {
    return this.http.post(this.config.urlDEV + '/talensoft/sso/sso_active_connexion', data, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }

}

<app-header></app-header>
<app-sidebar></app-sidebar>
<div class="main-content">
  <div class="page-content">
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-flex align-items-center justify-content-between">
            <h4 class="mb-0 font-size-18">
              <i class="bx bx-home-circle"></i>  Bienvenue dans votre espace
              <app-fildariane></app-fildariane>
            </h4>
            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item"><a href="javascript: void(0);">Accueil</a></li>
                <li class="breadcrumb-item active">
                  Gestion des formulaires / Ajouter
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <!-- end page title -->
      <div class="row">
      </div>
      <!-- end row -->
      <div class="row">
        <div class="col-lg-12">
          <ng-container *ngIf="site_id === 23">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Création de votre formulaire ...</h4>
              </div>
              <button type="button" class="btn btn-primary waves-effect waves-light"  (click)="create_form()" style="width: 250px;">
                Valider pour terminer
              </button>
              <br>
            </div>
          </ng-container>
          <ng-container *ngIf="site_id !== 23">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Création d'un formulaire</h4>
                <a href="/dashboard/administrator/formulaire">
                  <button type="button" class="btn btn-primary waves-effect waves-light text-right" style="float: right;top: -25px;">
                    <i class="bx bx-arrow-back font-size-16 align-middle mr-2 mb-3"></i> Retour
                  </button>
                </a>
              </div>
              <app-form-builder>
              </app-form-builder>
              <div class="form-group text-center">
                <div class="form-group row">
                  <div class="col-md-4"></div>
                  <img src="assets/img/loaderkapi.gif" height="18" *ngIf="showGif"> <span *ngIf="showGif"> récupération des groupes ...</span>
                  <div class="col-md-4" *ngIf="!showGif && groups?.length > 0">
                    <div class="form-group row">
                      <label class="col-md-5 col-form-label">Nom du formulaire * </label>
                      <div class="col-md-7">
                        <input class="form-control" type="text" [(ngModel)]="label_form_auto_inscription">
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-5 col-form-label">Sélectionner le groupe * </label>
                      <div class="col-md-7">
                        <select class="custom-select"  [(ngModel)]="groups_id" [ngModelOptions]="{standalone: true}" (change)="getGroupChoose($event)">
                          <option *ngFor="let group of groups; let i = index;" value="{{group.group_id}}">{{group.name}}</option>
                        </select>
                      </div>
                    </div>

                  </div>
                  <ng-container *ngIf="groups?.length === 0"> <span style="color:green;font-weight: bold;text-align: center;">Pas de groupes disponibles pour ce site.<br>Il vous sera impossible de créer de formulaie</span></ng-container>
                  <div class="col-md-4"></div>
                </div>

                <button type="button" class="btn btn-primary waves-effect waves-light"  (click)="create_form()" *ngIf="!showGif && groups?.length > 0">
                  Terminer
                </button>
              </div>
            </div>
          </ng-container>
        </div>


      </div>
      <!-- end row -->
    </div>
    <!-- container-fluid -->
  </div>
  </div>
  <!-- End Page-content -->
  <app-footer></app-footer>

<section id="front_wrap">
  <section id="front_page" class="profile_step">
    <section id="menu_front">
    <span class="badge badge-danger" *ngIf="takeInHand && takeInHand === 'yes'">
        <h6 style="color: #ffffff;font-size: 12px;">
        <span class="bx bx-info-circle"></span> Prise en main en cours <br>de ce compte
       </h6>
         <button type="button" class="btn btn-primary waves-effect waves-light" (click)="returner(admin_login)">
            Retour au compte précédent
          </button>
      </span>
      <a class="menu_item menu_search" href="dashboard/inspector"><img src="assets/img/icon_search.png"> <p>Recherche</p></a>
      <a class="menu_item menu_profile current" href="dashboard/inspector/profile"><img src="assets/img/icon_profile.png"> <p>Profil</p></a>
      <a class="menu_item menu_export" href="dashboard/inspector/inspector-export"><img src="assets/img/icon_profile.png"> <p>Export</p></a>
      <a (click)="logout_user()" class="menu_item menu_logout" ><img src="assets/img/icon_logout.png"> <p>Déconnexion</p></a>
    </section>
    <section id="big_front">
      <div id="name_connected">
        <p>{{single_user.name}}</p>
        <img src="{{single_user.photo}}">
      </div>
      <div id="content_front">
        <p class="h1">Mon profil</p>
        <form>
          <div class="row">
            <div class="col-sm-12">
              <img src="{{previewUrl? previewUrl : this.single_user.photo}}" class="rounded-circle" height="100">
              <input  name="image" type="file" id="image_upload" (change)="fileProgress($event)"><label for="image_upload" class="profile_img_upload">Modifier l'image</label>
              <div *ngIf="fileUploadProgress">
                Upload progress: {{ fileUploadProgress }}
              </div>
              <div class="mb-3" *ngIf="uploadedFilePath">
                {{uploadedFilePath}}
              </div>
            </div>
          </div>
          <input type="text"  placeholder="Prénom"  value="{{single_user.firstname}}" [(ngModel)]="single_user.firstname" [ngModelOptions]="{standalone: true}">
          <input type="text"  placeholder="Nom"  value="{{single_user.name}}" [(ngModel)]="single_user.name" [ngModelOptions]="{standalone: true}">
          <input type="email"  placeholder="Email" value="{{single_user.email}}" [(ngModel)]="single_user.email" [ngModelOptions]="{standalone: true}">
          <input type="password" placeholder="Mot de passe"  value="" [(ngModel)]="single_user.password" [ngModelOptions]="{standalone: true}">
          <input type="password" placeholder="Confirmation Mot de passe"  value="" [(ngModel)]="single_user.password_confirm" [ngModelOptions]="{standalone: true}">
          <br><i>Laissez le champ mot de passe vide, si vous ne souhaitez pas le modifier.</i>
          <div class="alert alert-success text-center" role="alert" *ngIf="success_Message">
            {{text}}
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="alert alert-danger text-center" role="alert" *ngIf="not_success_Message">
            {{text}}
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <button class="blue_btn" (click)="update_profile()">Valider</button>
        </form>
      </div>
      <div id="bottom_logo">
        <img src="assets/img/logo-alaya.png">
      </div>
    </section>
  </section>
  <div id="front_legal">
    <p>CERTIKAP - par <strong>Kaptitude</strong> - 2020</p>
  </div>
</section>

import * as tslib_1 from "tslib";
import { OnInit, ElementRef, Renderer2 } from '@angular/core';
import Swal from 'sweetalert2';
import { ExportToCsv } from 'export-to-csv';
export class CertificationComponent {
    // tslint:disable-next-line:max-line-length
    constructor(siteService, orderPipe, configService, certificationService, userService, spinner, el, renderer, router, route) {
        this.siteService = siteService;
        this.orderPipe = orderPipe;
        this.configService = configService;
        this.certificationService = certificationService;
        this.userService = userService;
        this.spinner = spinner;
        this.el = el;
        this.renderer = renderer;
        this.router = router;
        this.route = route;
        this.order = 'certificat.name';
        this.reverse = false;
        // tslint:disable-next-line:variable-name radix
        this.profile_id = parseInt(localStorage.getItem('profile_id'));
        this.show = 10;
        // tslint:disable-next-line:variable-name
        this.showLoader = true;
        this.showLabel = true;
        this.entityType = this.configService.entityType;
        this.AllCertifications = [];
        this.AllSites = [];
        this.sortedCollection = orderPipe.transform(this.certificats, 'certificat.name');
    }
    ngOnInit() {
        this.configService.checkUSerAccessByProfile(this.profile_id);
        this.get_list_certification();
        this.get_list_sites();
    }
    setOrder(value) {
        if (this.order === value) {
            this.reverse = !this.reverse;
        }
        this.order = value;
    }
    get_list_certification() {
        this.spinner.show();
        this.certificationService.getListCertificat()
            .subscribe(res => {
            console.log(res);
            // Éliminer les doublons
            const uniqueCertifications = res.data.filter((certification, index, self) => index === self.findIndex((cert) => cert.id === certification.id));
            // Mettre à jour le compteur et la liste avec les certifications uniques
            this.count_certificat = uniqueCertifications.length;
            this.certificats = uniqueCertifications;
            // @ts-ignore
            this.AllCertifications = res.uniqueCertifications;
            this.spinner.hide();
        }, (err) => {
            console.log(err);
            this.spinner.hide();
        });
    }
    increaseShow() {
        this.spinner.show();
        this.show += 10;
        this.spinner.hide();
    }
    // tslint:disable-next-line:variable-name
    editer(id_certificat) {
        this.router.navigate(['/dashboard/administrator/certification/editer/' + id_certificat], { relativeTo: this.route });
    }
    supprimer(id_certificat) {
        Swal.fire({
            title: 'Etes-vous sûr(e) ?',
            text: 'Cette action est irréversible',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui supprimer',
            cancelButtonText: 'Annuler'
        }).then((result) => {
            if (result.value) {
                const body = {
                    id: id_certificat
                };
                // @ts-ignore
                this.certificationService.deleteCertificats(body)
                    .subscribe(res => {
                    this.addingSuccess = true;
                    this.get_list_certification();
                }, (err) => {
                    console.log(err);
                });
                Swal.fire('Supprimé !', 'Certificat supprimé avec succès !', 'success');
            }
        });
    }
    sites(id_certificat) {
        this.router.navigate(['/dashboard/administrator/certifications/site/' + id_certificat], { relativeTo: this.route });
    }
    verifications(id_certificat) {
        this.router.navigate(['/dashboard/administrator/certifications/verification/' + id_certificat], { relativeTo: this.route });
    }
    listingSousCertificat(id) {
        this.showLoader = true;
        this.showLabel = false;
        this.certificationService.getSousCertificatbyCertificat(id)
            .subscribe(res => {
            // @ts-ignore
            this.showLabel = true;
            if (res.data.length !== 0) {
                this.list_certificat = res.data;
                for (const subCert of this.list_certificat) {
                    this.certId = subCert.certificat_id;
                }
            }
            this.showLoader = false;
        }, (err) => {
            console.log(err);
        });
    }
    exporter() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const dataToExport = [];
            const options = {
                fieldSeparator: ';',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: true,
                title: 'Exportation des certifications',
                filename: 'liste_certification_' + this.formatDate(),
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: false,
                headers: ['ID', 'Libellé', 'Sous-certifications', 'Type de validité', 'Durée']
            };
            // Parcourt chaque certification
            for (const rdata of this.certificats) {
                const data = {
                    nom: rdata.code_cert,
                    label: rdata.name,
                    sous_certifications: rdata.subCertCodes ? rdata.subCertCodes : '',
                    validite: rdata.type_validite === 2 ? 'Mois' : 'Date',
                    duree: rdata.type_validite === 2 ? rdata.delay : rdata.date,
                };
                dataToExport.push(data);
            }
            // Génère le fichier CSV une fois que toutes les données sont prêtes
            const csvExporter = new ExportToCsv(options);
            csvExporter.generateCsv(dataToExport);
        });
    }
    formatDate() {
        // tslint:disable-next-line:one-variable-per-declaration
        const d = new Date();
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();
        const hour = d.getHours();
        const minute = d.getMinutes();
        const seconde = d.getSeconds();
        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }
        const dateJoin = [year, month, day].join('');
        const hourJOin = [hour, minute, seconde].join('');
        return [dateJoin, hourJOin].join('_');
    }
    // tslint:disable-next-line:variable-name
    get_certification_sites(id_site) {
        this.spinner.show();
        this.certificationService.get_certification_sites_list(id_site)
            .subscribe(res => {
            // Éliminer les doublons
            const uniqueCertifications = res.data.filter((certification, index, self) => index === self.findIndex((cert) => cert.id === certification.id));
            // Mettre à jour le compteur et la liste avec les certifications uniques
            this.count_certificat = uniqueCertifications.length;
            this.certificats = uniqueCertifications;
            this.spinner.hide();
        }, (err) => {
            console.log(err);
            this.spinner.hide();
        });
    }
    get_list_sites() {
        this.siteService.getSite()
            .subscribe(res => {
            this.list_sites = res.data;
        }, (err) => {
            console.log(err);
        });
    }
    filterSite(value) {
        // Si l'utilisateur sélectionne "Tout" ou "Veuillez sélectionner le site", récupére toutes les certifications
        if (value === '7xxx7' || value === '788') {
            this.get_list_certification();
        }
        else {
            // Sinon, filtrer par site
            this.get_certification_sites(value);
        }
    }
}

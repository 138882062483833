import { environment } from "../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "ngx-spinner";
export class ConfigService {
    constructor(router, activatedRoute, spinner) {
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.spinner = spinner;
        this.urlDEV = environment.urlDEV;
        // tslint:disable-next-line:variable-name
        this.urlDEV_images = environment.urlDEV_images;
        this.urlDownload = environment.urlDownload;
        this.urlDownloadCertification = environment.urlDownloadCertification;
        this.formURL = environment.formURL;
        this.ELMS_url = environment.ELMS_url;
        this.entityType = environment.entityType;
        this.connectLikeVerifcateur = localStorage.getItem('connect_like_verificateur');
        const pageURL = window.location.href;
        this.lastURLSegment = pageURL.substr(pageURL.lastIndexOf('/') + 1);
    }
    redirectTools() {
        // return false;
        if (!localStorage.getItem('currentUser')) {
            this.router.navigate(['/']);
            this.spinner.hide();
        }
        // tslint:disable-next-line:variable-name
        const profile_id = localStorage.getItem('profile_id');
        if (profile_id !== null) {
            // @ts-ignore
            if (profile_id === '13' || this.router.url === '/') {
                this.router.navigate(['dashboard/inspector']);
            }
            else if (profile_id === '8' || this.router.url === '/') {
                this.router.navigate(['dashboard/inspector']);
            }
            else if (typeof this.connectLikeVerifcateur !== 'undefined' && this.connectLikeVerifcateur === 'yes') {
                this.router.navigate(['dashboard/inspector']);
            }
            else {
                this.router.navigate(['dashboard/administrator']);
                return false;
            }
        }
    }
    checkUSerAccessByProfile(profile) {
        if (!localStorage.getItem('currentUser')) {
            this.router.navigate(['/']);
        }
        this.path = this.router.url;
        if (profile === 13 || profile === 8) {
            this.router.navigate(['dashboard/inspector']);
        }
        if (profile === 8) {
            this.router.navigate(['dashboard/inspector']);
        }
        if (profile === 12 && this.path === '/dashboard/administrator/verificateurs') {
            this.router.navigate(['dashboard/administrator/verificateurs']);
        }
        if (profile === 12 && this.path === '/dashboard/administrator/intervenants') {
            this.router.navigate(['dashboard/administrator/intervenants']);
        }
        if (profile === 12 && this.path === '/dashboard/administrator/intervenants') {
            this.router.navigate(['dashboard/administrator/intervenants']);
        }
        if (profile === 1) {
            if (this.path === '/dashboard/administrator/clients'
                || this.path === '/dashboard/administrator/verifications'
                || this.path === '/dashboard/administrator/certifications'
                || this.path === '/dashboard/administrator/sous-certifications'
                || this.path === '/dashboard/administrator/apprenant/documents'
                || this.path === '/dashboard/administrator/apprenant/certificats'
                || this.path === 'dashboard/administrator/catalogue-management'
                || this.path === 'dashboard/administrator/choice_screen') {
                this.router.navigate(['dashboard/administrator']);
            }
        }
        // profile_id==2 || profile_id==4 || profile_id==5 || profile_id==6 || profile_id==9 || profile_id==10
        if (profile === 4 || profile === 2 || profile === 5 || profile === 6 || profile === 9 || profile === 10) {
            if (this.path === '/dashboard/administrator/clients'
                || this.path === '/dashboard/administrator/verifications'
                || this.path === '/dashboard/administrator/certifications'
                || this.path === '/dashboard/administrator/sous-certifications'
                || this.path === '/dashboard/administrator/superviseurs'
                || this.path === '/dashboard/administrator/apprenant/documents'
                || this.path === '/dashboard/administrator/apprenant/certificats'
                || this.path === '/dashboard/administrator/formulaire/create'
                || this.path === 'dashboard/administrator/catalogue-management'
                || this.path === 'dashboard/administrator/choice_screen') {
                this.router.navigate(['dashboard/administrator']);
            }
        }
        if (profile === 3) {
            if (this.path === '/dashboard/administrator/clients'
                || this.path === '/dashboard/administrator/verifications'
                || this.path === '/dashboard/administrator/certifications'
                || this.path === '/dashboard/administrator/sous-certifications'
                || this.path === '/dashboard/administrator/exports'
                || this.path === '/dashboard/administrator/superviseurs'
                || this.path === '/dashboard/administrator/verificateurs'
                || this.path === '/dashboard/administrator/intervenants'
                || this.path === '/dashboard/administrator/apprenant/documents'
                || this.path === '/dashboard/administrator/apprenant/certificats'
                || this.path === '/dashboard/administrator/formulaire/create'
                || this.path === 'dashboard/administrator/catalogue-management'
                || this.path === 'dashboard/administrator/choice_screen') {
                this.router.navigate(['dashboard/administrator']);
            }
        }
        if (this.connectLikeVerifcateur === 'yes') {
            if (this.path === '/dashboard/administrator/clients'
                || this.path === '/dashboard/administrator/verifications'
                || this.path === '/dashboard/administrator/certifications'
                || this.path === '/dashboard/administrator/sous-certifications'
                || this.path === '/dashboard/administrator/exports'
                || this.path === '/dashboard/administrator/superviseurs'
                || this.path === '/dashboard/administrator/verificateurs'
                || this.path === '/dashboard/administrator/intervenants'
                || this.path === '/dashboard/administrator/apprenant/documents'
                || this.path === '/dashboard/administrator/apprenant/certificats'
                || this.path === '/dashboard/administrator/formulaire/create'
                || this.path === 'dashboard/administrator/catalogue-management'
                || this.path === 'dashboard/administrator/choice_screen') {
                this.router.navigate(['dashboard/inspector']);
            }
            if (typeof this.lastURLSegment !== 'undefined' && Number(this.lastURLSegment)) {
                this.router.navigate(['dashboard/inspector']);
            }
        }
    }
}
ConfigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfigService_Factory() { return new ConfigService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i1.ActivatedRoute), i0.ɵɵinject(i2.NgxSpinnerService)); }, token: ConfigService, providedIn: "root" });

import { Component, OnInit } from '@angular/core';
import {Session} from "../../../../../models/session.model";
import {FormArray, FormBuilder, FormControl} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {SessionService} from "../../../../../services/session.service";
import Swal from "sweetalert2";
import {ConfigService} from "../../../../../services/config.service";
import {Chapitre} from "../../../../../models/chapitre.model";
import {ChapitreService} from "../../../../../services/chapitre.service";
import {
  CdkDrag,
  CdkDragDrop,
  CdkDragPlaceholder,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-create-catalogue-session',
  templateUrl: './create-chapitre.component.html',
  styleUrls: ['./create-chapitre.component.css'],
})
export class CreateChapitreComponent implements OnInit {

  public user_conntected = JSON.parse(localStorage.getItem('currentUser'));
  public profile_id = parseInt(localStorage.getItem('profile_id'));

  //Initialisation
  Sessions: Session[] = [];
  allSessions: Session[] = [];
  Chapitre = new Chapitre();

  //Forms
  FormSessions: any;
  FormSessionsArray: any;
  radioSelected: number;
  radioSelectedType: number;

  //Group formatting
  page = 1;
  order = 'session.name';
  reverse = false;
  vQuery: string;
  catalogue_id: string;

  //create ChoiceScreen
  responseMessage : string;
// @ts-ignore
  @NgModule({
  imports: [
    CdkDropList, CdkDrag, CdkDragPlaceholder
  ],})

constructor(private chapitreService: ChapitreService,
              private sessionService: SessionService,
              private formBuilder: FormBuilder,
              private configService: ConfigService,
              private router: Router,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.catalogue_id = this.activatedRoute.snapshot.url[3].path;
    //access
    this.configService.checkUSerAccessByProfile(this.profile_id);
    //FORMS
    this.FormSessions = this.formBuilder.group({
      session_id: this.formBuilder.array([])
    });
    this.FormSessionsArray = this.FormSessions.get('session_id') as FormArray;

    // Ajoutez des formulaires de groupe pour chaque session sélectionnée
    this.AllSession();
  }


  //SESSIONS
  //Recover all sessions
  AllSession() {
    this.sessionService.getSessionByUser()
      .subscribe(res => {
        this.Sessions = res.sessions;
        this.allSessions = [ ...this.Sessions ]
        console.log(this.Sessions)
      }, (err) => {
        console.log(err);
      });
  }

  //get term of the search
  session_title_value(event : any){
    let session_title;
    session_title = event.target.value;
    this.search_sessions(session_title);
  }

  //Functionality who permit to search session
  search_sessions(session_title){
    //when only research filter byt sessions name
    if(session_title == 'all'){
      this.Sessions = [ ...this.allSessions ];
    }
    else if(session_title && session_title.length > 0) {
      this.Sessions = this.allSessions.filter((Session) => Session.title.toLocaleLowerCase().includes(session_title.toLocaleLowerCase()));
    }
    else{
      this.Sessions = [ ...this.allSessions ];
    }
  }

  //FORM
  check_value(session_id){
    let value_check = this.FormSessionsArray.value.includes(session_id);
    if(value_check){
      return true;
    }
    else{
      return false;
    }
  }

  //FORM
  checkSession(session_id, event : any){
    if(event) {
      this.FormSessionsArray.push(new FormControl(session_id));
    } else {
      let index = this.FormSessionsArray.controls.findIndex(x => x.value == session_id)
      this.FormSessionsArray.removeAt(index);
    }
  }

  //Group formatting
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }

  createChapitre(){
    //This is to rectify an issue, as sending the form with an invalid session would trigger an error.
    this.search_sessions('all');
    //filter sessions in new array in the good order
    let filteredSessions = this.Sessions.filter(session => {
      const id = session['id'];
      // Vérifier si l'ID est présent dans les contrôles du FormArray
      return this.FormSessionsArray.controls.some(control => control.value === id);
    });
    let NewSessionsAssociates = this.FormSessionsArray.controls.map(control => {
      const id = control.value;
      return filteredSessions.find(session => session['id'] === id);
    });
// tslint:disable-next-line:max-line-length
    if (this.Chapitre.titre === undefined || this.Chapitre.titre.trim() === '') {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Le titre est manquant'
      });
      return false;
      // tslint:disable-next-line:align
    }
    else if (this.Chapitre.description === undefined || this.Chapitre.description.trim() === '') {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Veuillez mettre une description',
      });
      return false;
      // tslint:disable-next-line:align
    }
    else if (this.FormSessionsArray.length == 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Veuillez sélectionner au moins une session de choix',
      });
      return false;
      // tslint:disable-next-line:align
    }
    else{
      const body = {
        titre: this.Chapitre.titre,
        description: this.Chapitre.description,
        array_sessions : NewSessionsAssociates,
        catalogue_id : this.catalogue_id
      };
      this.chapitreService.createChapitre(body)
        .subscribe(res => {
          console.log('reponse')
          if(res.success == false){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: res.message
            });
          }
          else{
            // @ts-ignore: Unreachable code error
            this.Chapitre.titre = '';
            this.Chapitre.description = '';
            Swal.fire({
              title: 'Catalogue créé !',
              text: res.message,
              icon: 'success',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Revenir sur la liste des catalogues',
              cancelButtonText: 'Rester sur la page'
            }).then((result) => {
              if (result.isConfirmed) {
                this.router.navigate(['/dashboard/administrator/catalogue-management']);
              }
            });
          }

        }, (err) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
          });
        });
    }
  }


  //CARD DRAG AND DROP
  drop(event: CdkDragDrop<any>) {
    const previousIndex = event.previousIndex;
    const currentIndex = event.currentIndex;

    const movedSession = this.FormSessionsArray.at(previousIndex);
    this.FormSessionsArray.removeAt(previousIndex);
    this.FormSessionsArray.insert(currentIndex, movedSession);
  }

  moveSessionUp(index: number) {
    if (index > 0) {
      const temp = this.FormSessionsArray[index];
      this.FormSessionsArray[index] = this.FormSessionsArray[index - 1];
      this.FormSessionsArray[index - 1] = temp;
    }
  }

  moveSessionDown(index: number) {
    if (index < this.FormSessionsArray.length - 1) {
      const temp = this.FormSessionsArray[index];
      this.FormSessionsArray[index] = this.FormSessionsArray[index + 1];
      this.FormSessionsArray[index + 1] = temp;
    }
  }

  toggleCollapse(session: any) {
    session.showDescription = !session.showDescription;
  }

  changeDuree(session_id,duree) {
    // Trouver l'index de la session dans l'array
    const sessionIndex = this.Sessions.findIndex(s => s.id === session_id);

    if (sessionIndex !== -1) {
      // Mettre à jour la session avec la nouvelle donnée d'événement
      this.Sessions[sessionIndex].duree = duree;
    } else {
      console.error('Session non trouvée dans FormSessionsArray');
    }
  }

  updateSessionDescription(newDescription: string, session: Session): void {
    const sessionIndex = this.Sessions.findIndex(s => s.id === session.id);
    if (sessionIndex !== -1) {
      this.Sessions[sessionIndex].description = newDescription;
    } else {
      console.error('Session not found in Sessions array');
    }
  }

  changeType(session_id, type: string){
    const sessionIndex = this.Sessions.findIndex(s => s.id === session_id);

    if (sessionIndex !== -1) {
      // Mettre à jour la session avec la nouvelle donnée d'événement
      this.Sessions[sessionIndex].type = type;
    } else {
      console.error('Session non trouvée dans FormSessionsArray');
    }
  }

}

<app-header></app-header>
<app-sidebar></app-sidebar>
<div class="main-content">
  <div class="page-content">
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-flex align-items-center justify-content-between">
            <h4 class="mb-0 font-size-18">
              <i class="bx bx-home-circle"></i>  Bienvenue dans votre espace
              <app-fildariane></app-fildariane>
            </h4>
            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item"><a href="javascript: void(0);">Accueil</a></li>
                <li class="breadcrumb-item active">Gestion des certifications
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->

      <div class="row">

        <div class="col-lg-12">
          <div class="alert alert-success text-center" role="alert" *ngIf="addingSuccess">
            {{messageText}}
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <a href="javascript:window.history.back();" class="mb-2 btn btn-primary waves-effect waves-light btn-sm mb-3"><i class="mdi mdi-chevron-left ml-1"></i> Retour </a>

          <div class="card">
            <div class="card-body">

              <h4 class="card-title">Ajout d'une certification</h4>

                <div class="form-group row">
                  <label class="col-md-2 col-form-label">Veuillez sélectionner le type</label>
                  <div class="col-md-10">
                    <select class="custom-select" [(ngModel)]="certification.type">
                        <option value="1">Certification</option>
                        <option value="0">Sous-certification</option>

                    </select>
                  </div>
                </div>
                <ng-container *ngIf="certification.type == 0">
                  <div class="form-group row">
                    <label class="col-md-2 col-form-label">Veuillez sélectionner la certification</label>
                    <div class="col-md-10">
                      <select class="custom-select" [(ngModel)]="certification.id">
                          <option *ngFor="let certificat of certificats; let i = index" [value]="certificat.id">{{certificat.name}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-2 col-form-label">Libellé</label>
                    <div class="col-md-10">
                      <input class="form-control" type="text" [(ngModel)]="certification.name">
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-2 col-form-label">Description</label>
                    <div class="col-md-10">
                      <textarea required="" class="form-control" rows="5" [(ngModel)]="certification.description"></textarea>
                    </div>
                  </div>
                  <ng-container *ngIf="entityType == 'EKAMPUS'">
                  <div class="form-group row">
                    <label class="col-md-2 col-form-label">Site(s)</label>
                    <div class="col-md-10">
                      <ng-container *ngFor="let site of sites; let c = check;">
                        <div class="form-check form-check-inline col-md-2">
                          <input class="form-check-input" #c type="checkbox" (change)=" check(site.id, $event.target.checked)" id="{{site.id}}" value="{{site.id}}">
                          <label class="form-check-label"  [style.color]="c.checked ? '#0000FF':'' "  for="{{site.id}}">{{site.name}}</label>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  </ng-container>

                  <div class="form-group row">
                    <label class="col-md-2 col-form-label">Validité</label>
                    <div class="col-md-1">
                      <input class="form-check-input" type="radio" name="exampleRadios" value="2" [(ngModel)]="readioSelected" (change)="changeValidite($event)">
                      <label class="form-check-label">
                        Par durée (mois)
                      </label>
                    </div>
                    <div class="col-md-1">
                      <input class="form-check-input" type="radio" name="exampleRadios" value="1" [(ngModel)]="readioSelected" (change)="changeValidite($event)">
                      <label class="form-check-label">
                        Par date
                      </label>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="certification.type == 1">
                  <div class="form-group row">
                    <label class="col-md-2 col-form-label">Libellé</label>
                    <div class="col-md-10">
                      <input class="form-control" type="text" [(ngModel)]="certification.name">
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-2 col-form-label">Description</label>
                    <div class="col-md-10">
                      <textarea required="" class="form-control" rows="5" [(ngModel)]="certification.description"></textarea>
                    </div>
                  </div>

                  <ng-container *ngIf="entityType == 'EKAMPUS'">
                  <div class="form-group row">
                    <label class="col-md-2 col-form-label">Site(s)</label>
                    <div class="col-md-10">
                      <ng-container *ngFor="let site of sites; let c = check;">
                        <div class="form-check form-check-inline col-md-2">
                          <input class="form-check-input" #c type="checkbox" (change)=" check(site.id, $event.target.checked)" id="{{site.id}}" value="{{site.id}}">
                          <label class="form-check-label"  [style.color]="c.checked ? '#0000FF':'' "  for="{{site.id}}">{{site.name}}</label>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  </ng-container>

                  <div class="form-group row">
                    <label class="col-md-2 col-form-label">Validité</label>
                    <div class="col-md-1">
                      <input class="form-check-input" type="radio" name="exampleRadios" value="2" [(ngModel)]="readioSelected" (change)="changeValidite($event)">
                      <label class="form-check-label">
                        Par durée (mois)
                      </label>
                    </div>
                    <div class="col-md-1">
                      <input class="form-check-input" type="radio" name="exampleRadios" value="1" [(ngModel)]="readioSelected" (change)="changeValidite($event)">
                      <label class="form-check-label">
                        Par date
                      </label>
                    </div>
                  </div>

                </ng-container>

              <div class="form-group row" *ngIf="readioSelected == 1">
                <label class="col-md-2 col-form-label">Date</label>
                <div class="col-md-3">
                  <input class="form-control" type="date" [(ngModel)]="certification.date">
                </div>
              </div>
              <div class="form-group row" *ngIf="readioSelected == 2">
                <label class="col-md-2 col-form-label">Durée</label>
                <div class="col-md-3">
                  <input class="form-control" type="text" [(ngModel)]="certification.delay">
                </div>
              </div>
              <div class="form-group text-center" *ngIf="certification.type">
                <button type="button" class="btn btn-primary waves-effect waves-light" (click)="add_certificat()">
                  Ajouter
                </button>
              </div>
            </div>
          </div>
          <!-- end row -->
          <!-- end row -->
        </div>
        <!-- container-fluid -->
      </div>
      <!-- End Page-content -->
      <app-footer></app-footer>

<!-- Begin page -->
<div id="layout-wrapper">
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <a href="/dashboard/administrator" class="logo logo-dark">
                                <span class="logo-sm">
                                    <img src="assets/img/logo-alaya.png" alt="" height="22">
                                </span>
            <span class="logo-lg">
                                    <img src="assets/img/logo-alaya.png" alt="" height="17">
                                </span>
          </a>

          <a href="/dashboard/administrator" class="logo logo-light">
                                <span class="logo-sm">
                                    <img src="assets/img/logo-alaya.png" alt="" height="22">
                                </span>
            <span class="logo-lg">
                                    <img src="assets/img/logo-alaya.png" alt="" height="19">
                                </span>
          </a>
        </div>

        <button type="button" class="btn btn-sm px-3 font-size-16 header-item waves-effect" id="vertical-menu-btn">
          <i class="fa fa-fw fa-bars"></i>
        </button>

      </div>
      <span class="badge badge-danger" *ngIf="takeInHand && takeInHand === 'yes'">
        <h4 style="color: #ffffff;"><span class="bx bx-info-circle"></span> Prise en main en cours de ce compte </h4>
        <button type="button" class="btn btn-primary waves-effect waves-light" (click)="returner(admin_login)">
            Revenir au compte précédent
          </button>
      </span>
      <div class="d-flex">
        <div class="dropdown d-inline-block">
          <button type="button" class="btn header-item waves-effect" id="page-header-user-dropdown"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img class="rounded-circle header-profile-user" src="{{single_user.photo}}"
                 alt="Header Avatar">
            <span class="d-none d-xl-inline-block ml-1">{{single_user.name}}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-right">
            <!-- item-->
            <a class="dropdown-item" href="/dashboard/administrator/profile"><i class="bx bx-user font-size-16 align-middle mr-1"></i> Mon profil</a>
            <a class="dropdown-item text-danger" (click)="logout_user()"><i class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> Se déconnecter</a>
          </div>
        </div>
      </div>
    </div>
  </header>
</div>

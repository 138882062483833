import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
export class DataService {
    constructor() {
        this.subject = new Subject();
    }
    setData(message) {
        // just an example:
        this.subject.next({ htmlCode: message });
    }
    setOldData(message) {
        // just an example:
        this.subject.next({ htmlCode: message });
    }
    setDataGroup(message) {
        // just an example:
        this.subject.next({ htmlCodeGroup: message });
    }
    getData() {
        return this.subject.asObservable();
    }
    getOldData() {
        return this.subject.asObservable();
    }
    getDataGroup() {
        return this.subject.asObservable();
    }
}
DataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DataService_Factory() { return new DataService(); }, token: DataService, providedIn: "root" });

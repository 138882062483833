<app-header></app-header>
<app-sidebar></app-sidebar>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"  >
  <p style="font-size: 20px; color: white">Chargement de la liste en cours...</p>
</ngx-spinner>
<div class="main-content">
  <div class="page-content">
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-flex align-items-center justify-content-between">
            <h4 class="mb-0 font-size-18">
              <i class="bx bx-home-circle"></i>  Bienvenue dans votre espace
              <app-fildariane></app-fildariane>
            </h4>
            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item"><a href="javascript: void(0);">Accueil</a></li>
                <li class="breadcrumb-item active">Gestion des vérifications
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->
      <div class="row">
        <div class="col-lg-2" style="margin-bottom: 20px">
          <a href="/dashboard/administrator/verification/ajouter">
            <button type="button" class="btn btn-primary waves-effect waves-light">
            <i class="bx bx-plus font-size-16 align-middle mr-2"></i> Ajouter
          </button></a>
        </div>
        <div class="col-lg-2" style="margin-bottom: 20px;float: right">
          <button type="button" class="btn btn-primary waves-effect waves-light" (click)="exporter()">
            <i class="bx bx-export font-size-16 align-middle mr-2"></i> Exporter
          </button>
        </div>
      </div>

    </div>
      <br>

      <div class="row">
        <div class="col-lg-12">
        </div>
        <div class="col-lg-{{entityType == 'IP' ? 12 : 9}}">
          <div class="alert alert-success text-center" role="alert" *ngIf="addingSuccess">
            Action éffectuée avec <a href="#" class="alert-link">succès</a>.
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div id="accordion">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">LISTE DES VERIFICATIONS ({{count_verification}})</h4>
              <div class="table-responsive">
                <table class="table mb-0">
                  <thead>
                  <tr>
                    <th [class.active]="order === 'verifications.id'"
                    (click)="setOrder('verifications.id')">ID <span [hidden]="reverse">▼</span
                  ><span [hidden]="!reverse">▲</span></th>
                    <th [class.active]="order === 'verifications.name'"
                        (click)="setOrder('verifications.name')">Libellés <span [hidden]="reverse">▼</span
                    ><span [hidden]="!reverse">▲</span></th>
                    <th *ngIf="entityType == 'EKAMPUS'">Sites</th>
                    <th>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let verification of AllVerifications | orderBy: order:reverse:false; let i = index">

                    <td scope="row">
                      <p>
                        <a data-toggle="collapse" href="#collapse_{{verification.code_verif}}" role="button" aria-expanded="false" data-target="collapse_{{verification.code_verif}}"  (click)="listingCertificat(verification.id)">
                          <span class="caret" *ngIf="count_certifification[i] >= 0">{{verification.code_verif}}</span>
                        </a>
                      </p>
                      <div class="collapse" id="collapse_{{verification.code_verif}}" data-parent="#accordion">
                        <ul class="tree">
                          <span *ngIf="showLoader"><img src="../../../../assets/img/loaderkapi.gif" height="12"></span>
                          <ng-container *ngIf="showLabel"> <li *ngFor="let certif of list_certificat"><a href="/dashboard/administrator/certification/editer/{{certif.certifId}}">{{certif.certifName}}</a></li></ng-container>
                        </ul>
                      </div>
                    </td>
                    <td>{{verification.name}}</td>
                    <ng-container *ngIf="entityType == 'EKAMPUS'">
                    <td>
                      <ng-container *ngFor="let verificationsSite of AllverificationsSites">
                        <ng-container *ngIf="verificationsSite.verifications_id == verification.id ">
                          <ng-container *ngFor="let Site of AllSites">
                            <ng-container *ngIf="Site.id == verificationsSite.sites_id">
                              {{Site.name}} ;
                            </ng-container>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </td>
                    </ng-container>
                    <td>
                      <i class="bx bx-edit pointer" (click)="editer(verification.id)">Editer /</i>
                      <i class="bx bx-trash-alt pointer" (click)="supprimer(verification.id)">Supprimer</i>
                    </td>
                  </tr>
                  </tbody>
                </table>

              </div>

            </div>
          </div>
          </div>
        </div>

        <ng-container *ngIf="entityType == 'EKAMPUS'">
        <div class="col-lg-3" *ngIf="profile_id === 11">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">FILTRER</h4>
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Site</label>
                <div class="col-md-8">
                  <select class="custom-select" [(ngModel)]="site_id" (change)="filterSite($event.target.value)">
                    <option selected="">Veuillez sélectionner le site</option>
                    <option value="7xxx7">Tout</option>
                    <option *ngFor="let site of sites" value="{{site.id}}">{{site.name}}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        </ng-container>

        <!-- end row -->
      <!-- end row -->
    </div>
    <!-- container-fluid -->
  </div>
  </div>
  <!-- End Page-content -->
  <app-footer></app-footer>

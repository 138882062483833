import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fildarianeEKAMPUS',
  templateUrl: './fildarianeEKAMPUS.component.html',
  styleUrls: ['./fildarianeEKAMPUS.component.css']
})
export class FildarianeEKAMPUSComponent implements OnInit {
  // tslint:disable-next-line:variable-name radix
  public profile_id = parseInt(localStorage.getItem('profile_id'));
  constructor() { }

  ngOnInit() {
  }

}

import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {ConfigService} from './config.service';
import {Observable, throwError} from 'rxjs';
// @ts-ignore
const token = JSON.parse(localStorage.getItem('tokenCurrentConnected'));
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': 'Bearer ' + token
  }),
};

@Injectable({
  providedIn: 'root'
})
export class ChoiceScreenService {

  constructor(private http: HttpClient, private config: ConfigService) { }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error}, ` +
        `body was: ${error.error}`);
    }
    return throwError('Something bad happened; please try again later.');
  }

  private static extractData(res: Response) {
    return res || { };
  }

  getAllChoiceScreen(): Observable<any> {
    return this.http.get(this.config.urlDEV + '/choice_screen', httpOptions).pipe(
      map(ChoiceScreenService.extractData),
      catchError(this.handleError));
  }

  getChoiceScreen(id): Observable<any> {
    return this.http.get(this.config.urlDEV + '/choice_screen/' +id ,httpOptions).pipe(
      map(ChoiceScreenService.extractData),
      catchError((error) => this.handleError(error)));
  }

  createChoiceScreen(data): Observable<any> {
    return this.http.post(this.config.urlDEV + '/choice_screen', data,httpOptions).pipe(
      map(ChoiceScreenService.extractData),
      catchError((error) => this.handleError(error)));
  }

  updateChoiceScreen(id,data): Observable<any> {
    return this.http.put(this.config.urlDEV + '/choice_screen/' +id, data,httpOptions).pipe(
      map(ChoiceScreenService.extractData),
      catchError((error) => this.handleError(error)));
  }

  deleteChoiceScreen(id): Observable<any> {
    return this.http.delete(this.config.urlDEV + '/choice_screen/' +id,httpOptions).pipe(
      map(ChoiceScreenService.extractData),
      catchError((error) => this.handleError(error)));
  }

  getByGroups(data): Observable<any> {
    return this.http.post(this.config.urlDEV + '/choice_screen/groups', data,httpOptions).pipe(
      map(ChoiceScreenService.extractData),
      catchError((error) => this.handleError(error)));
  }

}

<app-header></app-header>
<app-sidebar></app-sidebar>
<div class="main-content">
  <div class="page-content">
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-flex align-items-center justify-content-between">
            <h4 class="mb-0 font-size-18">
              <i class="bx bx-home-circle"></i>  Bienvenue dans votre espace
              <app-fildariane></app-fildariane>
            </h4>
            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item"><a href="javascript: void(0);">Accueil</a></li>
                <li class="breadcrumb-item active">Création d'un nouveau écran de choix
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->
      <div class="row" style="padding-bottom: 5px">
        <a href="javascript:window.history.back();" class="btn btn-primary waves-effect waves-light btn-sm mb-3"><i class="mdi mdi-chevron-left ml-1"></i> Retour </a>
      </div>
      <div class="row">
        <div class="card col-12">
          <div class="card-body">
            <h4 class="card-title">Ajout d'un ecran de choix</h4>
            <div class="form-group row">
              <label class="col-md-2 col-form-label">Titre</label>
              <div class="col-md-10">
                <input class="form-control" type="text" value="{{ChoiceScreen.titre}}" [(ngModel)]="ChoiceScreen.titre">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-2 col-form-label">Description</label>
              <div class="col-md-10">
                <textarea required="" class="form-control" rows="5" value="{{ChoiceScreen.description}}" [(ngModel)]="ChoiceScreen.description"></textarea>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-2 col-form-label">Groupe d'entré</label>
              <div class="col-md-10">
                <select class="custom-select" [(ngModel)]="ChoiceScreen.groupe_id">
                  <option *ngFor="let group of GroupesEntre; let i = index" [value]="group.id">{{group.name}}</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-2 col-form-label">Groupe(s) choix associé(s)</label>
              <div class="col-md-10">
                <input class="form-control mt-3" type="text" id="example-text-input" placeholder="Veuillez entrer le nom du groupe à rechercher" [(ngModel)]="vQuery" (keyup)="groupe_name_value($event)">
                <div *ngIf="FormGroupesArray.length >= 1" class="form-group row">
                  <label class="col-md-6 col-form-label">Groupe(s) choix associé(s) ajouté(s) :</label>
                  <div class="col-md-10">
                    <form [formGroup]="FormGroupes">
                      <div *ngFor="let control of FormGroupesArray.controls; let i = index">
                        <ng-container *ngFor="let group of allGroupes">
                          <ng-container *ngIf="control.value == group.id">
                            {{group.name}}
                          </ng-container>
                        </ng-container>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="table-responsive mt-3 mb-3">
                  <table class="table mb-0">
                    <thead>
                    <tr>
                      <th [class.active]="order === 'groupe.name'"
                          (click)="setOrder('groupe.name')">Nom
                        <span [hidden]="reverse">▼</span
                        ><span [hidden]="!reverse">▲</span></th>
                      <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let groupe of GroupesChoice |  orderBy: order:reverse:false | filter:vQuery | paginate: { itemsPerPage: 10, currentPage: page } | slice:0:10; let i=index">
                      <td>{{groupe.name}}</td>
                      <td>
                        <div class="col-md-20">
                          <ng-container *ngIf="check_value(groupe.id) == true">
                            <input class="form-check-input" type="checkbox" checked (change)=" checkGroupe(groupe.id, $event.target.checked);" #c  value="{{groupe.id}}">
                            <label class="form-check-label" [style.color]="c.checked || check_value(groupe.id) == true ? '#0000FF':'' "></label>
                          </ng-container>
                          <ng-container *ngIf="check_value(groupe.id) == false">
                            <input class="form-check-input" type="checkbox" (change)=" checkGroupe(groupe.id, $event.target.checked);" #c value="{{groupe.id}}">
                            <label class="form-check-label" [style.color]="c.checked ? '#0000FF':'' " ></label>
                          </ng-container>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-md-12 text-center">
                  <pagination-controls
                    previousLabel="Précedent"
                    nextLabel="Suivant"
                    responsive="true"
                    (pageChange)="page = $event"></pagination-controls>
                </div>
              </div>
            </div>

            <div class="form-group text-center">
              <button type="button" class="btn btn-primary waves-effect waves-light" (click)="updateChoiceScreen(ChoiceScreen.id)">
                Mise à jour
              </button>
            </div>
          </div>
        </div>
        <!-- end row -->
        <!-- end row -->
      </div>
      <!-- container-fluid -->
    </div>
  </div>
</div>
<!-- End Page-content -->
<app-footer></app-footer>

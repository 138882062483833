/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./auto-inscription-start.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./auto-inscription-start.component";
import * as i3 from "@angular/platform-browser";
import * as i4 from "@angular/router";
import * as i5 from "../../../../services/user.service";
import * as i6 from "../../../../services/form-creator.service";
var styles_AutoInscriptionStartComponent = [i0.styles];
var RenderType_AutoInscriptionStartComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AutoInscriptionStartComponent, data: {} });
export { RenderType_AutoInscriptionStartComponent as RenderType_AutoInscriptionStartComponent };
export function View_AutoInscriptionStartComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["style", "text-align: center;margin: 0 auto 0;font-weight: bold;font-size: 30px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["R\u00E9direction en cours ..."]))], null, null); }
export function View_AutoInscriptionStartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-auto-inscription-start", [], null, null, null, View_AutoInscriptionStartComponent_0, RenderType_AutoInscriptionStartComponent)), i1.ɵdid(1, 114688, null, 0, i2.AutoInscriptionStartComponent, [i3.Meta, i3.Title, i4.ActivatedRoute, i4.Router, i5.UserService, i6.FormCreatorService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AutoInscriptionStartComponentNgFactory = i1.ɵccf("app-auto-inscription-start", i2.AutoInscriptionStartComponent, View_AutoInscriptionStartComponent_Host_0, {}, {}, []);
export { AutoInscriptionStartComponentNgFactory as AutoInscriptionStartComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page-non-trouvee.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./page-non-trouvee.component";
var styles_PageNonTrouveeComponent = [i0.styles];
var RenderType_PageNonTrouveeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageNonTrouveeComponent, data: {} });
export { RenderType_PageNonTrouveeComponent as RenderType_PageNonTrouveeComponent };
export function View_PageNonTrouveeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["style", "font-weight: bold;text-align: center;font-size: 300px"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["404"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [["style", "font-weight: bold;text-align: center;font-size: 50px"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Page non trouv\u00E9e"]))], null, null); }
export function View_PageNonTrouveeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-non-trouvee", [], null, null, null, View_PageNonTrouveeComponent_0, RenderType_PageNonTrouveeComponent)), i1.ɵdid(1, 114688, null, 0, i2.PageNonTrouveeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageNonTrouveeComponentNgFactory = i1.ɵccf("app-page-non-trouvee", i2.PageNonTrouveeComponent, View_PageNonTrouveeComponent_Host_0, {}, {}, []);
export { PageNonTrouveeComponentNgFactory as PageNonTrouveeComponentNgFactory };

<app-header></app-header>
<app-sidebar></app-sidebar>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"  >
  <p style="font-size: 20px; color: white">Chargement de la liste en cours...</p>
</ngx-spinner>
<div class="main-content">
  <div class="page-content">
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-flex align-items-center justify-content-between">
            <h4 class="mb-0 font-size-18">
              <i class="bx bx-home-circle"></i>  Bienvenue dans votre espace
              <app-fildariane></app-fildariane>
            </h4>
            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item"><a href="javascript: void(0);">Accueil</a></li>
                <li class="breadcrumb-item active">Catalogue Management
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->
      <div class="row">
        <div class="col-2" style="margin-bottom: 20px">
          <a href="/dashboard/administrator/catalogue-management/create">
            <button type="button" class="btn btn-primary waves-effect waves-light">
              <i class="bx bx-plus font-size-16 align-middle mr-2"></i> Ajouter
            </button></a>
        </div>
        <div class="col-4" style="margin-bottom: 20px">
          <button type="button" class="btn btn-primary waves-effect waves-light" (click)="synchronisation_session()">
            <i class="font-size-12 align-middle mr-2"></i> Synchroniser les sessions
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12" style="margin-bottom: 20px;">
          <input class="form-control" type="text" placeholder="Veuillez entrer l'id ou le titre du catalogue à rechercher" [(ngModel)]="vQuery">
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-12">
          <div id="accordion">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">LISTE DES CATALOGUES ({{catalogues.length}})</h4>
                <div class="card-catalogue mb-3" *ngFor="let catalogue of catalogues | orderBy: order:reverse: false | filter:vQuery | paginate: { itemsPerPage: 10, currentPage: page } | slice:0:show; let i=index">
                  <div class="card-body border">
                    <div class="row mb-2">
                      <div class="col-sm-9">
                        <h5 class="card-titre">{{catalogue.titre}}</h5>
                        <div class="mt-3">Description :</div>
                        <div class="mt-2">{{ catalogue.description }}</div>
                        <div class="mt-3">Langue : {{ catalogue.langue }}</div>
                      </div>
                      <div class="col-sm-3">
                        <i class="bx bx-edit pointer" (click)="update(catalogue.id)">Editer / </i>
                        <i class="bx bx-trash-alt pointer" (click)="delete(catalogue.id)">Supprimer</i>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-4" style="margin-bottom: 20px">
                        <a href="/dashboard/administrator/catalogue-chapitre/{{catalogue.id}}/create">
                          <button type="button" class="btn btn-primary waves-effect waves-light">
                            <i class="bx bx-plus font-size-12 align-middle mr-2"></i> Ajouter un chapitre
                          </button></a>
                      </div>
                    </div>
                    <mat-accordion>
                      <mat-expansion-panel *ngFor="let chapitre of catalogue.chapitres" [expanded]="chapitre.showDescription">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <div class="title mb-0 mt-0">
                              {{ chapitre.titre }}
                            </div>
                          </mat-panel-title>
                          <div class="mr-5">
                          <i class="bx bx-edit pointer" (click)="update_chapitre(catalogue.id,chapitre.id)">Editer / </i>
                          <i class="bx bx-trash-alt pointer" (click)="delete_chapitre(chapitre.id)">Supprimer</i>
                          </div>
                        </mat-expansion-panel-header>
                        <div>Description :</div>
                        <div>{{ chapitre.description }}</div>
                        <div class="table-responsive mt-3 mb-3">
                          <table class="table mb-0">
                            <thead>
                            <tr>
                              <th>Titre</th>
                              <th>Durée</th>
                              <th>Type</th>
                              <th>Code cert</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let session of chapitre.sessions_chapitres">
                              <td>{{session.title_catalogue}}</td>
                              <td>{{session.duree}}</td>
                              <td>{{session.type}}</td>
                              <td>{{session.code_cert}}</td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                </div>
                <div class="col-md-12 text-center">
                  <pagination-controls
                    previousLabel="Précedent"
                    nextLabel="Suivant"
                    responsive="true"
                    (pageChange)="page = $event"></pagination-controls>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
        <!-- end row -->
      </div>
      <!-- container-fluid -->
    </div>
    <!-- End Page-content -->
    <app-footer></app-footer>
  </div>
</div>


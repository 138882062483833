import { HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./config.service";
// @ts-ignore
const token = JSON.parse(localStorage.getItem('tokenCurrentConnected'));
const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
    }),
};
export class ChoiceScreenService {
    constructor(http, config) {
        this.http = http;
        this.config = config;
    }
    handleError(error) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        }
        else {
            console.error(`Backend returned code ${error}, ` +
                `body was: ${error.error}`);
        }
        return throwError('Something bad happened; please try again later.');
    }
    static extractData(res) {
        return res || {};
    }
    getAllChoiceScreen() {
        return this.http.get(this.config.urlDEV + '/choice_screen', httpOptions).pipe(map(ChoiceScreenService.extractData), catchError(this.handleError));
    }
    getChoiceScreen(id) {
        return this.http.get(this.config.urlDEV + '/choice_screen/' + id, httpOptions).pipe(map(ChoiceScreenService.extractData), catchError((error) => this.handleError(error)));
    }
    createChoiceScreen(data) {
        return this.http.post(this.config.urlDEV + '/choice_screen', data, httpOptions).pipe(map(ChoiceScreenService.extractData), catchError((error) => this.handleError(error)));
    }
    updateChoiceScreen(id, data) {
        return this.http.put(this.config.urlDEV + '/choice_screen/' + id, data, httpOptions).pipe(map(ChoiceScreenService.extractData), catchError((error) => this.handleError(error)));
    }
    deleteChoiceScreen(id) {
        return this.http.delete(this.config.urlDEV + '/choice_screen/' + id, httpOptions).pipe(map(ChoiceScreenService.extractData), catchError((error) => this.handleError(error)));
    }
    getByGroups(data) {
        return this.http.post(this.config.urlDEV + '/choice_screen/groups', data, httpOptions).pipe(map(ChoiceScreenService.extractData), catchError((error) => this.handleError(error)));
    }
}
ChoiceScreenService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ChoiceScreenService_Factory() { return new ChoiceScreenService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ConfigService)); }, token: ChoiceScreenService, providedIn: "root" });

<ngx-spinner></ngx-spinner>
<section id="front_wrap">
  <section id="connect_page">
    <div id="connect_title">
      <p>Nouveau mot de passe</p>
    </div>
    <div id="connect_content">
      <form>
        <input type="email">
        <input type="password"  placeholder="Nouveau mot de passe" [(ngModel)]="new_password" [ngModelOptions]="{standalone: true}">

        <button class="blue_btn"  type="button" color="accent" (click)="change_password()">Changer</button>
        <p style="margin-top: 25px"><a href="/"><< Se connecter</a></p>
      </form>
    </div>
    <div id="connect_legal">
      <ng-container *ngIf="entityType == 'EKAMPUS'">
        <p>CertiKap - par <strong>Kaptitude</strong> - 2021</p>
      </ng-container>
      <ng-container *ngIf="entityType == 'IP'">
        <p>Institut PASTEUR - par <strong>Kaptitude</strong> - 2021</p>
      </ng-container>

    </div>
  </section>
</section>

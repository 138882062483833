import {Component, ViewChild, ElementRef, AfterViewInit, OnInit} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {DataService} from '../../../../services/data.service';
import Swal from 'sweetalert2';
import {ActivatedRoute} from '@angular/router';
import {FormCreatorService} from '../../../../services/form-creator.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.css']
})
export class FormBuilderComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  required_value = 'accent';
  checked = false;
  // tslint:disable-next-line:variable-name
  public id_form: any;
  // tslint:disable-next-line:variable-name
  public old_forms: any;
  // tslint:disable-next-line:variable-name
  public final_element_forms = [ { name: '',
    type: '',
    inputType: '',
    placeholder: '',
    filed_name: '',
    ng_model_name: '',
    is_required: 0
  }];
  private sub: any;
  modalPopupObject: any;
  display = false;
  vegetables = [
    // tslint:disable-next-line:max-line-length
    { name: 'Service', type: 'input-text', inputType: 'text', placeholder: '',  filed_name: 'Service', ng_model_name: 'service', is_required: 1, translation: 'Service'},
    // tslint:disable-next-line:max-line-length
    { name: 'N° RPPS/ADELI', type: 'input-text', inputType: 'text', placeholder: '',  filed_name: 'N° RPPS/ADELI', ng_model_name: 'rppsadeli', is_required: 1 , translation: 'N° RPPS/ADELI'},
    // tslint:disable-next-line:max-line-length
    { name: 'Matricule ', type: 'input-text', inputType: 'text', placeholder: '',  filed_name: 'Matricule', ng_model_name: 'matricule', is_required: 1 , translation: 'Staff Number'},
    // tslint:disable-next-line:max-line-length
    { name: 'Société ', type: 'input-text', inputType: 'text', placeholder: '',  filed_name: 'Société', ng_model_name: 'societe', is_required: 1, translation: 'Company' },
    // tslint:disable-next-line:max-line-length
    { name: 'Ville ', type: 'input-text', inputType: 'text', placeholder: '',  filed_name: 'Ville', ng_model_name: 'ville', is_required: 1 , translation: 'City'},
    // tslint:disable-next-line:max-line-length
    { name: 'Manager Nom', type: 'input-text', inputType: 'text', placeholder: '' ,  filed_name: 'Manager Nom', ng_model_name: 'managernom', is_required: 1, translation: 'Manager\'s Last Name'},
    // tslint:disable-next-line:max-line-length
    { name: 'Manager Prenom', type: 'input-text', inputType: 'text', placeholder: '' ,  filed_name: 'Manager Prenom', ng_model_name: 'managerprenom', is_required: 1, translation: 'Manager\'s First Name'},
    // tslint:disable-next-line:max-line-length
    { name: 'Manager e-mail', type: 'input-text', inputType: 'email', placeholder: '' ,  filed_name: 'Manager e-mail', ng_model_name: 'manageremail', is_required: 1, translation: 'Manager\'s E-mail'},
    // tslint:disable-next-line:max-line-length
    { name: 'Statut - Profession', type: 'input-text', inputType: 'text', placeholder: '',  filed_name: 'Statut - Profession', ng_model_name: 'statusprofession', is_required: 1 , translation: 'Status-Occupation'},
    // tslint:disable-next-line:max-line-length
    { name: 'Fonction', type: 'input-text', inputType: 'text', placeholder: '',  filed_name: 'Fonction', ng_model_name: 'fonction', is_required: 1, translation: 'Fonction' },
    // tslint:disable-next-line:max-line-length
    { name: 'Date de naissance', type: 'input-text', inputType: 'date', placeholder: '',  filed_name: 'Date de naissance', ng_model_name: 'date_de_naissance', is_required: 1 , translation: 'Date of birth'},
    // tslint:disable-next-line:max-line-length
    { name: 'Adresse 1', type: 'input-text', inputType: 'text', placeholder: '',  filed_name: 'Adresse 1', ng_model_name: 'adresse1', is_required: 1 , translation: 'Adress 1'},
    // tslint:disable-next-line:max-line-length
    { name: 'Adresse 2', type: 'input-text', inputType: 'text', placeholder: '',  filed_name: 'Adresse 2', ng_model_name: 'adresse2', is_required: 1 , translation: 'Adress 2'},
    // tslint:disable-next-line:max-line-length
    { name: 'Code Postal', type: 'input-text', inputType: 'text', placeholder: '',  filed_name: 'Code Postal', ng_model_name: 'codepostal', is_required: 1 , translation: 'Postal Code'},
    { name: 'Pays', type: 'input-text', inputType: 'text', placeholder: '',  filed_name: 'Pays', ng_model_name: 'pays', is_required: 1 , translation: 'Country'},
    // tslint:disable-next-line:max-line-length
    { name: 'Unité de service', type: 'input-text', inputType: 'text', placeholder: '',  filed_name: 'Unité de service', ng_model_name: 'unite_service', is_required: 1 , translation: 'Service Unit'},
    // tslint:disable-next-line:max-line-length
    { name: 'Mode d\'exercice dominant', type: 'input-text', inputType: 'text', placeholder: 'Salarié ou Libérial ou Salarié mixte',  filed_name: 'Mode d\'exercice dominant', ng_model_name: 'mode_exercice_dominant', is_required: 1 , translation: 'Dominant mode of practice'},
    // tslint:disable-next-line:max-line-length
    { name: 'N° d\'installation', type: 'input-text', inputType: 'text', placeholder: '',  filed_name: 'N° d\'installation', ng_model_name: 'numero_installation', is_required: 1 , translation: 'Facility number'},
    // tslint:disable-next-line:max-line-length
    { name: 'Nom de naissance (si différent du nom)', type: 'input-text', inputType: 'text', placeholder: '',  filed_name: 'Nom de naissance (si différent du nom)', ng_model_name: 'nom_naissance_diferent', is_required: 1 , translation: 'Birth last name (if different from the Last Name)'},
    // tslint:disable-next-line:max-line-length
    { name: 'Profession', type: 'input-text', inputType: 'text', placeholder: '',  filed_name: 'Profession', ng_model_name: 'profession', is_required: 1 , translation: 'Occupation'},
    // tslint:disable-next-line:max-line-length

    ];
  // tslint:disable-next-line:variable-name
  required_fields = [
    // tslint:disable-next-line:max-line-length
    { name: 'Nom ', type: 'input-text', inputType: 'text', placeholder: '',  filed_name: 'Nom', ng_model_name: 'lastname', is_required: 1 },
    // tslint:disable-next-line:max-line-length
    { name: 'Prénom ', type: 'input-text', inputType: 'text', placeholder: '',  filed_name: 'Prénom', ng_model_name: 'firstname', is_required: 1 },
    // tslint:disable-next-line:max-line-length
    { name: 'Login', type: 'input-text', inputType: 'text', placeholder: '',  filed_name: 'Identifiant', ng_model_name: 'service', is_required: 1},
    // tslint:disable-next-line:max-line-length
    { name: 'Mot de passe', type: 'input-text', inputType: 'password', placeholder: '',  filed_name: 'Mot de passe', ng_model_name: 'password', is_required: 1},
    // tslint:disable-next-line:max-line-length
    { name: 'E-mail ', type: 'input-text', inputType: 'email', placeholder: '',  filed_name: 'E-mail', ng_model_name: 'email', is_required: 1 },
  ];

  droppedVegetables = [];
  droppedItems = [];
  dragEnabled = true;
  htmlText: any;
  test = '';
  currentDraggedItem: any;
  public fakeInut = '';
  // tslint:disable-next-line:variable-name
  is_requered: any;
  myData: any;
  // tslint:disable-next-line:variable-name
  cpt_field: any;

  constructor(private formCreatorService: FormCreatorService, private activatedRoute: ActivatedRoute, private dataService: DataService) {
    // this.modalPopupObject = {};
    this.dataService.getData().subscribe(data => {
      // do stuff with the data, for example:
      this.myData = data.htmlCode;
    });
  }

  onAnyDrop(e: any) {
    this.currentDraggedItem = e;
    this.updateDroppedItem(this.currentDraggedItem);
  }

  updateHtmlCode(): void {
    this.htmlText = this.droppedItems;
  }

   removeItem(item: any, list: Array<any>) {
     // tslint:disable-next-line:only-arrow-functions
     const index = list.map(function(e) {
       return e.name;
     }).indexOf(item.name);
     list.splice(index, 1);
  }

  removeItemUpdate(item: any, list: Array<any>) {
     // tslint:disable-next-line:only-arrow-functions
     const index = list.map(function(e) {
       return e.name;
     }).indexOf(item.name);
     list.splice(index, 1);
     if (typeof this.myData === 'undefined') {
       this.dataService.setData(this.old_forms);
     }

  }

  renderHtmlCode(htmlObject: any): string {
    return '<div class="form-group row"><input class="form-control" type="' + htmlObject.inputType
      + '" [(ngModel)]="' + htmlObject.ng_model_name
      + '"/></div>';
  }

  onSubmit(f: any): void {
    this.display = false;
    this.updateDroppedItem(f.value);
  }

  updateDroppedItem(e: any): boolean {
    // Create Form
    if (typeof this.id_form === 'undefined') {
      if (this.fakeInut !== this.currentDraggedItem.dragData.ng_model_name || this.droppedItems.length === 0 ) {
        this.currentDraggedItem.dragData.placeholder = e.placeholder;
        // console.log(this.is_requered);
        // this.currentDraggedItem.dragData.is_required = this.is_requered === 'undefined' ? 0 : this.is_requered;
        this.droppedItems.push(this.currentDraggedItem.dragData);
        this.updateHtmlCode();
        this.test += this.renderHtmlCode(this.currentDraggedItem.dragData);
        // @ts-ignore
        // tslint:disable-next-line:prefer-const
        const myJsonString = JSON.stringify(this.droppedItems);
        this.dataService.setData(myJsonString);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Ce champ est déjà sur le formulaire en création !'
        });
        return false;
      }
      this.fakeInut = this.currentDraggedItem.dragData.ng_model_name;
    } else {
      // tslint:disable-next-line:variable-name
      for (const form_element of this.final_element_forms) {
        if (form_element.ng_model_name === this.currentDraggedItem.dragData.ng_model_name) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ce champ est déjà sur le formulaire en création !'
          });
          return;
        }
      }
      this.final_element_forms = this.droppedItems;
      if (this.fakeInut !== this.currentDraggedItem.dragData.ng_model_name || this.final_element_forms.length === 0 ) {
        this.currentDraggedItem.dragData.placeholder = e.placeholder;
        this.currentDraggedItem.dragData.is_required = 1;
        this.final_element_forms.push(this.currentDraggedItem.dragData);
        Array.prototype.push.apply(this.final_element_forms, this.old_forms);
        this.old_forms = [];
        this.updateHtmlCode();
        this.test += this.renderHtmlCode(this.currentDraggedItem.dragData);
        // @ts-ignore
        const myJsonString = JSON.stringify(this.final_element_forms);
        this.dataService.setData(myJsonString);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Ce champ est déjà sur le formulaire en création !'
        });
        return false;
      }
      this.fakeInut = this.currentDraggedItem.dragData.ng_model_name;
    }

  }

  ngOnInit(): void {
    this.sub = this.activatedRoute.params.subscribe(params => {
      this.id_form = params.id;
      this.get_current_form(this.id_form);
    });

  }

  // tslint:disable-next-line:variable-name
  get_current_form(id_form) {
    this.formCreatorService.getForm(id_form)
      .subscribe(res => {
        // console.log(res.data);
        // tslint:disable-next-line:align
        if (res.data !== null) {
          // @ts-ignore
          this.old_forms = JSON.parse(res.data.content);
          this.cpt_field = this.old_forms.length;
          // tslint:disable-next-line:variable-name
          this.old_forms.forEach((form_element, form_index) => {
            const data = { name: form_element.name,
              type: form_element.type,
              inputType: form_element.inputType,
              placeholder: form_element.placeholder,
              filed_name: form_element.filed_name,
              ng_model_name: form_element.ng_model_name,
              is_required: form_element.is_required
            };
            // @ts-ignore
            this.final_element_forms.push(data);
            // console.log(this.final_element_forms.length);
          });
        }
      }, (err) => {
        console.log(err);
      });
  }

  checkIsRequired(event) {
    this.is_requered = event.target.value;
  }

  drop(event: CdkDragDrop<string[]>) {
    if (typeof this.id_form === 'undefined') {
      moveItemInArray(this.droppedItems, event.previousIndex, event.currentIndex);
      const myJsonString = JSON.stringify(this.droppedItems);
      this.dataService.setData(myJsonString);
    } else {
      if (this.cpt_field === this.old_forms.length) {
        moveItemInArray(this.old_forms, event.previousIndex, event.currentIndex);
        const myJsonString = JSON.stringify(this.old_forms);
        this.dataService.setData(myJsonString);
      } else {
        moveItemInArray(this.final_element_forms, event.previousIndex, event.currentIndex);
        const myJsonString = JSON.stringify(this.final_element_forms);
        this.dataService.setData(myJsonString);
      }
    }
  }

  // tslint:disable-next-line:variable-name
  onRequiredChange($event: MatSlideToggleChange, value, ng_model_name) {
    if (typeof this.id_form === 'undefined') {
      this.droppedItems[value].is_required = $event.checked ? 1 : 0;
      const myJsonString = JSON.stringify(this.droppedItems);
      this.dataService.setData(myJsonString);
    } else {
      if (this.cpt_field === this.old_forms.length) {
        this.old_forms[value].is_required = $event.checked ? 1 : 0;
        const myJsonString = JSON.stringify(this.old_forms);
        this.dataService.setData(myJsonString);
      } else {
        this.final_element_forms[value].is_required = $event.checked ? 1 : 0;
        const myJsonString = JSON.stringify(this.final_element_forms);
        this.dataService.setData(myJsonString);
      }
    }
  }

}

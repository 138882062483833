import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private subject = new Subject<any>();
  constructor() { }
  setData(message: string) {
    // just an example:
    this.subject.next({ htmlCode: message });
  }
  setOldData(message: string) {
    // just an example:
    this.subject.next({ htmlCode: message });
  }
  setDataGroup(message: string) {
    // just an example:
    this.subject.next({ htmlCodeGroup: message });
  }

  getData(): Observable<any> {
    return this.subject.asObservable();
  }
  getOldData(): Observable<any> {
    return this.subject.asObservable();
  }
  getDataGroup(): Observable<any> {
    return this.subject.asObservable();
  }
}

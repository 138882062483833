import { Component, OnInit } from '@angular/core';

import {ConfigService} from '../../../services/config.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private configService: ConfigService) {}

  ngOnInit() {

  }

  public entityType = this.configService.entityType;


}

<ngx-spinner></ngx-spinner>
<section id="front_wrap">
  <section id="connect_page">
    <div id="connect_title">
      <ng-container *ngIf="text_login">
        <p>Mot de passe oublié</p>
      </ng-container>
      <ng-container *ngIf="text_new_password">
        <p>Nouveau mot de passe</p>
      </ng-container>

    </div>
    <div id="connect_content">

      <form>
        <ng-container *ngIf="text_login">
          <input type="email"  placeholder="Votre identifiant" [(ngModel)]="email_send" [ngModelOptions]="{standalone: true}">
          <div class="alert alert-info text-center" role="alert" *ngIf="addingSuccess">
            {{text}}.
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <button class="blue_btn"  type="button" color="accent" (click)="send_new_password()">Envoyer</button>
          <p style="margin-top: 25px"><a href="/"><< Se connecter</a></p>
        </ng-container>
        <ng-container *ngIf="text_new_password">
          <div class="alert alert-info text-center" role="alert" *ngIf="addingSuccess">
            {{text}}.
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <input type="password"  placeholder="Nouveau mot de passe" [(ngModel)]="new_password" [ngModelOptions]="{standalone: true}">

          <button class="blue_btn"  type="button" color="accent" (click)="change_password()">Changer</button>
          <p style="margin-top: 25px"><a href="/"><< Se connecter</a></p>
        </ng-container>


      </form>

    </div>
    <div id="connect_legal">
      <p>Institut PASTEUR - par <strong>Kaptitude</strong> - 2021</p>

    </div>
  </section>
</section>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"  >
  <p style="font-size: 20px; color: white">Veuillez patienter svp...</p>
</ngx-spinner>
<section id="front_wrap">
  <section id="front_page" class="search_step">
    <section id="menu_front">
      <span class="badge badge-danger" *ngIf="takeInHand && takeInHand === 'yes'">
        <h6 style="color: #ffffff;font-size: 12px;">
        <span class="bx bx-info-circle"></span> Prise en main en cours <br>de ce compte
       </h6>
         <button type="button" class="btn btn-primary waves-effect waves-light" (click)="returner(admin_login)">
            Retour au compte précédent
          </button>
      </span>
      <a class="menu_item menu_search current" href="dashboard/inspector"><img src="assets/img/icon_search.png"> <p>Recherche</p></a>
      <a class="menu_item menu_profile" href="dashboard/inspector/profile"><img src="assets/img/icon_profile.png"> <p>Profil</p></a>
      <a class="menu_item menu_export" href="dashboard/inspector/inspector-export"><img src="assets/img/icon_profile.png"> <p>Export</p></a>
      <a (click)="logout_user()" class="menu_item menu_logout" ><img src="assets/img/icon_logout.png"> <p>Déconnexion</p></a>
    </section>
    <section id="big_front">
      <div id="name_connected">
        <p>{{single_user.name}}</p>
        <img src="{{single_user.photo}}">
      </div>
      <div id="content_front">
        <ng-container *ngIf="profile_id != 3  || profile_id != 7">
          <p class="h1" *ngIf="verifications && verifications.length > 0">Entrez les identifiants de l'intervenant</p>
          <ng-container *ngIf="verifications && verifications.length === 0">
            <div class="alert alert-danger text-black-50" role="alert" style="font-weight: bold">
              INFORMATION: Il n'y a pas de vérifications disponible pour vérifier l'accès des intervenants du site
            </div>
          </ng-container>
          <ng-container *ngIf="verifications && verifications.length > 0">
            <form>
              <select class="form-control verification" [(ngModel)]="verification_id" [ngModelOptions]="{standalone: true}" (change)="verificationChoice($event.target.value)">
                <option selected *ngFor="let verification of verifications" [value]="verification.id">{{verification.name}}</option>
              </select>
              <select class="form-control verification" (change)="societyChoice($event)">
                <option value="null" disabled>Veuillez sélectionner une société</option>
                <option value="all">Toutes les sociétés</option>
                <option *ngFor="let resultSociety of unique_society_all | orderBy: 'nom'">
                  <ng-container *ngIf="resultSociety.nom != null">{{resultSociety.nom}}</ng-container>
                </option>
              </select>
              <input class="verification mb-3" type="search" placeholder="Rechercher" (keyup)="name_value($event)">
              <BR>
              <img src="assets/img/loaderkapi.gif" height="18" *ngIf="showGif">
              <div  style=" height: 200px;overflow: auto;" class="text-center">
                <ul *ngFor = "let userFind of userFind_all.slice(0, 1000) | orderBy: 'name'" style="list-style: none;text-align: center;">
                  <ng-container *ngIf="userFind_all.length >= 1">
                    <ng-container *ngIf="verifications && verifications.length == 1">
                      <li *ngFor = "let verification of verifications" class="result_list">
                        <a href="/dashboard/inspector/intervenant/status/{{userFind.id}}/{{verification.id}}/{{verificateur_id}}">  {{userFind.name}} {{userFind.firstname}} ({{userFind.login}})</a></li>
                    </ng-container>
                    <ng-container *ngIf="verifications && verifications.length > 1">
                      <li class="result_list"><a href="/dashboard/inspector/intervenant/status/{{userFind.id}}/{{verification_id}}/{{verificateur_id}}">  {{userFind.name}} {{userFind.firstname}} ({{userFind.login}})</a></li>
                    </ng-container>
                  </ng-container>
                </ul>
                <ng-container *ngIf="results && results.length === 0">
                  <div class="alert alert-info" role="alert">
                    Pensez à entrer  : Nom
                  </div>
                </ng-container>
              </div>
            </form>
          </ng-container>
        </ng-container>
      </div>
      <div id="bottom_logo">
        <img src="assets/img/logo-kaptitude.png">
      </div>
    </section>
  </section>
  <div id="front_legal">
    <p>CERTIKAP - par <strong>Kaptitude</strong> - 2020</p>
  </div>
</section>

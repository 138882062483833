import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {CertificationService} from '../../../../services/certification.service';
import {UserService} from '../../../../services/user.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ActivatedRoute, Router} from '@angular/router';
import {VerificationService} from "../../../../services/verification.service";

@Component({
  selector: 'app-verification-certification',
  templateUrl: './verification-certification.component.html',
  styleUrls: ['./verification-certification.component.css']
})
export class VerificationCertificationComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  public verif_id = this.activatedRoute.snapshot.url[4].path;
  // tslint:disable-next-line:max-line-length
  certId: any;

  // tslint:disable-next-line:max-line-length variable-name
  count_sub_certifification: any;
  // tslint:disable-next-line:max-line-length
  constructor(private verificationService: VerificationService, private activatedRoute: ActivatedRoute, private certificationService: CertificationService, private userService: UserService, private spinner: NgxSpinnerService, private el: ElementRef, private renderer: Renderer2, private router: Router, private route: ActivatedRoute) { }
  // tslint:disable-next-line:variable-name radix
  public profile_id = parseInt(localStorage.getItem('profile_id'));
  public certificats: any;
  // tslint:disable-next-line:variable-name
  public count_verif: any;
  show = 10;
  vQuery: string;
  addingSuccess: boolean;
// tslint:disable-next-line:variable-name
  list_verification: any;

  ngOnInit() {
    console.log(this.verif_id);
    this.get_list_verification(this.verif_id);
  }
  // tslint:disable-next-line:variable-name
  get_list_verification(id_cert) {
    this.spinner.show();
    this.verificationService.getListVerificationbyCErtificat(id_cert)
      .subscribe(res => {
        // @ts-ignore
        this.count_verif = res.data.length;
        // @ts-ignore
        this.list_verification = res.data;
        console.log(res.data);
        this.spinner.hide();
      }, (err) => {
        console.log(err);
        this.spinner.hide();
      });
  }
}

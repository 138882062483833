import { Component, OnInit } from '@angular/core';
import {UserService} from '../../../services/user.service';
import {ConfigService} from '../../../services/config.service';
import {Meta, Title} from '@angular/platform-browser';
import {NgxSpinner} from 'ngx-spinner/lib/ngx-spinner.enum';
import Swal from 'sweetalert2';
import {ActivatedRoute, Router} from '@angular/router';


@Component({
  selector: 'app-forgetIP',
  templateUrl: './forgetIP.component.html',
  styleUrls: ['./forgetIP.component.css']
})
export class ForgetIPComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  id_new_password: any;
  // tslint:disable-next-line:max-line-length variable-name
  client_email: any;
  // tslint:disable-next-line:max-line-length variable-name
  login: any;
  // tslint:disable-next-line:variable-name
  public email_send: any;
  addingSuccess: boolean;
  public text: any;
  // tslint:disable-next-line:variable-name
  public email_reserter: any;
  // tslint:disable-next-line:max-line-length variable-name
  text_login: boolean;
  // tslint:disable-next-line:variable-name
  text_new_password: boolean;
  // tslint:disable-next-line:variable-name
  password_change = true;

  // tslint:disable-next-line:variable-name
  new_password: any;

  // tslint:disable-next-line:max-line-length
  constructor(public meta: Meta, public pageTitle: Title, private activatedRoute: ActivatedRoute, private router: Router, private userService: UserService) {
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (typeof params['email'] === 'undefined') {
        this.text_login = true;
      }
      if (typeof params['email'] !== 'undefined') {
        this.text_new_password = true;
      }
      this.email_reserter = params['login'];
      console.log(params['login']);
    });
  }

  getCurrentDate() {
    // tslint:disable-next-line:prefer-const
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();

    return dd + '-' + mm + '-' + yyyy;
  }

  get_user(id) {
    this.userService.getUserDetailsForPassword(id)
      .subscribe(res => {
        this.id_new_password = res.data.id;
        this.client_email = res.data.email;
        this.login = res.data.login;
        console.log(res.data);
        if (res.data.length === 0) {
          this.router.navigate(['/']);
        }
        // tslint:disable-next-line:max-line-length
        this.router.navigate(['/password/forget'], {
          queryParams: {
            email: this.client_email,
            login: this.login,
            date: this.getCurrentDate(),
            application: 'certi_kap_v1'
          }
        });
      }, (err) => {
        this.router.navigate(['/']);
      });
  }

  send_new_password() {
    // @ts-ignore
    console.log(this.email_send);
    if (this.email_send === null || typeof this.email_send === 'undefined') {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Veuillez entrer votre identifiant !'
      });
    } else {
      console.log('ok');
      // @ts-ignore
      const body = {
        login: this.email_send
      };
      this.userService.forget_password_user(body)
        .subscribe(res => {
          this.text = res.message;
          this.email_send = '';
          this.addingSuccess = true;
          console.log(res);
        }, (err) => {
          console.log(err);
        });
    }

  }

  change_password() {
    // console.log(this.new_password);
    // @ts-ignore
    if (this.new_password === null || typeof this.new_password === 'undefined') {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Veuillez entrer votre nouveau de passe !'
      });
    } else {
      // @ts-ignore
      const body = {
        login: this.email_reserter,
        new_password: this.new_password
      };
      this.userService.change_password_user(body)
        .subscribe(res => {
          this.addingSuccess = true;
          this.text = res.message;
          this.new_password = '';
          this.password_change = false;
          console.log(res);
        }, (err) => {
          console.log(err);
        });
    }
  }
}

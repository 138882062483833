import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fildarianeIP',
  templateUrl: './fildarianeIP.component.html',
  styleUrls: ['./fildarianeIP.component.css']
})
export class FildarianeIPComponent implements OnInit {
  // tslint:disable-next-line:variable-name radix
  public profile_id = parseInt(localStorage.getItem('profile_id'));
  constructor() { }

  ngOnInit() {
  }

}

<footer class="foot footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6">
        2020 © CERTIKAP.
      </div>
      <div class="col-sm-6">
        <div class="text-sm-right d-none d-sm-block">
          Création agence web Enjin
        </div>
      </div>
    </div>
  </div>
</footer>



import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginService} from '../../../services/login.service';
import {UserService} from '../../../services/user.service';
import {ConfigService} from '../../../services/config.service';
import Swal from 'sweetalert2';
import {NgxSpinnerService} from 'ngx-spinner';
@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.css']
})
export class AdminProfileComponent implements OnInit {
  // tslint:disable-next-line:variable-name radix
  public profile_id = parseInt(localStorage.getItem('profile_id'));
  // tslint:disable-next-line:variable-name
  public user_conntected = JSON.parse(localStorage.getItem('currentUser'));
  // tslint:disable-next-line:variable-name
  public photo_url;
  // tslint:disable-next-line:variable-name
  public visibleBtn: any;
  toggle1 = false;
  toggle2 = false;
  // tslint:disable-next-line:variable-name
  public single_user = {
    name: null,
    photo: null,
    email: null,
    password: null,
    password_confirm: null,
    firstname: null,
    login: null,
    id: null,
  };
  previewUrl: any = null;
  success_Message: boolean;
  // tslint:disable-next-line:variable-name
  icon_eye: boolean;
  // tslint:disable-next-line:variable-name
  icon_eye_slash: boolean;
  // tslint:disable-next-line:variable-name
  not_success_Message: boolean;
  text: any;

  constructor(public translate: TranslateService,
              public pageTitle: Title,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private loginService: LoginService,
              private userService: UserService,
              private spinner: NgxSpinnerService,
              private config: ConfigService) {
    this.photo_url = this.config.urlDEV_images;
    if (this.user_conntected !== null) {
      this.get_single_user_connected_details(this.user_conntected.user_id ? this.user_conntected.user_id : this.user_conntected.id);
    }
  }

  ngOnInit() {
    this.config.checkUSerAccessByProfile(this.profile_id);
    if (!localStorage.getItem('currentUser')) {
      this.router.navigate(['/']);
    }
  }

  // tslint:disable-next-line:variable-name
  get_single_user_connected_details(id_user: number) {
    this.userService.getUser(id_user)
      .subscribe(res => {
        this.single_user.firstname = res.data.firstname;
        this.single_user.name = res.data.name;
        this.single_user.email = res.data.email;
        this.single_user.login = res.data.login;
        // this.single_user.password = res.data.password;
        this.single_user.id = res.data.id;
        this.single_user.photo = this.photo_url + '' + res.data.photo;
      }, (err) => {
        console.log(err);
      });
  }

  showNotMessageSuccess(text){

    this.text = text;
    this.not_success_Message = true;

    setTimeout (() => {
      this.not_success_Message = false;
    }, 5000);

  }

  showMessageSuccess(text){

    this.text = text;
    this.success_Message = true;

    setTimeout (() => {
      this.success_Message = false;
    }, 5000);

  }

  update_profile() {
    const body = {
      name: this.single_user.name,
      email: this.single_user.email,
      password: this.single_user.password,
      password_confirm: this.single_user.password_confirm,
      firstname: this.single_user.firstname,
      login: this.single_user.login,
      id: this.single_user.id,
    };
    this.userService.updateUSer(body)
      .subscribe(res => {
        if (res.success === true) {
          this.text = res.message;
          this.showMessageSuccess(this.text);
        }
        if (res.success === false) {
          this.text = res.message;
          this.showNotMessageSuccess(this.text);
        }
      }, (err) => {
        console.log(err);
      });
  }

  // tslint:disable-next-line:variable-name
  changeType(input_field_password, num) {
    if (input_field_password.type === 'password') {
      input_field_password.type = 'text';
    } else {
      input_field_password.type = 'password';
    }

    if (num === 1) {
      this.toggle1 = !this.toggle1;
    } else {
      this.toggle2 = !this.toggle2;
    }
  }
}

import { HttpHeaders } from "@angular/common/http";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./config.service";
const token = JSON.parse(localStorage.getItem('tokenCurrentConnected'));
const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
    }),
};
export class ChapitreService {
    constructor(http, config) {
        this.http = http;
        this.config = config;
    }
    static handleError(error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(`Backend returned code ${error}, ` +
                `body was: ${error.error}`);
        }
        console.log(error);
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }
    static extractData(res) {
        return res || {};
    }
    getAllChapitre() {
        return this.http.get(this.config.urlDEV + '/chapitre', httpOptions).pipe(map(ChapitreService.extractData), catchError(ChapitreService.handleError));
    }
    createChapitre(data) {
        return this.http.post(this.config.urlDEV + '/chapitre/create', data, httpOptions).pipe(map(ChapitreService.extractData), catchError(ChapitreService.handleError));
    }
    editChapitre(id, data) {
        return this.http.put(this.config.urlDEV + '/chapitre/update/' + id, data, httpOptions).pipe(map(ChapitreService.extractData), catchError(ChapitreService.handleError));
    }
    getChapitre(id) {
        return this.http.get(this.config.urlDEV + '/chapitre/' + id, httpOptions).pipe(map(ChapitreService.extractData), catchError(ChapitreService.handleError));
    }
    deleteChapitre(id) {
        return this.http.delete(this.config.urlDEV + '/chapitre/' + id, httpOptions).pipe(map(ChapitreService.extractData), catchError(ChapitreService.handleError));
    }
}
ChapitreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ChapitreService_Factory() { return new ChapitreService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ConfigService)); }, token: ChapitreService, providedIn: "root" });

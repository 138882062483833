import { Component, OnInit } from '@angular/core';
import {UserService} from '../../../../services/user.service';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginService} from '../../../../services/login.service';
import {VerificationService} from '../../../../services/verification.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ConfigService} from '../../../../services/config.service';
import {element} from "protractor";
import { CertificationService } from 'src/app/services/certification.service';
import {User} from "../../../../models/user.model";

@Component({
  selector: 'app-certificats',
  templateUrl: './certificats.component.html',
  styleUrls: ['./certificats.component.css'],
})
export class CertificatsComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  user_details: User = new User(); // Initialisation par défaut avec un objet User vide

  public admin_login = localStorage.getItem('admin_login');
  // tslint:disable-next-line:variable-name radix
  public site_id = parseInt(localStorage.getItem('site_id'));
  public user_conntected = JSON.parse(localStorage.getItem('currentUser'));
  // tslint:disable-next-line:variable-name
  public profile_id = this.user_conntected.profile_id;
  public takeInHand = localStorage.getItem('takeInHand');
  // tslint:disable-next-line:variable-name
  public connectLikeVerifcateur = localStorage.getItem('connect_like_verificateur');
  public user_url_id: any;
  public user_id: any;
  public users: any;
  private sub: any;
  public certificats: any;

  constructor(
    public translate: TranslateService,
    public pageTitle: Title,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private userService: UserService,
    private configService: ConfigService,
    private verificationService: VerificationService,
    private spinner: NgxSpinnerService,
    private config: ConfigService,
    private certificationService: CertificationService) {}
  currentDate: string;

  ngOnInit() {

    const today = new Date();
    this.currentDate = today.toDateString(); // Formate la date en une chaîne lisible
    this.configService.checkUSerAccessByProfile(this.profile_id);
    if(this.user_conntected.id)
    {
      this.user_id = this.user_conntected.id;
    }
    else{
      this.user_id = this.user_conntected.user_id;
    }

    this.get_certification_by_user_login(this.user_conntected.login)

    this.sub = this.activatedRoute.params.subscribe(params => {
      this.user_url_id = params.id;
    });

    // si profile apprenant (3) verification si l'url corresponds a celle de l'user qui est connecté
    if (this.user_url_id == this.user_id ||this.user_url_id){
      this.user_certifications(this.user_url_id);
    }
      else if(this.profile_id===11 || this.takeInHand =='yes'){
        this.user_certifications(this.user_url_id);
    }
    else if(this.profile_id !==11 || this.profile_id !==3) {
      this.userService.getListIntervenant(this.profile_id, this.site_id, this.user_id)
        .subscribe(res => {
          // @ts-ignore
          this.users = res.data;
          const user_tab = [];
          for (let us of this.users) {
            user_tab.push(us.user.id)
          }
          for (let id of user_tab){
            if (id == this.user_url_id){
              this.user_certifications(this.user_url_id);
            }
          }
        }, (err) => {
          console.log(err);
        });
    }
    else{
      this.router.navigate(['dashboard/administrator']);
    }
  }


  // tslint:disable-next-line:variable-name
  user_certifications(id_user) {
    this.spinner.show();
    this.userService.get_all_intervenant_certifications_status(id_user)
      .subscribe(res => {
        this.user_details = res.user;
        this.certificats = res.certificats;
        this.spinner.hide();
      }, (err) => {
        console.log(err);
        this.spinner.hide();
        this.router.navigate(['dashboard/administrator']);
        return false;
      });
  }

  // tslint:disable-next-line:variable-name
  get_certification_by_user_login(login) {
    const body = {
      login : login,
    };
    this.certificationService.get_certification_by_user_login(body)
      .subscribe(res => {
        // @ts-ignore
        this.certificats_site = res.data.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
      }, (err) => {
        console.log(err);

      });
  }

}

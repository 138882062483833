import { Component, OnInit } from '@angular/core';
import {GroupeService} from "../../../../services/groupe.service";
import {Groupe} from "../../../../models/groupe.model";
import {FormArray, FormBuilder, FormControl} from "@angular/forms";
import {ChoiceScreen} from "../../../../models/choice-screen.model";
import Swal from "sweetalert2";
import {ChoiceScreenService} from "../../../../services/choice-screen.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-create-choice-screen',
  templateUrl: './create-choice-screen.component.html',
  styleUrls: ['./create-choice-screen.component.css']
})
export class CreateChoiceScreenComponent implements OnInit {

  public user_conntected = JSON.parse(localStorage.getItem('currentUser'));
  public profile_id = parseInt(localStorage.getItem('profile_id'));

  //Initialisation
  GroupesEntre: Groupe[] = [];
  GroupesChoice: Groupe[] = [];
  ChoiceScreen = new ChoiceScreen();
  allGroupes : any;

  //Forms
  FormGroupes: any;
  FormGroupesArray: any;

  //Group formatting
  page = 1;
  order = 'groupe.name';
  reverse = false;
  vQuery: string;

  //create ChoiceScreen
  responseMessage : string;

  constructor(private groupeService: GroupeService,
              private formBuilder: FormBuilder,
              private choiceScreenService: ChoiceScreenService,
              private router: Router) { }

  ngOnInit() {

    //FORMS
    this.FormGroupes = this.formBuilder.group({
      group_id: this.formBuilder.array([])
    });
    this.FormGroupesArray = <FormArray>this.FormGroupes.controls.group_id;


    //GROUPES
    this.AllGroups();
  }

  //GROUPES
  //Recover all groupes
  AllGroups() {
    this.groupeService.getGroupsByUserLogin(this.profile_id,this.user_conntected.login)
      .subscribe(res => {
        this.GroupesEntre = res.groupes;
        this.GroupesChoice = res.groupes;
        this.allGroupes = [ ...this.GroupesChoice ]
      }, (err) => {
        console.log(err);
      });
  }
  //get term of the search
  groupe_name_value(event : any){
    let group_name;
    group_name = event.target.value;
    this.search_groupes(group_name);
  }

  //Functionality who permit to search group
  search_groupes(groupe_name){
    //when only research filter byt groupes name
    if (groupe_name && groupe_name.length > 0) {
      this.GroupesChoice = this.allGroupes.filter((Groupe) => Groupe.name.toLocaleLowerCase().includes(groupe_name.toLocaleLowerCase()));
    }
    else{
      this.GroupesChoice = [ ...this.allGroupes ];
    }
  }

  //FORM
  check_value(groupe_id){
    let value_check = this.FormGroupesArray.value.includes(groupe_id);
    if(value_check){
      return true;
    }
    else{
      return false;
    }
  }

  //FORM
  checkGroupe(groupe_id, event : any){
    if(event) {
      this.FormGroupesArray.push(new FormControl(groupe_id));
    } else {
      let index = this.FormGroupesArray.controls.findIndex(x => x.value == groupe_id)
      this.FormGroupesArray.removeAt(index);
    }
  }

  //Group formatting
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }

  //Create ScreenChoice
  createChoiceScreen() {
    // tslint:disable-next-line:max-line-length
    if (this.ChoiceScreen.titre === undefined || this.ChoiceScreen.titre.trim() === '') {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Le titre est manquant'
      });
      return false;
      // tslint:disable-next-line:align
    }
    else if (this.ChoiceScreen.description === undefined || this.ChoiceScreen.description.trim() === '') {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Veuillez mettre une description',
      });
      return false;
      // tslint:disable-next-line:align
    }
    else if (this.ChoiceScreen.groupe_id === undefined) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Veuillez sélectionner un groupe d\'entré',
      });
      return false;
      // tslint:disable-next-line:align
    }
    else if (this.FormGroupesArray.length <= 1) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Veuillez sélectionner au moins deux groupes de choix',
      });
      return false;
      // tslint:disable-next-line:align
    }
    else{
      const body = {
        titre: this.ChoiceScreen.titre,
        description: this.ChoiceScreen.description,
        groupe_id: this.ChoiceScreen.groupe_id,
        array_choice_screen_groupe_id : this.FormGroupesArray.value,
      };
      this.choiceScreenService.createChoiceScreen(body)
        .subscribe(res => {
          if(res.status == false)
            // @ts-ignore: Unreachable code error
            this.responseMessage = res.messages;
          this.ChoiceScreen.titre = '';
          this.ChoiceScreen.description = '';
          this.ChoiceScreen.groupe_id = null;
          Swal.fire({
            title: 'Ecran de choix créé !',
            text: res.message,
            icon: 'success',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Revenir sur la liste des écran de choix',
            cancelButtonText: 'Rester sur la page'
          }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigate(['/dashboard/administrator/choice_screen']);
            }
          });
        }, (err) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: this.responseMessage
          });
        });
    }
  }
}

import { Component, OnInit } from '@angular/core';
import {UserService} from '../../../services/user.service';
import {NgxSpinnerService} from 'ngx-spinner';
import Swal from 'sweetalert2';
import {ConfigService} from '../../../services/config.service';
import { OrderPipe } from 'ngx-order-pipe';
import {ExportToCsv} from 'export-to-csv';
import {Router} from '@angular/router';
import {LoginService} from '../../../services/login.service';
import {SiteService} from '../../../services/site.service';
import {CertificationService} from '../../../services/certification.service';
import {User} from "../../../models/user.model";

@Component({
  selector: 'app-intervenant',
  templateUrl: './intervenant.component.html',
  styleUrls: ['./intervenant.component.css']
})
export class IntervenantComponent implements OnInit {
  // tslint:disable-next-line:max-line-length
  constructor(private siteService: SiteService, private orderPipe: OrderPipe, private router: Router, private loginService: LoginService, private userService: UserService, private spinner: NgxSpinnerService, private configService: ConfigService, private certificationService: CertificationService) {
    this.sortedCollection = orderPipe.transform(this.users, 'user.name');
    this.urlDownload = this.configService.urlDownloadCertification;
  }
  // tslint:disable-next-line:max-line-length variable-name
  public date_export: any;
  // tslint:disable-next-line:max-line-length
  public urlDownload: string;
  page = 1;
  order = 'user.name';
  reverse = false;
  sortedCollection: any[];
  // tslint:disable-next-line:variable-name radix
  public profile_id = parseInt(localStorage.getItem('profile_id'));
  // tslint:disable-next-line:variable-name radix
  public site_id = parseInt(localStorage.getItem('site_id'));

  // tslint:disable-next-line:variable-name radix
  public site_connected_id = parseInt(localStorage.getItem('site_id'));
  // tslint:disable-next-line:variable-name
  public user_conntected = JSON.parse(localStorage.getItem('currentUser'));
  // tslint:disable-next-line:max-line-length variable-name
  public admin_login = this.user_conntected.login;
  // tslint:disable-next-line:variable-name
  public connected_id = this.user_conntected.id;
  public connected_user_id = this.user_conntected.user_id;
  public users: any;
  public Allusers: any;
  public sites: any;
  // tslint:disable-next-line:variable-name
  public count_intervenant: any;
  show = 10;
  vQuery: string;
  addingSuccess: boolean;
  showLoadConnection: boolean;
  // tslint:disable-next-line:variable-name
  showLoader = true;
  public showLoaderExport: boolean;
  public userToExport: any[];
  // tslint:disable-next-line:variable-name
  showLabel = true;
  // tslint:disable-next-line:variable-name
  public user_sites: any;
  // @ts-ignore
  public certifications: any;
  public user_id: any;
  // tslint:disable-next-line:variable-name
  hideListLoader = false;
  // tslint:disable-next-line:variable-name
  certification_id: any;
  displayBody = true;
  isTousChecked: boolean = true;
  isActifsChecked: boolean = false;
  isNonActifsChecked: boolean = false;
  public entityType = this.configService.entityType;

  ngOnInit() {
    if(this.user_conntected.id)
    {
      this.user_id = this.user_conntected.id;
    }
    else{
      this.user_id = this.user_conntected.user_id;
    }
    this.get_list_sites();
    this.get_list_certifications();
    this.configService.checkUSerAccessByProfile(this.profile_id);
    this.get_list_intervenants(this.profile_id, this.site_id, this.user_id);

  }

    setOrder(value: string) {
      if (this.order === value) {
        this.reverse = !this.reverse;
      } else {
        this.reverse = false;
      }
    
      this.order = value;
    
      // Appliquer le tri après le filtrage et la normalisation
      this.users.sort((a, b) => {
        const fieldA = a.user && a.user[value] ? a.user[value].normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s+/g, "").toLowerCase(): '';
    
        const fieldB = b.user && b.user[value]? b.user[value].normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s+/g, "").toLowerCase(): '';
    
        // Comparer les deux champs pour déterminer l'ordre de tri
        return this.reverse
          ? fieldB.localeCompare(fieldA)
          : fieldA.localeCompare(fieldB);
      });
    }



    //  Méthode pour charger les intervenants avec un nettoyage initial des données
    get_list_intervenants(profile_id, site_id, user_id) {
      this.spinner.show();
      this.userService.getListIntervenant(profile_id, site_id, user_id)
        .subscribe(res => {
          // @ts-ignore
          this.count_intervenant = res.data.length;
          // @ts-ignore
          this.users = res.data.map(user => {
            // Tri sur les noms et prénoms pour un affichage uniforme pour les espaces et les Majuscules
            return {
              ...user,
              user: {
                ...user.user,
                name: user.user.name ? user.user.name.replace(/\s+/g, "").toUpperCase() : '', // Convertir en majuscules
                firstname: user.user.firstname ? user.user.firstname.replace(/\s+/g, "").toUpperCase() : '', // Convertir en majuscules
              }
            };
          });
          this.Allusers = [...this.users];

          // Appliquer un tri initial pour s'assurer que la liste est ordonnée
          this.setOrder('name');
          this.spinner.hide();
        }, (err) => {
          console.log(err);
          this.spinner.hide();
        });
    }



  // tslint:disable-next-line:variable-name
  delete_client(id_client: any) {
    Swal.fire({
      title: 'Etes-vous sûr(e) ?',
      text: 'Cette action est irréversible',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui supprimer',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.value) {
        // @ts-ignore
        this.userService.deleteClient(id_client)
          .subscribe(res => {
            this.get_list_intervenants(this.profile_id, this.site_id, this.user_id);
          }, (err) => {
            console.log(err);
          });
        Swal.fire(
          'Supprimé !',
          'Intervenant supprimé avec succès !',
          'success'
        );
      }
    });
  }

  get_list_sites() {
    this.siteService.getSite()
      .subscribe(res => {
        this.sites = res.data;
      }, (err) => {
        console.log(err);
      });
  }
  get_list_certifications() {
    this.certificationService.getCertificat()
      .subscribe(res => {
        this.certifications = res.data;
      }, (err) => {
        console.log(err);
      });
  }

  // tslint:disable-next-line:variable-name
  get_client_sites(id_site, profile) {
    this.spinner.show();
    this.siteService.get_site_apprenant(id_site, profile)
      .subscribe(res => {
        // @ts-ignore
        this.count_intervenant = res.data.length;
        // @ts-ignore
        this.users = res.data;
        this.Allusers = [ ...this.users ];
        this.afficherTousLesUtilisateurs();
        this.spinner.hide();
      }, (err) => {
        console.log(err);
      });
  }

  filterSite(value) {
    if (value === '7xxx7') {
      // Si "Tout" est sélectionné, chargez tous les intervenants
      this.get_list_intervenants(this.profile_id, this.site_id, this.user_id);
    } else {
      // Si un site spécifique est sélectionné, chargez les intervenants pour ce site
      this.get_client_sites(value, this.profile_id);
    }
  }

  exporter() {
    const dataToExport = [];
    if (this.profile_id !== 11) {
      this.hideListLoader = true;
      const options = {
        fieldSeparator: ';',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'Exportation des intervenants',
        filename: 'liste_intervenant_' + this.formatDate(),
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        // tslint:disable-next-line:max-line-length
        headers: ['Noms', 'Prénoms', 'Date de début', 'Date de fin', 'Statut']
      };
      this.users.forEach((rdata, index) => {
        const data = {
          nom: rdata.user.name,
          prenom: rdata.user.firstname,
          date_debut: rdata.user.start_date === '01/01/1970' ? '' : rdata.user.start_date,
          date_fin: rdata.user.end_date === '01/01/1970' ? '' : rdata.user.end_date,
          status: rdata.user.active === 1 ? 'Actif' : 'Non actif',
        };
        dataToExport.push(data);
      });
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(dataToExport);
    } else {
      this.showLoaderExport = true;
      this.hideListLoader = true;

      this.spinner.show();
      this.userService.getIntervenants_export(this.profile_id, this.site_id, this.connected_id)
        .subscribe(res => {
          // @ts-ignore
          this.userToExport = res.data;
          this.spinner.hide();
          const options = {
            fieldSeparator: ';',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: 'Exportation des intervenants',
            filename: 'liste_intervenant_' + this.formatDate(),
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: false,
            // tslint:disable-next-line:max-line-length
            headers: ['Noms', 'Prénoms', 'Sites', 'Date de début', 'Date de fin', 'Statut']
          };
          this.userToExport.forEach((rdata, index) => {
            // tslint:disable-next-line:variable-name
            let nameSite = '';
            // tslint:disable-next-line:variable-name
            rdata.sites.forEach((rsite, index_site) => {
              nameSite += rsite.name + ' - ';
            });
            const data = {
              nom: rdata.user.name,
              prenom: rdata.user.firstname,
              site: nameSite.slice(0, -2),
              date_debut: rdata.user.start_date === '01/01/1970' ? '' : rdata.user.start_date,
              date_fin: rdata.user.end_date === '01/01/1970' ? '' : rdata.user.end_date,
              status: rdata.user.active === 1 ? 'Actif' : 'Non actif',
            };
            dataToExport.push(data);
          });
          const csvExporter = new ExportToCsv(options);
          csvExporter.generateCsv(dataToExport);
        });
    }
  }

  formatDate() {
    // tslint:disable-next-line:one-variable-per-declaration
    const d = new Date();
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    const hour = d.getHours();
    const minute = d.getMinutes();
    const seconde = d.getSeconds();
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    const dateJoin = [year, month, day].join('');
    const hourJOin = [hour, minute, seconde].join('');
    return [dateJoin, hourJOin].join('_');
  }

  // tslint:disable-next-line:variable-name
  prise_en_main(login_t, email_t) {
    this.showLoadConnection = true;
    this.spinner.show();
    const body = {
      login: login_t,
      email: email_t
    };
    // @ts-ignore
    this.userService.take_in_hand(body)
      .subscribe(res => {
        this.loginService.logout_take_in_hand();
        // @ts-ignore
        if (res.status === 'success') {
          // @ts-ignore
          localStorage.setItem('currentUser', JSON.stringify(res.user));
          localStorage.setItem('takeInHand', 'yes');
          localStorage.setItem('admin_login', this.admin_login);
          // @ts-ignore
          localStorage.setItem('tokenCurrentConnected', JSON.stringify(res.token));
          // @ts-ignore
          this.loginService.currentUserSubject.next(res.user);
          // @ts-ignore
          localStorage.setItem('profile_id', res.profile_id);
          // @ts-ignore
          localStorage.setItem('site_id', res.site_id);
          // @ts-ignore
          if (res.profile_id === 10 || res.profile_id === 8 || res.profile_id === 13) {
            window.location.reload();
            this.router.navigate(['dashboard/inspector']);
            return false;
          }
          window.location.reload();
          this.router.navigate(['dashboard/administrator']);
          return false;
        }
        this.spinner.hide();
      }, (err) => {
        console.log(err);
      });
  }

  // tslint:disable-next-line:variable-name
  listingSiteUser(user_id, profile_id) {
    this.displayBody = false;
    this.showLoader = true;
    this.showLabel = false;
    this.userService.getUserSiteAnd_Profil_InFront(user_id, profile_id)
      .subscribe(res => {
        this.displayBody = true;
        this.showLabel = true;
        // @ts-ignore
        this.user_sites = res.sites;
        this.showLoader = false;
      }, (err) => {
        console.log(err);
      });
  }

  handlePageChange(event) {
    this.page = event;
  }

  viewCertificats(id) {
    this.router.navigate(['dashboard/administrator/apprenant/certificats/' + id]);
  }

  // tslint:disable-next-line:variable-name
  filterExportSite(certification_id) {
    this.getExportByCertifications(certification_id);
  }

  // tslint:disable-next-line:variable-name
  private getExportByCertifications(certification_id) {
    // @ts-ignore
    this.userService.exportintervenant_certifications_status(certification_id)
      .subscribe(res => {
        if (res.success === true) {
          this.date_export = res.data.date_export;
          window.location.href = this.urlDownload + '' + this.date_export;
        }
      }, (err) => {
        console.log(err);
      });
  }

  afficherTousLesUtilisateurs() {
    // Réinitialiser la liste des utilisateurs selon votre logique
    this.users = [...this.Allusers];
    this.count_intervenant = this.users.length;

    // Définir isTousChecked sur true et désactiver les autres boutons
    this.isTousChecked = true;
    this.isActifsChecked = false;
    this.isNonActifsChecked = false;
  }

  // Fonction pour filtrer les utilisateurs non actifs
  filtrerUtilisateurs(actif : number) {
    this.users = this.Allusers.filter((data) => data.user.active === actif);
    this.count_intervenant = this.users.length;
    // Définir isTousChecked sur false
    this.isTousChecked = false;
  }

  convertToDate(dateString: string): Date | null {
    const [day, month, year] = dateString.split('/').map(Number);

    // Vérification des valeurs
    if (isNaN(day) || isNaN(month) || isNaN(year)) {
      return null;
    }

    const date = new Date(year, month - 1, day);

    // Vérification si la date est valide
    if (date instanceof Date && !isNaN(date.getTime())) {
      return date;
    }

    return null;
  }


}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import {ConfigService} from './config.service';
import {Observable, throwError} from 'rxjs';
// @ts-ignore
const token = JSON.parse(localStorage.getItem('tokenCurrentConnected'));
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': 'Bearer ' + token
  }),
};

@Injectable({
  providedIn: 'root'
})
export class SiteService {

  constructor(private http: HttpClient, private config: ConfigService) { }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
  private extractData(res: Response) {
    const body = res;
    return body || { };
  }
  getSite(): Observable<any> {
    return this.http.get(this.config.urlDEV + '/sites', httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }
  // tslint:disable-next-line:variable-name
  get_site_client(site_id, profile): Observable<any> {
    return this.http.get(this.config.urlDEV + '/users/filter_clients/' + site_id + '/' + profile, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }

  // tslint:disable-next-line:variable-name
  get_site_superviseur(site_id, profile): Observable<any> {
    return this.http.get(this.config.urlDEV + '/users/filter_superviseurs/' + site_id + '/' + profile, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }
  // tslint:disable-next-line:variable-name
  get_site_verificateur(site_id, profile): Observable<any> {
    return this.http.get(this.config.urlDEV + '/users/filter_verificateurs/' + site_id + '/' + profile, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }

  // tslint:disable-next-line:variable-name
  get_site_apprenant(site_id, profile): Observable<any> {
    return this.http.get(this.config.urlDEV + '/users/filter_apprenants/' + site_id + '/' + profile, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }

  // tslint:disable-next-line:variable-name
  get_sites_by_user_id(user_id): Observable<any> {
    return this.http.get(this.config.urlDEV + '/users/sites/' + user_id, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }


}

import { HttpHeaders } from "@angular/common/http";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./config.service";
const token = JSON.parse(localStorage.getItem('tokenCurrentConnected'));
const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
    }),
};
export class CatalogueService {
    constructor(http, config) {
        this.http = http;
        this.config = config;
    }
    static handleError(error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(`Backend returned code ${error}, ` +
                `body was: ${error.error}`);
        }
        console.log(error);
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }
    static extractData(res) {
        return res || {};
    }
    getAllCatalogue() {
        return this.http.get(this.config.urlDEV + '/catalogue', httpOptions).pipe(map(CatalogueService.extractData), catchError(CatalogueService.handleError));
    }
    createCatalogue(data) {
        return this.http.post(this.config.urlDEV + '/catalogue/create', data, httpOptions).pipe(map(CatalogueService.extractData), catchError(CatalogueService.handleError));
    }
    editCatalogue(id, data) {
        return this.http.put(this.config.urlDEV + '/catalogue/update/' + id, data, httpOptions).pipe(map(CatalogueService.extractData), catchError(CatalogueService.handleError));
    }
    getCatalogue(id) {
        return this.http.get(this.config.urlDEV + '/catalogue/' + id, httpOptions).pipe(map(CatalogueService.extractData), catchError(CatalogueService.handleError));
    }
    deleteCatalogue(id) {
        return this.http.delete(this.config.urlDEV + '/catalogue/' + id, httpOptions).pipe(map(CatalogueService.extractData), catchError(CatalogueService.handleError));
    }
    getCataloguesByUser(catalogue_langue, user_id) {
        return this.http.get(this.config.urlDEV + '/catalogue/apprenant/' + catalogue_langue + '/' + user_id, httpOptions).pipe(map(CatalogueService.extractData), catchError(CatalogueService.handleError));
    }
}
CatalogueService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CatalogueService_Factory() { return new CatalogueService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ConfigService)); }, token: CatalogueService, providedIn: "root" });

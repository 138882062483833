<div class="row">

  <div class="col-sm-3">
    <div class="card card-outline-success mb-3">
      <div class="card-header card-inverse card-success" style="text-transform: uppercase; font-weight: bold;">
        Les champs
      </div>
      <div class="card-block scroll-list" style="overflow: auto">
        <div #formData class="list-group">
          <li class="list-group-item list-group-item-action list-group-item-success"
              [draggable] *ngFor="let item of vegetables"
              [dragClass]="'active'"
              [dragData]="item"
              [dragScope]="item.type"
              [dragEnabled]="dragEnabled">
            {{item.name}}
          </li>
        </div>
      </div>
    </div>
  </div>

  <div class="col-sm-9">
    <div class="card card-outline-primary mb-3" droppable [dragOverClass]="'drag-target-border'" [dropScope]="['label', 'input-text','input-check', 'is_required']"
         [dragHintClass]="'drag-hint'" (onDrop)="onAnyDrop($event)">
      <div class="card-header card-inverse card-primary" style="text-transform: uppercase; font-weight: bold;">Glisser les éléments sur le formulaire</div>
      <p style="text-align: center"><b>* </b> Ces champs sont obligatoires et non modifiables. </p>
      <div class="card-block scroll-list" cdkDropList id="formContent"  (cdkDropListDropped)="drop($event)" style="overflow: auto">
        <div class="row">
          <div class="col-sm-12">
            <li *ngFor="let item of required_fields; let i = index" class="list-group-item list-requred" style="border: none;">
              <div class="card card_custom">
                <div class="card-header card_bg_color-required">
                  <div class="card_title">
                    * {{item.placeholder ? item.placeholder : item.name}}
                  </div>
                  <div class="card_actions">
                    <div class="icon-text" style="color: #FFF">
                      <mat-slide-toggle
                        style="font-weight: bold;"
                        [checked]="true"
                        [disabled]="true"
                      > Obligatoire</mat-slide-toggle>
                    </div>
                  </div>
                </div>
              </div>
              <label *ngIf="item.inputType === 'label'"></label>
              <label *ngIf="item.displayText"></label>
            </li>
            <li *ngFor="let item of droppedItems; let i = index" cdkDrag class="list-group-item list-requred" style="border: none;">
              <div class="card card_custom">
                <div class="card-header card_bg_color">
                  <div class="card_title">
                    <i class="bx bx-move" style="cursor: move;font-size: 20px;"></i> {{item.placeholder ? item.placeholder : item.name}}
                  </div>
                  <div class="card_actions">
                    <div class="icon-text">
                      <mat-slide-toggle
                        style="font-weight: bold"
                        [checked]="true"
                        (change)="onRequiredChange($event, i, item.ng_model_name)"
                      > Obligatoire</mat-slide-toggle>
                      <mat-icon (click)="removeItem(item, droppedItems)" style="cursor: pointer">delete</mat-icon>
                      <span style="cursor: pointer" (click)="removeItem(item, droppedItems)">Supprimer</span>
                    </div>
                  </div>
                </div>
              </div>
              <label *ngIf="item.inputType === 'label'"></label>
              <label *ngIf="item.displayText"></label>
            </li>
            <!--Old data-->
            <li *ngFor="let item of old_forms; let i= index;" cdkDrag class="list-group-item list-requred" style="border: none;">
              <div class="card card_custom">
                <div class="card-header card_bg_color">
                  <div class="card_title">
                    <i class="bx bx-move" style="cursor: move;font-size: 20px;"></i> {{item.placeholder ? item.placeholder : item.name}}
                  </div>
                  <div class="card_actions">
                    <div class="icon-text">
                      <mat-slide-toggle
                        style="font-weight: bold"
                        [checked]="item.is_required === 0 ? false: true"
                        (change)="onRequiredChange($event, i, item.ng_model_name)"
                      > Obligatoire</mat-slide-toggle>
                      <mat-icon (click)="removeItemUpdate(item, old_forms)" style="cursor: pointer">delete</mat-icon>
                      <span (click)="removeItemUpdate(item, old_forms)" style="cursor: pointer">Supprimer</span>
                    </div>
                  </div>
                </div>
              </div>
              <label *ngIf="item.inputType === 'label'"></label>
              <label *ngIf="item.displayText"></label>
            </li>
          </div>
          <div [innerHTML]="test"></div>
        </div>
      </div>
    </div>
  </div>
</div>

import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {CertificationService} from '../../../../services/certification.service';
import {UserService} from '../../../../services/user.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {SubCertificationService} from '../../../../services/sub-certification.service';
import {ActivatedRoute, Router} from '@angular/router';
import Swal from 'sweetalert2';
import {ConfigService} from '../../../../services/config.service';
import { OrderPipe } from 'ngx-order-pipe';
import {ExportToCsv} from 'export-to-csv';
import {SiteService} from '../../../../services/site.service';
import {Certificat} from "../../../../models/certificat.model";
import {Site} from "../../../../models/site.model";
import {CertificatSite} from "../../../../models/certificat-site.model";
import {SubCertificatSite} from "../../../../models/sub-certificat-site.model";
import {SubCertificat} from "../../../../models/sub-certificat.model";

@Component({
  selector: 'app-sous-certification',
  templateUrl: './sous-certification.component.html',
  styleUrls: ['./sous-certification.component.css']
})
export class SousCertificationComponent implements OnInit {
  order = 'name';
  reverse = false;
  sortedCollection: any[];
  // tslint:disable-next-line:variable-name radix
  public profile_id = parseInt(localStorage.getItem('profile_id'));
  // tslint:disable-next-line:variable-name
  public sub_certificats: any;
  // tslint:disable-next-line:variable-name
  public count_certificat: any;
  show = 10;
  vQuery: string;
  addingSuccess: boolean;
  // tslint:disable-next-line:variable-name
  public site_id: any;
  // tslint:disable-next-line:variable-name
  public list_sites: any;
  // tslint:disable-next-line:max-line-length
  public certificats: any;
  // tslint:disable-next-line:max-line-length
  constructor(private certificationService: CertificationService, private siteService: SiteService, private orderPipe: OrderPipe, private configService: ConfigService, private subCertificationService: SubCertificationService, private userService: UserService, private spinner: NgxSpinnerService, private el: ElementRef, private renderer: Renderer2, private router: Router, private activatedRoute: ActivatedRoute) {
    this.sortedCollection = orderPipe.transform(this.sub_certificats, 'name');
  }
  public entityType = this.configService.entityType;

  ngOnInit() {
    this.configService.checkUSerAccessByProfile(this.profile_id);
    this.get_list_certification();
    this.get_list_sites();
  }

  AllSousCertifications: SubCertificat[] = [];
  AllSites: Site[] = [];
  AllSousCertificationsSites: SubCertificatSite[] = [];

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }

  get_list_certification() {
    this.spinner.show();
    this.subCertificationService.getSousCertificat()
      .subscribe(res => {
        // @ts-ignore
        this.count_certificat = res.data.length;
        // @ts-ignore
        this.sub_certificats = res.data;
        this.AllSousCertifications = res.Sub_certifications_all;
        this.AllSites = res.sites_all;
        this.AllSousCertificationsSites = res.Sub_CertificationSite;
        this.spinner.hide();
      }, (err) => {
        console.log(err);
      });
  }
  increaseShow() {
    this.spinner.show();
    this.show += 10;
    this.spinner.hide();
  }


  // tslint:disable-next-line:variable-name
  editer(id_certificat) {
    this.router.navigate(['/dashboard/administrator/sous-certifications/editer/' + id_certificat], { relativeTo: this.activatedRoute });

  }
// tslint:disable-next-line:variable-name
  supprimer(id_certificat) {
    Swal.fire({
      title: 'Etes-vous sûr(e) ?',
      text: 'Cette action est irréversible',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui supprimer',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.value) {
        const body = {
          id: id_certificat
        };
        // @ts-ignore
        this.subCertificationService.deleteSousCertificat(body)
          .subscribe(res => {
            this.addingSuccess = true;
            this.get_list_certification();
          }, (err) => {
            console.log(err);
          });
        Swal.fire(
          'Supprimé !',
          'Sous-certificat supprimé avec succès !',
          'success'
        );
      }
    });
  }
// tslint:disable-next-line:variable-name
  sites(id_sous_certificat) {
    this.router.navigate(['/dashboard/administrator/sous-certifications/sites/' + id_sous_certificat], { relativeTo: this.activatedRoute});

  }
// tslint:disable-next-line:variable-name
  verifications(id_sous_certificat) {
    this.router.navigate(['/dashboard/administrator/sous-certifications/verifications/' + id_sous_certificat], { relativeTo: this.activatedRoute});

  }
  exporter() {
    const dataToExport = [];
    const options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Exportation des sous-certifications',
      filename: 'liste_sous_certification_' + this.formatDate(),
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      // tslint:disable-next-line:max-line-length
      headers: ['ID', 'Libellé', 'Certifications', 'Type de validité', 'Durée']
    };
    this.sub_certificats.forEach((rdata, index) => {
      // tslint:disable-next-line:variable-name
      const  data = {
        nom: rdata.code_cert,
        label: rdata.name,
        certification: rdata.certPArentName,
        validite: rdata.type_validite === 2 ? 'Mois' : 'Date',
        duree: rdata.type_validite === 2 ? rdata.delay : rdata.date,
        // sous_certifications: labelSubCert,
      };
      dataToExport.push(data);
    });
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(dataToExport);
  }
  formatDate() {
    // tslint:disable-next-line:one-variable-per-declaration
    const d = new Date();
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    const hour = d.getHours();
    const minute = d.getMinutes();
    const seconde = d.getSeconds();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    const dateJoin = [year, month, day].join('');
    const hourJOin = [hour, minute, seconde].join('');
    return [dateJoin, hourJOin].join('_');
  }
  get_list_sites() {
    this.siteService.getSite()
      .subscribe(res => {
        this.list_sites = res.data;
      }, (err) => {
        console.log(err);
      });
  }
  filterSite(value) {
    this.get_sub_certification_sites(value);
  }
  // tslint:disable-next-line:variable-name
  get_sub_certification_sites(id_site) {
    this.spinner.show();
    this.certificationService.get_sous_certification_sites_list(id_site)
      .subscribe(res => {
        this.count_certificat = res.data.length;
        this.sub_certificats = res.data;
        this.spinner.hide();
      }, (err) => {
        console.log(err);
      });
  }
}

import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoginService } from './login.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private loginService: LoginService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';

        if (error.status === 401 || error.error.error === 'Unauthenticated.') {
          // Déconnecter l'utilisateur
          errorMessage = 'Unauthenticated';
          this.loginService.logout_take_in_hand();
          // Rediriger vers la page de connexion
          this.router.navigate(['/']);
        }
        else if (error.status === 403) {
          errorMessage = 'Unauthorized';
          this.loginService.logout_take_in_hand();// redirection ou traitement spécifique pour l'erreur 403
          this.router.navigate(['/']);
        }
        else {
          errorMessage = 'Server error';
        }
        return throwError(errorMessage);
      })
    );
  }
}

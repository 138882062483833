<app-header></app-header>
<app-sidebar></app-sidebar>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"  >
  <p style="font-size: 20px; color: white">Export en cours...</p>
</ngx-spinner>
<div class="main-content">
  <div class="page-content">
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-flex align-items-center justify-content-between">
            <h4 class="mb-0 font-size-18">
              <i class="bx bx-home-circle"></i>  Bienvenue dans votre espace
              <app-fildariane></app-fildariane>
            </h4>
            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item"><a href="javascript: void(0);">Accueil</a></li>
                <li class="breadcrumb-item active"> Mon profil
                  <ng-container *ngIf="profile_id==1">
                    Administrateur
                  </ng-container>
                  <ng-container *ngIf="profile_id==12">
                    Client
                  </ng-container>
                  <ng-container *ngIf="profile_id==6">
                    Superviseur
                  </ng-container>
                  <ng-container *ngIf="profile_id==14">
                    Intervenant
                  </ng-container>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <!-- end page title -->
      <!-- end row -->
      <div class="row">
        <div class="card col-xl-12">
          <div class="card-body">
            <h4 class="card-title mb-4">Export</h4>

            <div class="alert alert-success text-center" role="alert" *ngIf="successMessage">
              {{text}}.
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div>
              <div class="form-group row">
                <label class="col-md-3 col-form-label">Veuillez sélectionner la donnée à exporter</label>
                <div class="col-md-9">
                  <select class="custom-select" [(ngModel)]="exportate.type" (change)="chooseType($event)">
                    <option disabled selected value="">Sélection de la donnée à exporter</option>
                    <ng-container *ngIf="profile_id==11">
                      <ng-container *ngIf="entityType!='IP'">
                      <option value="0">Sites</option>
                      </ng-container>
                      <option value="1">Parcours</option>
                      <option value="2">Sessions</option>
                      <option value="3">Uas</option>
                    </ng-container>
                    <ng-container *ngIf="profile_id==11 || profile_id==1 || profile_id==12 || profile_id==2 || profile_id==4 || profile_id==5  ||  profile_id==6 ||  profile_id==9 ||  profile_id==10">
                      <option value="5">Certificat</option>
                      <option value="8">Sub certificats</option>
                    </ng-container>
                    <ng-container *ngIf="profile_id==11 || profile_id==1 || profile_id==12 || profile_id==2 || profile_id==4 || profile_id==5  ||  profile_id==6 ||  profile_id==9 ||  profile_id==10 ||profile_id==13">
<!--                      <option value="7">Vérification</option>-->
                    </ng-container>
                  </select>
                </div>
              </div>
              <ng-container *ngIf="choose && choose==4">
                <div class="form-group row">
                  <label class="col-md-3 col-form-label">Sélectionner la session</label>
                  <div class="col-md-9">

                    <ng-select [items]="sessions"
                               bindLabel="label_session"
                               bindValue="id_session"
                               [(ngModel)]="session_id">
                    </ng-select>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="choose && choose==5">
                <label class="">Veuillez sélectionner le(s) certificat(s) à exporter</label>
                <div class="listcertificats form-group row text-center mt-5">
                     <ng-container *ngIf="AllCertificats.length === 0">
                      <div class="form-check form-check-inline col-md-6">
                        <label class="form-check-label" >Aucune certification n'est rattachée à votre compte ou votre site.</label>
                      </div>
                    </ng-container>
                  <ng-container *ngIf="AllCertificats.length != 0">
                    <div class="touslescertificats mt-3 mb-3">
                      <input class="form-check-input" type="checkbox" (change)=" checkUncheckAllCertificat($event.target.checked);" #s id="AllCert" value="AllCert">
                      <label class="form-check-label" [style.color]="s.checked ? '#0000FF':'' " for="AllCert">Tous les certificats</label>
                    </div>
                    <ng-container *ngFor="let AllCertificat of AllCertificats; let s = check">
                      <div class="simplecertificat mt-3">
                        <ng-container *ngIf="check_value_certificat(AllCertificat.id) == true">
                        <input class="form-check-input" type="checkbox" checked (change)=" check_certificats(AllCertificat.id, $event.target.checked);" #s  id="{{AllCertificat.id}}" value="{{AllCertificat.id}}">
                        <label class="form-check-label" [style.color]="s.checked ? '#0000FF':'' " for="{{AllCertificat.id}}">{{AllCertificat.name}}</label>
                        </ng-container>
                        <ng-container *ngIf="check_value_certificat(AllCertificat.id) == false">
                        <input class="form-check-input" type="checkbox" (change)=" check_certificats(AllCertificat.id, $event.target.checked);" #s  id="{{AllCertificat.id}}" value="{{AllCertificat.id}}">
                        <label class="form-check-label" [style.color]="s.checked ? '#0000FF':'' " for="{{AllCertificat.id}}">{{AllCertificat.name}}</label>
                        </ng-container>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
<!--              <ng-container *ngIf="choose && choose==7">-->
<!--                <div class="form-group row">-->
<!--                  <label class="col-md-3 col-form-label">Veuillez sélectionner la vérification</label>-->
<!--                  <div class="col-md-9">-->
<!--                    <select class="custom-select" (change)="chooseVerifications($event)">-->
<!--                      <option>Sélectionner la vérification à exporter</option>-->
<!--                      <option *ngFor="let verification of AllVerifications" value="{{verification.id}}">{{verification.name}}</option>-->
<!--                    </select>-->
<!--                  </div>-->
<!--                  <ng-container *ngIf="AllVerifications == 0">-->
<!--                    <div class="form-check form-check-inline col-md-6 mt-3">-->
<!--                      <label class="form-check-label" >Aucune verification n'est rattachée à votre compte ou votre site.</label>-->
<!--                    </div>-->
<!--                  </ng-container>-->
<!--                </div>-->
<!--              </ng-container>-->
              <ng-container *ngIf="choose && choose==8">

                <label class="">Veuillez sélectionner le(s) sub certificat(s) à exporter</label>

                <div class="listcertificats form-group row text-center mt-5">
                  <ng-container *ngIf="AllSubCertificats.length === 0">
                    <div class="form-check form-check-inline col-md-6">
                      <label class="form-check-label" >Aucune sous-certification n'est rattachée à votre compte ou votre site.</label>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="AllSubCertificats.length != 0">
                    <div class="touslescertificats mt-3 mb-3">
                      <input class="form-check-input" type="checkbox" (change)=" checkUncheckAllSubCertificat($event.target.checked);" #s id="AllSub" value="AllSub">
                      <label class="form-check-label" [style.color]="s.checked ? '#0000FF':'' " for="AllSub">Tous les Sous Certificats</label>
                    </div>
                  <ng-container *ngFor="let AllSubCertificat of AllSubCertificats; let s = check">
                          <div class="simplecertificat mt-3">
                        <ng-container *ngIf="check_value_sub_certificat(AllSubCertificat.id) == true">
                           <input class="form-check-input" type="checkbox" checked (change)=" check_sub_certificats(AllSubCertificat.id, $event.target.checked);" #s  id="{{AllSubCertificat.id}}" value="{{AllSubCertificat.id}}">
                           <label class="form-check-label" [style.color]="s.checked ? '#0000FF':'' " for="{{AllSubCertificat.id}}">{{AllSubCertificat.name}}</label>
                         </ng-container>
                        <ng-container *ngIf="check_value_sub_certificat(AllSubCertificat.id) == false">
                          <input class="form-check-input" type="checkbox" (change)=" check_sub_certificats(AllSubCertificat.id, $event.target.checked);" #s  id="{{AllSubCertificat.id}}" value="{{AllSubCertificat.id}}">
                          <label class="form-check-label" [style.color]="s.checked ? '#0000FF':'' " for="{{AllSubCertificat.id}}">{{AllSubCertificat.name}}</label>
                        </ng-container>
                        </div>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
              <div class="alert alert-danger" role="alert" *ngIf="messeNotUser">
                Pas d'internevants inscrits dans cette session
              </div>
              <ng-container *ngIf="choose && choose!=-1">
                <div class="form-group row">
                  <div class="form-group text-center">
                    <button type="button" class="btn btn-primary waves-effect waves-light" (click)="exportation()">
                      Exporter
                    </button>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <!-- end card -->

        <!-- end card -->
      </div>

    </div>
    <!-- end row -->
  </div>
  <!-- container-fluid -->
</div>
<!-- End Page-content -->
<app-footer></app-footer>

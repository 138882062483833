import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class EntityGuard {
    constructor(router) {
        this.router = router;
    }
    canActivate(next, state) {
        const requiredEntityType = next.data['requiredEntityType'];
        if (environment.entityType === requiredEntityType) {
            // Autoriser l'accès pour l'entité correspondante
            return true;
        }
        else {
            // Redirection vers une page d'accès refusé
            this.router.navigate(['/**']);
            return false;
        }
    }
}
EntityGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EntityGuard_Factory() { return new EntityGuard(i0.ɵɵinject(i1.Router)); }, token: EntityGuard, providedIn: "root" });

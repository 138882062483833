import { Component, OnInit } from '@angular/core';
import {ChoiceScreenService} from "../../../../services/choice-screen.service";
import {Groupe} from "../../../../models/groupe.model";
import {ChoiceScreen} from "../../../../models/choice-screen.model";
import Swal from "sweetalert2";
import {ChoiceScreenGroupes} from "../../../../models/choice-screen-groupes.model";
import {FormArray, FormBuilder, FormControl} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {GroupeService} from "../../../../services/groupe.service";

@Component({
  selector: 'app-edit-choice-screen',
  templateUrl: './edit-choice-screen.component.html',
  styleUrls: ['./edit-choice-screen.component.css']
})
export class EditChoiceScreenComponent implements OnInit {

  public user_conntected = JSON.parse(localStorage.getItem('currentUser'));
  public profile_id = parseInt(localStorage.getItem('profile_id'));

  constructor(private choiceScreenService: ChoiceScreenService,
              private formBuilder: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private groupeService: GroupeService,
              private router: Router) { }

  //Initialisation
  GroupesEntre: Groupe[] = [];
  GroupesChoice: Groupe[] = [];
  ChoiceScreen = new ChoiceScreen();
  ChoiceScreenOlder = new ChoiceScreen();
  ChoiceScreenOlderGroupes = [];
  allGroupes : any;

  //Forms
  FormGroupes: any;
  FormGroupesArray: any;

  //Group formatting
  page = 1;
  order = 'groupe.name';
  reverse = false;
  vQuery: string;

  //update ChoiceScreen
  responseMessage : string;

  ngOnInit() {
    //FORMS
    this.FormGroupes = this.formBuilder.group({
      group_id: this.formBuilder.array([])
    });
    this.FormGroupesArray = <FormArray>this.FormGroupes.controls.group_id;

    this.ShowChoiceScreen(this.activatedRoute.snapshot.url[4].path)
    this.AllGroups();
  }


  ShowChoiceScreen(id) {
    this.choiceScreenService.getChoiceScreen(id)
      .subscribe(res => {
        this.ChoiceScreen = res.choiceScreen;
        this.ChoiceScreenOlder = { ...res.choiceScreen };
        this.ChoiceScreen.groupes.forEach(groupe => {
          this.checkGroupe(groupe.id,true);
        });
        this.ChoiceScreen.groupes.forEach(groupe => {
          this.ChoiceScreenOlderGroupes.push(groupe.id);
        });
      }, (err) => {
        Swal.fire(
          'Erreur',
          'Une erreur s\'est produite lors de la récupération des informations',
          'error'
        );
      });
  }

  //FORM
  check_value(groupe_id){
    let value_check = this.FormGroupesArray.value.includes(groupe_id);
    if(value_check){
      return true;
    }
    else{
      return false;
    }
  }

  checkGroupe(groupe_id, event : any){
    if(event) {
      this.FormGroupesArray.push(new FormControl(groupe_id));
    } else {
      let index = this.FormGroupesArray.controls.findIndex(x => x.value == groupe_id)
      this.FormGroupesArray.removeAt(index);
    }
  }

  //Group formatting
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }

  //get term of the search
  groupe_name_value(event : any){
    let group_name;
    group_name = event.target.value;
    this.search_groupes(group_name);
  }

  //Functionality who permit to search group
  search_groupes(groupe_name){
    //when only research filter byt groupes name
    if (groupe_name && groupe_name.length > 0) {
      this.GroupesChoice = this.allGroupes.filter((Groupe) => Groupe.name.toLocaleLowerCase().includes(groupe_name.toLocaleLowerCase()));
    }
    else{
      this.GroupesChoice = [ ...this.allGroupes ];
    }
  }

  //GROUPES
  //Recover all groupes
  AllGroups() {
    this.groupeService.getGroupsByUserLogin(this.profile_id,this.user_conntected.login)
      .subscribe(res => {
        this.GroupesEntre = res.groupes;
        this.GroupesChoice = res.groupes;
        this.allGroupes = [ ...this.GroupesChoice ]
      }, (err) => {
        console.log(err);
      });
  }

  //UPDATE CHOICE SCREEN
  updateChoiceScreen(id) {
    const areDataEqual = JSON.stringify(this.FormGroupesArray.value) === JSON.stringify(this.ChoiceScreenOlderGroupes);

    // tslint:disable-next-line:max-line-length
    if (this.ChoiceScreen.titre === undefined || this.ChoiceScreen.titre.trim() === '') {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Le titre est manquant'
      });
      return false;
      // tslint:disable-next-line:align
    }
    else if (this.ChoiceScreen.description === undefined || this.ChoiceScreen.description.trim() === '') {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Veuillez mettre une description',
      });
      return false;
      // tslint:disable-next-line:align
    }
    else if (this.ChoiceScreen.groupe_id === undefined) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Veuillez sélectionner un groupe d\'entré',
      });
      return false;
      // tslint:disable-next-line:align
    }
    else if (this.FormGroupesArray.length <= 1) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Veuillez sélectionner au moins deux groupes de choix',
      });
      return false;
      // tslint:disable-next-line:align
    }
    else if (this.ChoiceScreenOlder.titre == this.ChoiceScreen.titre
      && this.ChoiceScreenOlder.description == this.ChoiceScreen.description
      && this.ChoiceScreenOlder.groupe_id == this.ChoiceScreen.groupe_id
      && areDataEqual == true
    ) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Aucune modification n\'a eu lieu',
      });
      return false;
    }
    else{
      const body = {
        titre: this.ChoiceScreen.titre,
        description: this.ChoiceScreen.description,
        groupe_id: this.ChoiceScreen.groupe_id,
        array_choice_screen_groupe_id : this.FormGroupesArray.value,
      };
      this.choiceScreenService.updateChoiceScreen(id,body)
        .subscribe(res => {
          if(res.status == false)
            // @ts-ignore: Unreachable code error
            this.responseMessage = res.messages;
          this.ChoiceScreen.titre = '';
          this.ChoiceScreen.description = '';
          this.ChoiceScreen.groupe_id = null;
          Swal.fire({
            title: 'Ecran de choix créé !',
            text: res.message,
            icon: 'success',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Revenir sur la liste des écran de choix',
            cancelButtonText: 'Rester sur la page'
          }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigate(['/dashboard/administrator/choice_screen']);
            }
            else{
              window.location.reload();
              this.ShowChoiceScreen(this.activatedRoute.snapshot.url[4].path);
            }
          });
        }, (err) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: this.responseMessage
          });
        });
    }
  }

  areArraysEqual(array1: any[], array2: any[]): boolean {
    if (array1.length !== array2.length) {
      return false;
    }
    return array1.every((element, index) => element === array2[index]);
  }

}

import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {SiteService} from '../../../../services/site.service';
import {UserService} from '../../../../services/user.service';
import {CertificationService} from '../../../../services/certification.service';
import {ProfileService} from '../../../../services/profile.service';
import {VerificationService} from '../../../../services/verification.service';
import Swal from "sweetalert2";
import {ConfigService} from "../../../../services/config.service";
import {FormArray, FormControl, FormBuilder,FormGroup} from "@angular/forms";

@Component({
  selector: 'app-edit-verification',
  templateUrl: './edit-verification.component.html',
  styleUrls: ['./edit-verification.component.css']
})
export class EditVerificationComponent implements OnInit {

  // tslint:disable-next-line:variable-name
  public single_verification = {
    name: null,
    id: null,
    site_id: null,
    certificat_id: null
  };


  // tslint:disable-next-line:variable-name
  public certificats_list_by_verification: any[]  = [{
    certificat_id: '',
    name: ''
  }];
  // tslint:disable-next-line:variable-name
  public site_form: any[] = [{
    certificat_ID: ''
  }];
  public sites= [];
  // tslint:disable-next-line:variable-name radix
  public profile_id = parseInt(localStorage.getItem('profile_id'));
  public addingSuccess: boolean;
  public profiles: any;
  public certificats: any;
  public site_id_verification: any;
  // tslint:disable-next-line:variable-name
  public new_certificat_id: any[] = [{
    certificat_ID: ''
  }];
  public allCertificatID: any[];
  // tslint:disable-next-line:variable-name
  public certificat_ids: any;
  // tslint:disable-next-line:max-line-length
  private myForm: any;
  public siteFormArray: any;
  public array = [];
  private array_sites_id: any;
  private val: any;
  constructor(private configService: ConfigService, private formBuilder: FormBuilder, private router: Router, private activatedRoute: ActivatedRoute, private spinner: NgxSpinnerService, private siteService: SiteService, private userService: UserService, private certificationService: CertificationService, private profileService: ProfileService, private verificationService: VerificationService) { }
  public entityType = this.configService.entityType;

  ngOnInit() {
    this.configService.checkUSerAccessByProfile(this.profile_id);
    this.get_verification(this.activatedRoute.snapshot.url[4].path);
    this.get_certification_list_verification(this.activatedRoute.snapshot.url[4].path);
    this.get_list_certification();
    this.get_list_sites();
  }

  // tslint:disable-next-line:variable-name
  get_verification(id_verification: any) {
    this.spinner.show();
    this.verificationService.getOneVerification(id_verification)
      .subscribe(res => {
        // @ts-ignore
        this.single_verification = res.data;
        this.site_id_verification = res.site_id;
        this.spinner.hide();
      }, (err) => {
        console.log(err);
      });
  }

  selectOption(id: number) {
    // getted from event
    this.new_certificat_id.push({
      certificat_ID: id
    });
    const body = {
      certificat_id: id,
      id: this.activatedRoute.snapshot.url[4].path
    };
    this.verificationService.liveUpdateCertification(body)
      .subscribe(res => {
        this.addingSuccess = true;
        this.get_certification_list_verification(this.activatedRoute.snapshot.url[4].path);
      }, (err) => {
        console.log(err);
      });
  }

  // tslint:disable-next-line:variable-name
  get_certification_list_verification(id_verification: any) {
    this.verificationService.getCertificationByVerification(id_verification)
      .subscribe(res => {
        // @ts-ignore
        this.certificats_list_by_verification = res.data;
      }, (err) => {
        console.log(err);
      });
  }
  get_list_sites() {
    this.siteService.getSite()
      .subscribe(res => {
        this.sites = res.data;
        this.array_sites_id = res.id;
      }, (err) => {
        console.log(err);
      });
  }

  addFormSite() {
    // this.get_list_certification();
    this.site_form.push({
      id: this.site_form.length + 1,
      certificat_ID: ''
    });
  }

  removeFormSite(i: number) {
    this.site_form.splice(i, 1);
  }

  get_list_certification() {
    this.certificationService.getCertificat()
      .subscribe(res => {
        // @ts-ignore
        this.certificats = res.data.certificat;
      }, (err) => {
        console.log(err);
      });
  }

  edit_verification() {
    const body = {
      name: this.single_verification.name,
      site_id: this.single_verification.site_id,
      certificats_old_updated: this.new_certificat_id,
      certificats_old_db: this.certificats_list_by_verification,
      certificats_new_adding: this.site_form,
      id: this.single_verification.id,
    };
    this.verificationService.updateVerification(body)
      .subscribe(res => {
        this.addingSuccess = true;
        setTimeout (() => {
          this.addingSuccess = false;
        }, 2000);
      }, (err) => {
        console.log(err);
      });
  }

  removeFormSiteOld(oldId) {
    Swal.fire({
      title: 'Etes-vous sûr(e) ?',
      text: 'Cette action est irréversible',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui supprimer',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.value) {
        const body = {
          certificat_id: oldId
        };
        // @ts-ignore
        this.verificationService.delete_content_verification(body)
          .subscribe(res => {
            this.addingSuccess = true;
            this.get_certification_list_verification(this.activatedRoute.snapshot.url[4].path);
          }, (err) => {
            console.log(err);
          });
        Swal.fire(
          'Supprimé !',
          'Certificat supprimée avec succès !',
          'success'
        );
      }
    });
  }
}

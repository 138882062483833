import { Component, OnInit } from '@angular/core';
import {SiteService} from '../../../../services/site.service';
import {UserService} from '../../../../services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfigService} from '../../../../services/config.service';
import Swal from "sweetalert2";

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {

  // tslint:disable-next-line:variable-name
  public single_client = {
    name: null,
    firstname: null,
    phone: null,
    password: null,
    u_remote_id: null,
    password_confirm: null,
    site_id: null,
    publish: null,
    id: null,
    profile_id: null,
    society: null,
    login: null,
    email: null
  };
  success_Message: boolean;
  // tslint:disable-next-line:variable-name
  not_success_Message: boolean;
  // tslint:disable-next-line:variable-name
  toggle1 = false;
  toggle2 = false;
  text: any;
  loadingAdd: boolean;
  private sub: any;
  public sites_admin: any;
  public user_id: any;
  public sites: any;
  public users: any;
  public user_url_id: any;
  passwordIsValid = false;
  public showCustomProfile: boolean;
  public groups_id: any;
  // tslint:disable-next-line:variable-name radix
  public profile_id = parseInt(localStorage.getItem('profile_id'));
  public site_id = parseInt(localStorage.getItem('site_id'));
  public user_conntected = JSON.parse(localStorage.getItem('currentUser'));
  public profile_id_connected = this.user_conntected.profile_id;

  public addingSuccess: boolean;
  // tslint:disable-next-line:variable-name
  public the_update_id: any;
  // tslint:disable-next-line:max-line-length
  public userSiteId: any;
  public form_validate = false;

  // tslint:disable-next-line:max-line-length
  constructor(private siteService: SiteService, private userService: UserService, private activatedRoute: ActivatedRoute, public router: Router, private configService: ConfigService) { }

  ngOnInit() {
    this.user_id = this.user_conntected.id;
    this.configService.checkUSerAccessByProfile(this.profile_id);
    this.sub = this.activatedRoute.params.subscribe(params => {
      this.the_update_id = params.id;
    });

    if(this.profile_id_connected===11){
      this.get_client(this.the_update_id);
      this.form_validate = true;
    }

    else if(this.profile_id_connected !==11) {
      this.userService.getListUser(this.profile_id_connected, this.site_id, this.user_conntected.id)
        .subscribe(res => {
          // @ts-ignore
          this.users = res.data;
          const user_tab = [];
          for (let us of this.users) {
            user_tab.push(us.user.id)
          }
          for (let id of user_tab){
            if (id == this.the_update_id){
              this.get_client(this.the_update_id);
              this.form_validate = true;
            }
          }
        }, (err) => {
          console.log(err);
        });
    }
    else{
      this.router.navigate(['dashboard/administrator']);
    }

  }

  // tslint:disable-next-line:variable-name
  get_client(id_client) {
    this.userService.getApprenantDetailWithSite(id_client)
      .subscribe(res => {
        this.single_client.name = res.data.name;
        this.single_client.firstname = res.data.firstname;
        // this.single_client.password = res.data.password;
        this.single_client.u_remote_id = res.data.u_remote_id;
        this.single_client.email = res.data.email;
        this.single_client.site_id = res.data.site_id;
        this.single_client.publish = res.data.publish;
        this.single_client.profile_id = res.data.profile_id;
        this.single_client.login = res.data.login;
        this.single_client.society = res.data.society;
        this.single_client.id = res.data.id;
      }, (err) => {
        console.log(err);
      });
  }


  passwordValid(event) {
    this.passwordIsValid = event;
  }

  showPublishing(value) {
    if (value === '1') {
      this.showCustomProfile = true;
    } else {
      this.showCustomProfile = false;
    }
  }

  getGroupChoose(event: any) {
    this.groups_id = event.target.value;
  }

  showNotMessageSuccess(text){
    this.text = text;
    this.not_success_Message = true;
    setTimeout (() => {
      this.not_success_Message = false;
    }, 5000);
  }

  showMessageSuccess(text){
    this.text = text;
    this.success_Message = true;
    setTimeout (() => {
      this.success_Message = false;
    }, 5000);
  }


  update_client() {
    const body = {
      name: this.single_client.name,
      email: this.single_client.email,
      password: this.single_client.password,
      password_confirm: this.single_client.password_confirm,
      firstname: this.single_client.firstname,
      login: this.single_client.login,
      id: this.single_client.id,
      site_id: this.single_client.site_id,
      society: this.single_client.society,
      profile_id: this.single_client.profile_id,
    };
    this.userService.editUser(body)
      .subscribe(res => {
        if (res.success === true) {
          this.text = res.message;
          this.showMessageSuccess(this.text);
        }
        if (res.success === false) {
          this.text = res.message;
          this.showNotMessageSuccess(this.text);
        }
      }, (err) => {
        console.log(err);
      });
  }

  // tslint:disable-next-line:variable-name
  changeType(input_field_password, num) {
    if (input_field_password.type === 'password') {
      input_field_password.type = 'text';
    } else {
      input_field_password.type = 'password';
    }
    if (num === 1) {
      this.toggle1 = !this.toggle1;
    } else {
      this.toggle2 = !this.toggle2;
    }
  }

}

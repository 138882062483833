import { HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./config.service";
// @ts-ignore
const token = JSON.parse(localStorage.getItem('tokenCurrentConnected'));
const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
    }),
};
export class FormCreatorService {
    constructor(http, config) {
        this.http = http;
        this.config = config;
    }
    handleError(error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(`Backend returned code ${error}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }
    extractData(res) {
        const body = res;
        return body || {};
    }
    // tslint:disable-next-line:variable-name
    getFormList(profile_id, user_id) {
        return this.http.get(this.config.urlDEV + '/form_list/' + profile_id + '/' + user_id, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    getCountryList() {
        return this.http.get(this.config.urlDEV + '/nlmk/listcountry', httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    getOneForm(id) {
        return this.http.get(this.config.urlDEV + '/forms/details/' + id, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    createForm(data) {
        return this.http.post(this.config.urlDEV + '/forms/create', data, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    createFormNlmk(data) {
        return this.http.post(this.config.urlDEV + '/forms/create/nlmk', data, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    createFormAutoInscription(data) {
        return this.http.post(this.config.urlDEV + '/forms/create/autoinscription', data, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    // tslint:disable-next-line:variable-name
    getListOfGroupBySite(site_id) {
        return this.http.get(this.config.urlDEV + '/nlmk/getGroupeBySite/' + site_id, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    // tslint:disable-next-line:variable-name
    getListOfGroupforAdmin() {
        return this.http.get(this.config.urlDEV + '/group_for_admin', httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    deleteform(data) {
        return this.http.post(this.config.urlDEV + '/forms/delete', data, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    updateform(data) {
        return this.http.post(this.config.urlDEV + '/forms/update', data, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    // tslint:disable-next-line:variable-name
    getForm(id) {
        return this.http.get(this.config.urlDEV + '/forms/' + id, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    sso_connexion_form_redirection(data) {
        return this.http.post(this.config.urlDEV + '/forms/sso/sso_connexion', data, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
}
FormCreatorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FormCreatorService_Factory() { return new FormCreatorService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ConfigService)); }, token: FormCreatorService, providedIn: "root" });

import {Chapitre} from "./chapitre.model";

export class Catalogue {
  id: number;
  titre: string;
  description: string;
  langue: string;
  chapitres: Chapitre[];
  created_at: Date;
  updated_at: Date;
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footerIP',
  templateUrl: './footerIPComponent.component.html',
  styleUrls: ['./footerIPComponent.component.css']
})
export class FooterIPComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

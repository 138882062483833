/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./fildariane.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../EKAMPUS/dashboard/template/fildariane/fildarianeEKAMPUS.component.ngfactory";
import * as i3 from "../../../EKAMPUS/dashboard/template/fildariane/fildarianeEKAMPUS.component";
import * as i4 from "../../../IP/dashboard/template/fildariane/fildarianeIP.component.ngfactory";
import * as i5 from "../../../IP/dashboard/template/fildariane/fildarianeIP.component";
import * as i6 from "@angular/common";
import * as i7 from "./fildariane.component";
import * as i8 from "../../../services/config.service";
var styles_FildarianeComponent = [i0.styles];
var RenderType_FildarianeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FildarianeComponent, data: {} });
export { RenderType_FildarianeComponent as RenderType_FildarianeComponent };
function View_FildarianeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-fildarianeEKAMPUS", [], null, null, null, i2.View_FildarianeEKAMPUSComponent_0, i2.RenderType_FildarianeEKAMPUSComponent)), i1.ɵdid(2, 114688, null, 0, i3.FildarianeEKAMPUSComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_FildarianeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-fildarianeIP", [], null, null, null, i4.View_FildarianeIPComponent_0, i4.RenderType_FildarianeIPComponent)), i1.ɵdid(2, 114688, null, 0, i5.FildarianeIPComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_FildarianeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FildarianeComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FildarianeComponent_2)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.entityType == "EKAMPUS"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.entityType == "IP"); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_FildarianeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-fildariane", [], null, null, null, View_FildarianeComponent_0, RenderType_FildarianeComponent)), i1.ɵdid(1, 114688, null, 0, i7.FildarianeComponent, [i8.ConfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FildarianeComponentNgFactory = i1.ɵccf("app-fildariane", i7.FildarianeComponent, View_FildarianeComponent_Host_0, {}, {}, []);
export { FildarianeComponentNgFactory as FildarianeComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../EKAMPUS/dashboard/template/footer/footerEkampus.component.ngfactory";
import * as i3 from "../../../EKAMPUS/dashboard/template/footer/footerEkampus.component";
import * as i4 from "../../../IP/dashboard/template/footer/footerIPComponent.component.ngfactory";
import * as i5 from "../../../IP/dashboard/template/footer/footerIPComponent.component";
import * as i6 from "@angular/common";
import * as i7 from "./footer.component";
import * as i8 from "../../../services/config.service";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
function View_FooterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-footerEkampus", [], null, null, null, i2.View_FooterEkampusComponent_0, i2.RenderType_FooterEkampusComponent)), i1.ɵdid(2, 114688, null, 0, i3.FooterEkampusComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_FooterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-footerIP", [], null, null, null, i4.View_FooterIPComponent_0, i4.RenderType_FooterIPComponent)), i1.ɵdid(2, 114688, null, 0, i5.FooterIPComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_FooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_2)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.entityType == "EKAMPUS"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.entityType == "IP"); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 114688, null, 0, i7.FooterComponent, [i8.ConfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterComponentNgFactory = i1.ɵccf("app-footer", i7.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footerIPComponent.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./footerIPComponent.component";
var styles_FooterIPComponent = [i0.styles];
var RenderType_FooterIPComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterIPComponent, data: {} });
export { RenderType_FooterIPComponent as RenderType_FooterIPComponent };
export function View_FooterIPComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "footer", [["class", "foot footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "col-sm-6"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 2021 \u00A9 Kaptitude "])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "col-sm-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "text-sm-right d-none d-sm-block"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Cr\u00E9ation agence web Enjin "]))], null, null); }
export function View_FooterIPComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footerIP", [], null, null, null, View_FooterIPComponent_0, RenderType_FooterIPComponent)), i1.ɵdid(1, 114688, null, 0, i2.FooterIPComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterIPComponentNgFactory = i1.ɵccf("app-footerIP", i2.FooterIPComponent, View_FooterIPComponent_Host_0, {}, {}, []);
export { FooterIPComponentNgFactory as FooterIPComponentNgFactory };

import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginService} from '../../../services/login.service';
import {UserService} from '../../../services/user.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ConfigService} from '../../../services/config.service';
import {VerificationService} from '../../../services/verification.service';
import {formatDate} from '@angular/common';
import * as moment from 'moment';
import { interval, Subscription } from 'rxjs';
import Swal from "sweetalert2";

@Component({
  selector: 'app-result-intervenant',
  templateUrl: './result-intervenant.component.html',
  styleUrls: ['./result-intervenant.component.css']
})
export class ResultIntervenantComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  public reponseA_R_All: any;
  public isAccepted = false;
  public labelText: string;
  public nLabel: number;
  public cptCount = 0;
  public cptssCertif = 0;
  public cptCountFAke = 0;
  public rCptFake: number;
  public fakeCodeCert = 'FAKER';
  public FirstCK2;
  public certificationsLenght: any;
  // tslint:disable-next-line:variable-name
  public sub_certifications: any;
  // tslint:disable-next-line:variable-name
  public admin_verifications: any;
  // tslint:disable-next-line:variable-name
  public user_conntected = JSON.parse(localStorage.getItem('currentUser'));
  // tslint:disable-next-line:variable-name
  public profile_id = this.user_conntected.profile_id;
  public takeInHand = localStorage.getItem('takeInHand');
  public labelSousCertifcation: boolean;
  // tslint:disable-next-line:variable-name
  public query_status: boolean;
  // tslint:disable-next-line:variable-name
  public query_message: any;
  public connectLikeVerifcateur = localStorage.getItem('connect_like_verificateur');
  // tslint:disable-next-line:variable-name
  public cpt_cert_and_sub_requiring: any;
  // tslint:disable-next-line:variable-name
  public cpt_comparaison_result: any;
  // tslint:disable-next-line:variable-name
  public check_requiring: any;
  // tslint:disable-next-line:variable-name
  public label_no_register: string;
  // tslint:disable-next-line:variable-name
  public certifications_list: any;
  // tslint:disable-next-line:variable-name
  public array_is_register: any;
  public certificationsSize: any;

  constructor(
    public translate: TranslateService,
    public pageTitle: Title,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private userService: UserService,
    private verificationService: VerificationService,
    private spinner: NgxSpinnerService,
    private config: ConfigService
  ) {
    translate.addLangs(['fr', 'en']);
    translate.setDefaultLang('fr');
    pageTitle.setTitle('Espace Vérificateur | Plateforme ALAYA');
    this.photo_url = this.config.urlDEV_images;
    if (this.user_conntected !== null) {
      this.get_single_user_connected_detail(this.user_conntected.user_id ? this.user_conntected.user_id : this.user_conntected.id);
    }

  }
  // tslint:disable-next-line:variable-name
  public admin_login = localStorage.getItem('admin_login');
  public certifDetails = [];
  // tslint:disable-next-line:variable-name
  public result_final = [];
  // tslint:disable-next-line:variable-name radix
  public site_id = parseInt(localStorage.getItem('site_id'));
  // tslint:disable-next-line:variable-name
  status_datas: any;
  // tslint:disable-next-line:variable-name
  new_data_to_loops: any;
  // tslint:disable-next-line:variable-name
  fake_pass: string;
  public reponseAutorisedRefusedAll = [];
  public verifications: any;
  public certificats: any;
  // tslint:disable-next-line:variable-name
  public comapraison_result: any;
  // tslint:disable-next-line:variable-name
  public count_path_validating: any;
  public sucessArrays: any;
  public certif: string;
  public resultByCertificats: {};
  // tslint:disable-next-line:variable-name
  public photo_url ;
  // tslint:disable-next-line:variable-name
  public single_user = {
    name : null,
    photo : null,
  };

  public resultFinal = [];

  private sub: any;
  // tslint:disable-next-line:variable-name
  the_finding: number;
  // tslint:disable-next-line:variable-name
  verification_id: string;
  // tslint:disable-next-line:variable-name
  user_id: string;
  // tslint:disable-next-line:variable-name
  verificateur_id: string;
  // tslint:disable-next-line:variable-name
  message_status_date: string;

  // tslint:disable-next-line:variable-name
  intervenant_find = {
    name : null,
    firstname : null,
    photo : null,
    society : null,
    id : null,
  };
  certifications: any;
  // tslint:disable-next-line:variable-name
  certificats_list_by_verification: any;
  // tslint:disable-next-line:variable-name
  result_user_verification : boolean;
  // tslint:disable-next-line:variable-name
  single_verification: any;
  today: Date;
  displaying: any;
  status: any;
  // tslint:disable-next-line:variable-name
  cpt_valid = 0;
  // tslint:disable-next-line:variable-name
  cpt_not_valid = 0;
  // tslint:disable-next-line:variable-name
  cpt_certif = 0;

  // tslint:disable-next-line:variable-name
  cpt_passed = 0;
  // tslint:disable-next-line:variable-name
  cpt_failed = 0;

  // tslint:disable-next-line:variable-name
  show_result: boolean;

  public fakeYes = 0;
  public fakeNo = 0;


  // tslint:disable-next-line:variable-name

  ngOnInit() {
    if (this.connectLikeVerifcateur === 'no') {
      this.router.navigate(['dashboard/administrator']);
      return false;
    }
    // this.config.redirectTools();
    this.get_list_admin_verification();
    this.today = this.todayDate();
    this.user_id = this.activatedRoute.snapshot.url[4].path;
    this.verification_id = this.activatedRoute.snapshot.url[5].path;
    this.verificateur_id = this.activatedRoute.snapshot.url[6].path;
    this.sub = this.activatedRoute.params.subscribe(params => {
      this.the_finding = params.id;
      this.get_search_find_details(this.the_finding, this.verification_id);
    });
    this.get_verification(this.activatedRoute.snapshot.url[5].path);
    this.get_certification_list_verification(this.activatedRoute.snapshot.url[5].path);
    this.get_list_verification(this.site_id);
  }

  logout_user() {
    this.loginService.logout().subscribe(
      res => {
        if(res.status == 'success'){
          localStorage.removeItem('currentUser');
          localStorage.removeItem('tokenCurrentConnected');
          localStorage.removeItem('profile_id');
          localStorage.removeItem('site_id');
          localStorage.removeItem('takeInHand');
          localStorage.removeItem('connect_like_verificateur');
          this.router.navigate(['/']);
          setTimeout(() => {
            location.reload();
          }, 1000);
        }
        else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Une erreur s\'est produite lors de la déconnexion',
            footer: '<a href>Equipe Kaptitude</a>'
          });
        }
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Une erreur s\'est produite lors de la déconnexion',
          footer: '<a href>Equipe Kaptitude</a>'
        });
      }
    );
  }
  // tslint:disable-next-line:variable-name
  get_single_user_connected_detail(id_user: number) {
    this.userService.getUser(id_user)
      .subscribe(res => {
        this.single_user.name = res.data.name;
        this.single_user.photo = this.photo_url + '' + res.data.photo;
      }, (err) => {
        console.log(err);
      });
  }

  // tslint:disable-next-line:variable-name
  get_verification(id_verification: any) {
    this.spinner.show();
    this.verificationService.getOneVerification(id_verification)
      .subscribe(res => {
        // @ts-ignore
        this.single_verification = res.data;
      }, (err) => {
        console.log(err);
      });
  }

  // tslint:disable-next-line:variable-name
  get_certification_list_verification(verification_id) {
    this.verificationService.getCertificationByVerification(verification_id)
      .subscribe(res => {
        // @ts-ignore
        this.certificats_list_by_verification = res.data;
      }, (err) => {
        console.log(err);
      });
  }
  // tslint:disable-next-line:variable-name
  get_search_find_details(id_find, verification_id) {
    this.userService.get_intervenant_result(id_find, verification_id)
      .subscribe(res => {
        // Display User information

        this.query_status = res.data.results.status;
        this.query_message = res.data.results.error;
        this.intervenant_find.id = res.data.results.user.id;
        this.intervenant_find.name = res.data.results.user.name;
        this.intervenant_find.firstname = res.data.results.user.firstname;
        this.intervenant_find.society = res.data.results.user.society;
        this.intervenant_find.photo = this.photo_url + '' + res.data.results.user.photo;
        // Display Certificats Requiring certificat
        this.certifications_list = res.data.results.certifications;
        this.certificats = res.data.results.certifications_and_subcertificats_requiring;
        // tslint:disable-next-line:variable-name
        this.certificats.forEach((certificat_loop, certIndex) => {
        });
        this.certificationsLenght = res.data.results.certifications.length - 1;
        this.certificationsSize = res.data.results.certifications.length;
        // Cpt Requiring Cert & subCert
        this.cpt_cert_and_sub_requiring = res.data.results.certifications_and_subcertificats_requiring.length;
        // Cpt Comparaison
        this.cpt_comparaison_result = res.data.results.comapraison_result.length;
        this.comapraison_result = res.data.results.comapraison_result;
        this.sub_certifications = res.data.results.sub_certifications.length;
        this.cpt_passed = res.data.results.cpt_passed;
        this.cpt_failed = res.data.results.cpt_failed;
        this.count_path_validating = res.data.results.count_path_validating;
        this.sucessArrays = res.data.results.sucessArrays;
        this.check_requiring = res.data.results.check_requiring;
        this.array_is_register = res.data.results.array_is_register;
        const fakeCodeCert = 'FAKER';
        const fakeIndex = '';
        let cPassed = 0;
        let cFailed = 0;
        // tslint:disable-next-line:variable-name
        let is_sub_cert;
        this.sucessArrays.forEach((sucessArray, rindex) => {
          this.certificats.forEach((certification, index) => {
            if (certification.code_cert === sucessArray.code_cert) {
              if (fakeIndex !== index) {
                cPassed = 0;
                cFailed = 0;
              }
              if (sucessArray.code_cert.substring(0, 3) === 'CK2') {
                if (this.fakeCodeCert === 'FAKER') {
                  this.labelText = 'SOUS-CERTIFICATS';
                  this.FirstCK2 = Object.values(sucessArray)[0];

                } else  {
                  this.labelText = '';
                  this.cptCountFAke++;
                }
                this.fakeCodeCert = sucessArray.code_cert;
              }
              if (sucessArray.code_cert.substring(0, 3) === 'CK2') {
                is_sub_cert = 'yes';
                this.nLabel = rindex;
              } else {
                is_sub_cert = 'no';
              }
              if (certification.code_cert === sucessArray.code_cert) {
                this.certif = 'certificat' + index;
                if (sucessArray.success === 'yes') {
                  cPassed++;
                }
                if (sucessArray.failed === 'yes') {
                  cFailed++;
                }
                if (sucessArray.incomplete === 'yes') {
                  cFailed++;
                }
                const data = {
                  certificat : this.certif,
                  code_cert : sucessArray.code_cert,
                  certificat_id : sucessArray.certificat_id,
                  parent_certificat_id : sucessArray.parent_certificat_id,
                  result: {
                    failed: cFailed,
                    passed: cPassed,
                    is_sub_cert,
                  }
                };
                this.resultFinal.push(data);
              }
            }
          });
          this.cpt_certif++;
        });
        this.resultByCertificats = this.groupByCertificat(this.resultFinal);
        const resultats = this.resultByCertificats;
        // tslint:disable-next-line:only-arrow-functions
        let nResponseAutorised = 0;
        let nResponseRefused = 0;
        const fakeResponse = '';
        let reponse = '';
        // tslint:disable-next-line:only-arrow-functions forin
        for (const key in resultats) {
          if (fakeResponse !== key) {
            reponse = '';
          }
          if (resultats[key].passed > resultats[key].failed  || resultats[key].passed < resultats[key].failed) {
            // tslint:disable-next-line:variable-name
            nResponseAutorised++;
            reponse = 'valide';
          }
          if (resultats[key].passed >= 1) {
            // tslint:disable-next-line:variable-name
            nResponseAutorised++;
            reponse = 'valide';
          } else {
            nResponseRefused++;
            reponse = 'refuse';
          }
          const responseAccess = {
            reponse,
            is_sub_cert: resultats[key].is_sub_cert,
            parent_certificat_id: resultats[key].parent_certificat_id,
            certificat_id: resultats[key].certificat_id,
          };
          this.reponseAutorisedRefusedAll.push(responseAccess);
        }
        let cpAnswer = 0;
        let cpAnswerSub = 0;
        const sizeAnswer = this.reponseAutorisedRefusedAll.length;
        let sizeAnswerSub = 0;
        const certParent = [];
        let cpAnswerNotSub = 0;
        this.reponseAutorisedRefusedAll.forEach((answer, rindex) => {
          // @ts-ignore
          if (answer.reponse === 'valide') {
            cpAnswer++;
          }
          if (answer.is_sub_cert === 'yes') {
            if (answer.reponse === 'valide') {
              cpAnswerSub++;
              certParent.push(answer.parent_certificat_id);
            }
            sizeAnswerSub++;
          }
          if (answer.is_sub_cert === 'no') {
            if (answer.reponse === 'valide') {
              cpAnswerNotSub++;
            }
          }
        });
        if (this.check_requiring === 'no' && this.sub_certifications === 0) {
          // tslint:disable-next-line:max-line-length
          if (this.cpt_cert_and_sub_requiring < this.cpt_comparaison_result || this.cpt_cert_and_sub_requiring > this.cpt_comparaison_result) {
            this.isAccepted = false;
          } else {
            this.isAccepted = true;
          }
        }
        if (sizeAnswerSub >= 1) {
          if (cpAnswerSub < sizeAnswerSub) {
            this.labelSousCertifcation = true;
          } else {
            this.labelSousCertifcation = false;
          }
          const rsponseConsideringSubCert = [];
          // Nouvelle size
          const newSize = sizeAnswer - sizeAnswerSub;
          let n = 0;
          if (cpAnswerSub === sizeAnswerSub) {
            const responseAccess = {
              reponse: 'valide',
              parent_certificat_id: certParent[0],
              certificat: 0,
            };
            rsponseConsideringSubCert.push(responseAccess);
          } else if (cpAnswerSub === 0 || cpAnswerSub > 0) {
            if (cpAnswer === sizeAnswer) {
              this.isAccepted = true;
            } else {
              this.isAccepted = false;
            }
          } else {
            const responseAccess = {
              reponse: 'refuse',
              parent_certificat_id: certParent[0],
              certificat: 0,
            };
            rsponseConsideringSubCert.push(responseAccess);
          }

          for (const rep of this.reponseAutorisedRefusedAll) {
            const data = {
              reponse : rep.reponse,
              parent_certificat_id: rep.parent_certificat_id,
              certificat_id : rep.certificat_id,
              testing : 'ok',
            };
            rsponseConsideringSubCert.push(data);
            n++;
            if (newSize === n) { break; }
          }
          let tempCertId = '';
          let certID = '';
          const FinalResponse = [];
          for (const rsp of rsponseConsideringSubCert) {
            if (rsp.parent_certificat_id.length === 0) {
              certID = rsp.certificat_id;
            }
            if (tempCertId === certID) {
              const data = {
                reponse : 'valide',
              };
              FinalResponse.push(data);
            } else {
              const data = {
                reponse : rsp.reponse,
              };
              FinalResponse.push(data);
            }
            tempCertId = rsp.parent_certificat_id;
          }
          let cpSousAnswer = 0;
          let cpSousAnswerFalse = 0;
          FinalResponse.forEach((answer, rindex) => {
            // @ts-ignore
            if (answer.reponse === 'valide') {
              cpSousAnswer++;
            } else {
              cpSousAnswerFalse++;
            }
          });
          if (this.sub_certifications > 0) {
            if (cpAnswer === 0) {
              this.isAccepted = false;
            }
            if (cpAnswerSub === this.sub_certifications) {
              //  this.isAccepted = true;
              this.labelSousCertifcation = false;
              if (this.certificationsSize === cpAnswerNotSub) {
                this.isAccepted = true;
              } else {
                this.isAccepted = false;
              }

              // tslint:disable-next-line:align
            } else {
              if (this.certificationsSize === cpAnswerNotSub) {
                this.isAccepted = true;
              } else {
                this.isAccepted = false;
              }
              this.labelSousCertifcation = true;
            }
          } else {
            this.isAccepted = false;
          }
        }  else {
          if (cpAnswer === sizeAnswer) {
            this.isAccepted = true;
          } else {

            this.isAccepted = false;
          }

          if (this.certificationsSize === cpAnswerNotSub) {
            this.isAccepted = true;
          } else {
            this.isAccepted = false;
          }
        }
        // tslint:disable-next-line:variable-name
        this.fake_pass = '';
        // tslint:disable-next-line:variable-name
        this.spinner.hide();
        this.show_result = true;
      }, (err) => {
        console.log(err);
      });
  }

  groupByCertificat(dataResult) {
    const distinctCount = {};
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < dataResult.length; i++) {
      const cert = dataResult[i];
      const certName = cert.certificat;
      // tslint:disable-next-line:variable-name
      const result_failed  = cert.result.failed;
      // tslint:disable-next-line:variable-name
      const result_passed = cert.result.passed;
      // tslint:disable-next-line:variable-name
      const result_code_cert = cert.code_cert;
      // tslint:disable-next-line:variable-name
      const result_is_sub_cert = cert.result.is_sub_cert;
      // tslint:disable-next-line:variable-name
      const result_parent_certificat_id = cert.parent_certificat_id;
      // tslint:disable-next-line:variable-name
      const result_certificat_id = cert.certificat_id;

      if (!distinctCount[certName]) {
        // tslint:disable-next-line:max-line-length
        distinctCount[certName] = { failed: result_failed, passed: result_passed, code_cert: result_code_cert, is_sub_cert: result_is_sub_cert, certificat_id: result_certificat_id, parent_certificat_id: result_parent_certificat_id};
      } else {
        distinctCount[certName].failed += result_failed;
        distinctCount[certName].passed += result_passed;
      }
    }
    return distinctCount;
  }

  numberOfNightsBetweenDates(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    let dayCount = 0;

    while (end > start) {
      dayCount++;
      start.setDate(start.getDate() + 1);
    }
    return dayCount;
  }

  // tslint:disable-next-line:variable-name
  addMontheToDate(vl_date, value) {
    // tslint:disable-next-line:prefer-const
    let date = moment(vl_date); // now
    const dateInMonths = date.add(value, 'months'); // now + 5 months
    return  dateInMonths.format('yyyy-MM-DD');
  }

  formatDateSimply(datte) {
    return formatDate(datte, 'yyyy-MM-dd', 'en');
  }

  todayDate() {
    return new Date();
  }

  displayDate(text) {
    const actualDate = new Date();
    return this.displaying = new Date(actualDate).setMonth(actualDate.getMonth() + text );


  }
  converttoTimeStamp(obtenaint, text) {
    const now = moment(obtenaint);
    const newdata = now.subtract(text, 'months').format();
    // return this.displaying = moment(newdata).format("X");
    return this.displaying = newdata;
  }

  reinitialiser() {
    this.router.navigate(['dashboard/inspector']);
  }

  modifier() {
    this.router.navigate(['dashboard/inspector/intervenant/status/11105/3'])
      .then(() => {
        window.location.reload();
      });
  }
  // tslint:disable-next-line:variable-name
  get_list_verification(id_site) {
    this.verificationService.getListVerificationbySiteId(id_site)
      .subscribe(res => {
        // @ts-ignore
        this.count_verification = res.data.length;
        // @ts-ignore
        this.verifications = res.data;
      }, (err) => {
        console.log(err);
      });
  }
  verificationChoice(event: any) {
    // tslint:disable-next-line:max-line-length
    this.router.navigate(['dashboard/inspector/intervenant/status/' + this.intervenant_find.id + '/' + event.target.value + '/' + this.verificateur_id])
      .then(() => {
        window.location.reload();
      });
  }

  // tslint:disable-next-line:variable-name
  returner(login_t) {
    this.spinner.show();
    const body = {
      login: login_t
    };
    // @ts-ignore
    this.userService.take_in_hand(body)
      .subscribe(res => {
        this.loginService.logout_take_in_hand();
        // @ts-ignore
        if (res.status === 'success') {
          // @ts-ignore
          localStorage.setItem('currentUser', JSON.stringify(res.user));
          localStorage.setItem('takeInHandOf', 'no');
          localStorage.setItem('connect_like_verificateur', 'no');
          // @ts-ignore
          localStorage.setItem('tokenCurrentConnected', JSON.stringify(res.token));
          // @ts-ignore
          this.loginService.currentUserSubject.next(res.user);
          // @ts-ignore
          localStorage.setItem('profile_id', res.profile_id);
          // @ts-ignore
          localStorage.setItem('site_id', res.site_id);
          // @ts-ignore
          if (res.profile_id === 10 || res.profile_id === 8 || res.profile_id === 13) {
            window.location.reload();
            this.router.navigate(['dashboard/inspector']);
            return false;
          }
          window.location.reload();
          this.router.navigate(['dashboard/administrator']);
          return false;
        }
        this.spinner.hide();
      }, (err) => {
        console.log(err);
      });
  }
  get_list_admin_verification() {
    this.spinner.show();
    this.verificationService.getListVerification()
      .subscribe(res => {
        // @ts-ignore
        this.count_verification = res.data.length;
        // @ts-ignore
        this.admin_verifications = res.data;
        this.spinner.hide();
      }, (err) => {
        console.log(err);
      });
  }

  checkExisting(value) {
    if (value.hasOwnProperty('code_cert')) {
      return true;
    } else {
      return false;
    }
  }

  checkObj(obj) {
    if (obj !== null || obj !== undefined) {
      return true;
    } else {
      return false;
    }
  }

  returnTypeOfValue(value) {
    return typeof value;
  }
}

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"  >
  <p style="font-size: 20px; color: white">Affichage du resultat en cours...</p>
</ngx-spinner>
<section id="front_wrap">
  <section id="front_page" class="profile_step">
    <section id="menu_front">
      <span class="badge badge-danger" *ngIf="takeInHand && takeInHand === 'yes'">
        <h6 style="color: #ffffff;font-size: 12px;">
        <span class="bx bx-info-circle"></span> Prise en main en cours <br>de ce compte
       </h6>
         <button type="button" class="btn btn-primary waves-effect waves-light" (click)="returner(admin_login)">
            Retour au compte précédent
          </button>
      </span>
      <a class="menu_item menu_search current" href="dashboard/inspector"><img src="assets/img/icon_search.png"> <p>Recherche</p></a>
      <a class="menu_item menu_profile" href="dashboard/inspector/profile"><img src="assets/img/icon_profile.png"> <p>Profil</p></a>
      <a href="" (click)="logout_user()" class="menu_item menu_logout"><img src="assets/img/icon_logout.png"> <p>Déconnexion</p></a>
    </section>
    <section id="big_front">
      <div id="name_connected">
        <p>{{single_user.name}}</p>
        <img src="{{single_user.photo}}">
      </div>
      <div id="content_front" *ngIf="show_result">

        <form>
          <p class="h1 search_result_title">Résultat de votre recherche pour la vérification<br>"{{single_verification.name}}"</p>
          <div class="row" >

            <div class="col-sm-12">
              <img src="{{this.intervenant_find.photo}}" class="rounded-circle" height="30" style="height: 30px; width: 30px;margin-top: 10px;">
              <p class="h3"><b>{{intervenant_find.firstname}} {{intervenant_find.name}}</b></p>
              <p class="h6" style="margin-top: 10px">{{intervenant_find.society ? intervenant_find.society : 'Société non défini' }}</p>
              <p class="h1">
                <ng-container *ngIf="certifications?.length === 0">
                  <span class="badge badge-pill badge-danger" style="background-color: red; margin-top: 10px"><i class="bx bxs-x-square"></i> Refusé</span>
                </ng-container>
                <!--  {{sucessArrays.length}}
                  {{isAccepted}}-->
                <ng-container *ngIf="isAccepted && sucessArrays.length  > 0">
                  <span class="badge badge-pill badge-success" style="background-color: #3aef8d; margin-top: 10px"><i class="bx bx-check-circle"></i>Accepté</span>
                </ng-container>
                <!--  <ng-container *ngIf="!isAccepted">
                    <span class="badge badge-pill badge-danger" style="background-color: red; margin-top: 10px"><i class="bx bxs-x-square"></i> Refusé</span>
                  </ng-container>-->
                <ng-container *ngIf="!isAccepted || sucessArrays.length === 0">
                  <span class="badge badge-pill badge-danger" style="background-color: red; margin-top: 10px"><i class="bx bxs-x-square"></i> Refusé</span>
                </ng-container>

              </p>
              <div class="container-fluid mobile_container">
                <div class="row head_row text-left">
                  <p class="only_mobile details_tt">Détails</p>
                  <p class="only_mobile legende_tt">Légende :</p>
                  <div class="col-md-3 col-lg-6 tt_nom_certif">
                    <i class="bx bxs-check-shield"></i> Nom de la certification
                  </div>
                  <div class="col-md-3 col-lg-2 tt_date_certif">
                    <i class="bx bx-calendar"></i> Date d'obtention
                  </div>
                  <div class="col-md-3 col-lg-2 tt_date_end_certif">
                    <i class="bx bx-calendar"></i> Date de fin de validité
                  </div>
                  <div class="col-md-3 col-lg-2 tt_valid_certif">
                    <i class="bx bx-info-circle"></i> Validité
                  </div>
                </div>
                <div class="row margin_in_row">
                  <div class="col-lg-12">
                    <div class="alert alert-danger" role="alert" *ngIf="certifications && certifications?.length === 0">
                      <p style="text-align: center; margin:20px 0 20px 0;">Cet intervenant ne possède aucun certificat pour la(les) vérification(s) sélectionnée(s) !</p>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="alert alert-danger" role="alert" *ngIf="sucessArrays && sucessArrays.length === 0">
                      <p style="text-align: center; margin:20px 0 20px 0;"> Cet intervenant n'a pas suivi de cours pour valider les certifications ci-dessous</p>
                    </div>
                  </div>
                  <!--<div class="col-lg-12">
                      <div class="alert alert-info" role="alert" *ngIf="certifications_list.length > sucessArrays.length && sucessArrays.length !== 0">
                        <p style="text-align: center; margin:0 0 0 0;"> Cet intervenant a passé que {{sucessArrays.length}}/{{certifications_list.length}} certifications demandées pour cette vérification</p>
                      </div>
                  </div>-->
                </div>
                <div class="row list_scroll">
                  <!-- <ul class="col-lg-12"> -->
                  <div class="row text-left certif_wrap" *ngFor="let certification of certificats; let i = index;">
                    <ng-container *ngIf="certification.code_cert.substring(0,3)==='CK1'">
                      <div class="col-lg-12 margin_col_12 certif_list">
                        <div class="label_cert">
                          {{certification.name}}
                          <span class="alert-danger">{{certification.is_register === false ? 'Non inscrit ou non commencé':''}}</span>

                        </div>
                        <ng-container *ngFor="let sucessArray of sucessArrays; let k = index">
                          <ng-container *ngIf="sucessArray.code_cert === certification.code_cert">
                            <span class="col_name col-md-3 col-lg-6">{{sucessArray.title}}</span>

                            <span class="col_date col-md-3 col-lg-2">
                                    <ng-container *ngIf="sucessArray.date_dernier_passage==='1970-01-01 00:00:00'"><span class="col_valid not_attempted"><i class="bx bx-info-circle"></i> NC</span></ng-container>
                                    <ng-container *ngIf="sucessArray.date_dernier_passage!=='1970-01-01 00:00:00'">{{sucessArray.date_dernier_passage | date: 'dd/MM/yyyy'}}</ng-container>
                            </span>
                            <span class="col_end_date col-md-3 col-lg-2">
                                    <ng-container *ngIf="sucessArray.end_date_validite==='1970-01-01 00:00:00'"><span class="col_valid not_attempted"><i class="bx bx-info-circle"></i> NC</span></ng-container>
                              <ng-container *ngIf="sucessArray.end_date_validite!=='1970-01-01 00:00:00'">{{sucessArray.end_date_validite | date: 'dd/MM/yyyy'}}</ng-container>
                            </span>
                            <ng-container *ngIf="sucessArray.code_cert === certification.code_cert">
                              <ng-container *ngIf="sucessArray.success==='yes'">
                                <span class="col_valid valid col-md-3 col-lg-2"><i class="bx bx-check-circle"></i> Valide</span>
                              </ng-container>
                              <ng-container *ngIf="sucessArray.failed==='yes'">
                                      <span class="col_valid no_valid col-md-3 col-lg-2"> <i class="bx bxs-x-square"></i> Non valide
                                        <ng-container *ngIf="sucessArray.expired === 'yes'"> (expiré)</ng-container>
                                        <ng-container *ngIf="sucessArray.incomplete === 'yes'"> (non terminé)</ng-container>
                                      </span>
                              </ng-container>
                              <ng-container *ngIf="sucessArray.notattempted==='yes'">
                                <span class="col_valid not_attempted"><i class="bx bx-info-circle"></i> NC</span>
                              </ng-container>
                            </ng-container>

                          </ng-container>

                          <ng-container *ngIf="sucessArray.parent_certificat_id !=='' && sucessArray.parent_certificat_id ===certification.id">
                            <ng-container *ngIf="sucessArray.code_cert.substring(0,3)==='CK2'">
                              <ng-container *ngIf="sucessArray.code_cert===FirstCK2">
                                <span class="label_ss_cert">Sous-certifications
                                  <ng-container *ngIf="labelSousCertifcation"><span class="badge badge-pill badge-danger" style="background-color: red; margin-top: 10px">Non valide</span></ng-container>
                                  <ng-container *ngIf="!labelSousCertifcation"><span class="badge badge-pill badge-success" style="background-color: #3aef8d; margin-top: 10px">Valide</span></ng-container> :
                                </span>
                              </ng-container>
                            </ng-container>
                            <div class="ss_certif_one">
                              <span class="col_name">{{sucessArray.sub_cert_name}} ({{sucessArray.title}})</span>
                              <span class="col_date">
                                <ng-container *ngIf="sucessArray.success==='yes' || sucessArray.expired === 'yes'">
                                    <ng-container *ngIf="sucessArray.date_dernier_passage==='1970-01-01 00:00:00'"><span class="col_valid not_attempted"><i class="bx bx-info-circle"></i> NC</span></ng-container>
                                    <ng-container *ngIf="sucessArray.date_dernier_passage!=='1970-01-01 00:00:00'">{{sucessArray.date_dernier_passage | date: 'dd/MM/yyyy'}}</ng-container>
                               </ng-container>
                                </span>
                              <ng-container *ngIf="sucessArray.success==='yes'">
                                <span class="col_valid valid col-md-3 col-lg-2"><i class="bx bx-check-circle"></i> Valide</span>
                              </ng-container>
                              <ng-container *ngIf="sucessArray.failed==='yes'">
                                      <span class="col_valid no_valid col-md-3 col-lg-2"> <i class="bx bxs-x-square"></i> Non valide
                                        <ng-container *ngIf="sucessArray.expired === 'yes'"> (expiré)</ng-container>
                                        <ng-container *ngIf="sucessArray.incomplete === 'yes'"> (non terminé)</ng-container>
                                      </span>
                              </ng-container>
                              <ng-container *ngIf="sucessArray.notattempted==='yes'">
                                <span class="col_valid not_attempted"><i class="bx bx-info-circle"></i> NC</span>
                              </ng-container>
                            </div>
                          </ng-container>

                        </ng-container>
                      </div>
                    </ng-container>
                  </div>
                  <!-- </ul> -->
                </div>
              </div>

              <div class="wrap">
                <div class="inner_table">
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="button_result">
          <button style="width: 150px;" class="blue_btn reinit_btn"  type="buuton" color="accent" (click)="reinitialiser()"><i class="fa fa-recycle"></i> Réinitialiser</button>
          <button style="width: 150px;" class="blue_btn"  type="buuton" color="accent" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample"><i class="fa fa-edit"></i> Modifier</button>
        </div>
        <div class="collapse" id="collapseExample">
          <div class="card card-body">
            <ng-container *ngIf="profile_id === 11">
              <select class="form-control verification"  [(ngModel)]="verification_id" [ngModelOptions]="{standalone: true}" (change)="verificationChoice($event)">
                <option>Veuillez sélectionner une vérification</option>
                <option *ngFor="let verification of admin_verifications" [value]="verification.id" selected>{{verification.name}}</option>
              </select>
            </ng-container>
            <ng-container *ngIf="profile_id === 8 || profile_id === 2 || profile_id ===4 || profile_id ===5 || profile_id === 6 || profile_id ===9 || profile_id === 1">
              <select class="form-control verification"  [(ngModel)]="verification_id" [ngModelOptions]="{standalone: true}" (change)="verificationChoice($event)">
                <option>Veuillez sélectionner une vérification</option>
                <option *ngFor="let verification of verifications" [value]="verification.id" selected>{{verification.name}}</option>
              </select>
            </ng-container>

          </div>
        </div>
      </div>

      <div id="bottom_logo">
        <img src="assets/img/logo-kaptitude.png">
      </div>
    </section>
  </section>
  <div id="front_legal">
    <p>CERTIKAP - par <strong>Kaptitude</strong> - 2020</p>
  </div>
</section>

import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {SubCertificationService} from '../../../../../services/sub-certification.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CertificationService} from '../../../../../services/certification.service';
import {UserService} from '../../../../../services/user.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-verification-souscertification',
  templateUrl: './verification-souscertification.component.html',
  styleUrls: ['./verification-souscertification.component.css']
})
export class VerificationSouscertificationComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  count_verif: any;
  // tslint:disable-next-line:variable-name
  list_verifications: any;
  // tslint:disable-next-line:variable-name
  public profile_id = parseInt(localStorage.getItem('profile_id'));
  // tslint:disable-next-line:variable-name
  public sous_cert_id = this.activatedRoute.snapshot.url[4].path;
  constructor(private subCertificationService: SubCertificationService, private activatedRoute: ActivatedRoute, private certificationService: CertificationService, private userService: UserService, private spinner: NgxSpinnerService, private el: ElementRef, private renderer: Renderer2, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.get_list_verification_sous_certification(this.sous_cert_id);
  }
  // tslint:disable-next-line:variable-name
  get_list_verification_sous_certification(sous_cert_id) {
    this.spinner.show();
    this.subCertificationService.getListVerificationForSubCErt(sous_cert_id)
      .subscribe(res => {
        // @ts-ignore
        this.count_verif = res.data.length;
        // @ts-ignore
        this.list_verifications = res.data;
        this.spinner.hide();
      }, (err) => {
        console.log(err);
        this.spinner.hide();
      });
  }

}

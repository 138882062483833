import { Component, OnInit } from '@angular/core';
import {ChoiceScreenService} from "../../../../services/choice-screen.service";
import {ChoiceScreen} from "../../../../models/choice-screen.model";
import {ChoiceScreenGroupes} from "../../../../models/choice-screen-groupes.model";
import Swal from "sweetalert2";
import {ActivatedRoute, Router} from "@angular/router";
@Component({
  selector: 'app-choice-screen',
  templateUrl: './list-choice-screen.component.html',
  styleUrls: ['./list-choice-screen.component.css']
})
export class ListChoiceScreenComponent implements OnInit {

  public user_conntected = JSON.parse(localStorage.getItem('currentUser'));
  // tslint:disable-next-line:variable-name
  public profile_id = this.user_conntected.profile_id;

  //delete ChoiceScreen
  responseMessage : string;

  constructor(private choiceScreenService: ChoiceScreenService,
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {
    if(this.profile_id ==11){
      this.getAllScreenChoice();
    }
  }

  AllChoiceScreen: ChoiceScreen[] = [];
  ChoiceScreenGroupe : ChoiceScreenGroupes[] = [];

  getAllScreenChoice() {
    this.choiceScreenService.getAllChoiceScreen()
      .subscribe(res => {
        this.AllChoiceScreen = res.choiceScreen
      }, (err) => {
        console.log(err);
      });
  }

  // tslint:disable-next-line:variable-name
  update(id) {
    this.router.navigate(['/dashboard/administrator/choice_screen/edit/' + id], { relativeTo: this.route });
  }
// tslint:disable-next-line:variable-name
  delete(id) {
    Swal.fire({
      title: 'Etes-vous sûr(e) ?',
      text: 'Cette action est irréversible',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui supprimer',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.value) {
        // @ts-ignore
        this.choiceScreenService.deleteChoiceScreen(id)
          .subscribe(res => {
            this.responseMessage = res.message;
            Swal.fire(
              'Supprimé !',
              'Ecran de choix supprimé avec succès !',
              'success'
            ).then(() => {
              window.location.reload();
              this.getAllScreenChoice();
            });
          }, (err) => {
            Swal.fire(
              'Erreur',
              'Une erreur s\'est produite lors de la suppression',
              'error'
            );
          });
      }
    });
  }

}

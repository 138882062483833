<app-header></app-header>
<app-sidebar></app-sidebar>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"  >
  <p style="font-size: 20px; color: white">Chargement de la liste en cours...</p>
</ngx-spinner>
<div class="main-content">
  <div class="page-content">
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-flex align-items-center justify-content-between">
            <h4 class="mb-0 font-size-18">
              <i class="bx bx-home-circle"></i>  Bienvenue dans votre espace
              <app-fildariane></app-fildariane>
            </h4>
            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item"><a href="javascript: void(0);">Accueil</a></li>
                <li class="breadcrumb-item active">Gestion des Ecrans de choix
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->
      <div class="row">
        <div class="col-lg-2" style="margin-bottom: 20px">
          <a href="/dashboard/administrator/choice_screen/create">
            <button type="button" class="btn btn-primary waves-effect waves-light">
              <i class="bx bx-plus font-size-16 align-middle mr-2"></i> Ajouter
            </button></a>
        </div>
      </div>
      <br>

      <div class="row">
        <div class="col-12">
          <div id="accordion">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">LISTE DES ECRANS DE CHOIX ({{AllChoiceScreen.length}})</h4>
                <div class="table-responsive">
                  <table class="table mb-0">
                    <thead>
                    <tr>
                      <th>Titre</th>
                      <th>Description</th>
                      <th>Groupe d'entré</th>
                      <th>Groupe choix associé</th>
                      <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let choiceScreen of AllChoiceScreen">
                      <td scope="row">
                        {{ choiceScreen.titre }}
                      </td>
                      <td>
                        {{ choiceScreen.description }}
                      </td>
                      <td>
                        {{ choiceScreen.groupe.name }}
                      </td>
                      <td>
                        <ng-container *ngFor="let group of choiceScreen.groupes">
                          {{ group.name }},
                        </ng-container>
                      </td>
                      <td>
                        <i class="bx bx-edit pointer" (click)="update(choiceScreen.id)">Editer /</i>
                        <i class="bx bx-trash-alt pointer" (click)="delete(choiceScreen.id)">Supprimer /</i>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
        <!-- end row -->
      </div>
      <!-- container-fluid -->
    </div>
    <!-- End Page-content -->
    <app-footer></app-footer>
  </div>
</div>


import {Component, Input, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {LoginService} from 'src/app/services/login.service';
import {Router} from '@angular/router';
import {UserService} from 'src/app/services/user.service';
import Swal from "sweetalert2";
import {ConfigService} from "src/app/services/config.service";

@Component({
  selector: 'app-sidebarIP',
  templateUrl: './sidebarIP.component.html',
  styleUrls: ['./sidebarIP.component.css']
})
export class SidebarIPComponent implements OnInit {
  // tslint:disable-next-line:max-line-length
  public groups: any;
  // tslint:disable-next-line:max-line-length variable-name
  is_able_to_verify: boolean;
  constructor(private spinner: NgxSpinnerService, private loginService: LoginService, private userService: UserService,  private router: Router, private configService: ConfigService) { }
  // tslint:disable-next-line:variable-name
  public user_conntected = JSON.parse(localStorage.getItem('currentUser'));
  // tslint:disable-next-line:variable-name radix
  public profile_id = parseInt(localStorage.getItem('profile_id'));
  // tslint:disable-next-line:variable-name
  public connected_id = this.user_conntected.id;
  // tslint:disable-next-line:max-line-length variable-name
  public admin_login = this.user_conntected.login;
  // tslint:disable-next-line:variable-name
  public admin_email = this.user_conntected.email;
  public takeInHand = localStorage.getItem('takeInHand');
  // tslint:disable-next-line:variable-name
  showLoadConnection = false;
  @Input() sidebarStyle: any;

  ngOnInit() {
  }

  verificateur_view(value) {
    this.showLoadConnection = true;
    this.spinner.show();
    const body = {
      login: value,
      email: this.admin_email
    };
    // @ts-ignore
    this.userService.take_in_hand(body)
      .subscribe(res => {
        this.loginService.logout_take_in_hand();
        // @ts-ignore
        if (res.status === 'success') {
          // @ts-ignore
          localStorage.setItem('currentUser', JSON.stringify(res.user));
          localStorage.setItem('takeInHand', 'yes');
          localStorage.setItem('connect_like_verificateur', 'yes');
          localStorage.setItem('admin_login', this.admin_login);
          // @ts-ignore
          localStorage.setItem('tokenCurrentConnected', JSON.stringify(res.token));
          // @ts-ignore
          this.loginService.currentUserSubject.next(res.user);
          // @ts-ignore
          localStorage.setItem('profile_id', res.profile_id);
          // @ts-ignore
          localStorage.setItem('site_id', res.site_id);
          window.location.reload();
          this.router.navigate(['dashboard/inspector']);
          return false;
        }
        this.spinner.hide();
      }, (err) => {
        console.log(err);
      });
  }
  // tslint:disable-next-line:variable-name
  get_list_group(id_user) {
    this.userService.get_user_group_list(id_user)
      .subscribe(res => {
        this.groups = res;
        for (const group of this.groups) {
          if (group === 1948) {
            this.is_able_to_verify = true;
            return;
          }
        }
      }, (err) => {
        console.log(err);
      });
  }

  sso_certikap() {
    this.spinner.show();
    const body = {
      // ssoKey : 'h-kaptitude'
      Id : this.user_conntected.id,
      ssoActive : this.user_conntected.active,
      ssoKey : this.user_conntected.login
    };
    this.userService.sso_connexion_direct_to_talensoft(body)
        .subscribe(res => {
          // @ts-ignore
          if (res.sso_data === '') {
            this.spinner.hide();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Votre compte est inconnu sur Talensoft, veuillez contacter l\'administrateur pour plus détails.',
              footer: '<a href>Equipe Kaptitude</a>'
            });
            return false;
          }
          this.spinner.hide();
          // @ts-ignore
          window.location.replace(this.configService.ELMS_url + 'sso.php?skey=' + res.sso_data);

        }, (err) => {
          console.log(err);
        });
  }

  sso_active() {
    this.spinner.show();
    const body = {
      // ssoKey : 'h-kaptitude'
      Id : this.user_conntected.user_id,
      ssoActive : this.user_conntected.active,
      user_login : this.user_conntected.login
    };
    this.userService.sso_connexion_active(body)
      .subscribe(res => {
        // @ts-ignore
        if (res.sso_data === '') {
          this.spinner.hide();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Votre compte est inconnu sur Talensoft, veuillez contacter l\'administrateur pour plus détails.',
            footer: '<a href>Equipe Kaptitude</a>'
          });
          return false;
        }
        this.spinner.hide();
        // @ts-ignore
        window.location.replace(this.configService.ELMS_url + 'sso.php?skey=' + res.sso_data);
      }, (err) => {
        console.log(err);
      });
  }

  viewCertificats(id) {
    if(!id){
      id = this.user_conntected.user_id;
    }
    this.router.navigate(['dashboard/administrator/apprenant/certificats/' + id]);
  }

  viewCatalogue() {
    this.router.navigate(['dashboard/administrator/apprenant/catalogue-list']);
  }


}

// src/app/guards/entity.guard.ts
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EntityGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const requiredEntityType = next.data['requiredEntityType'] as string;

    if (environment.entityType === requiredEntityType) {
      // Autoriser l'accès pour l'entité correspondante
      return true;
    } else {
      // Redirection vers une page d'accès refusé
      this.router.navigate(['/**']);
      return false;
    }
  }
}

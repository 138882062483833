/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../EKAMPUS/dashboard/template/header/headerEkampus.component.ngfactory";
import * as i3 from "../../../EKAMPUS/dashboard/template/header/headerEkampus.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/platform-browser";
import * as i6 from "@angular/router";
import * as i7 from "../../../services/login.service";
import * as i8 from "ngx-spinner";
import * as i9 from "../../../services/user.service";
import * as i10 from "../../../services/config.service";
import * as i11 from "../../../IP/dashboard/template/header/headerIP.component.ngfactory";
import * as i12 from "../../../IP/dashboard/template/header/headerIP.component";
import * as i13 from "@angular/common";
import * as i14 from "./header.component";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
function View_HeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-headerEkampus", [], null, null, null, i2.View_HeaderEkampusComponent_0, i2.RenderType_HeaderEkampusComponent)), i1.ɵdid(2, 114688, null, 0, i3.HeaderEkampusComponent, [i4.TranslateService, i5.Title, i6.ActivatedRoute, i6.Router, i7.LoginService, i8.NgxSpinnerService, i9.UserService, i10.ConfigService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_HeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-headerIP", [], null, null, null, i11.View_HeaderIPComponent_0, i11.RenderType_HeaderIPComponent)), i1.ɵdid(2, 114688, null, 0, i12.HeaderIPComponent, [i4.TranslateService, i5.Title, i6.ActivatedRoute, i6.Router, i7.LoginService, i8.NgxSpinnerService, i9.UserService, i10.ConfigService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_1)), i1.ɵdid(1, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_2)), i1.ɵdid(3, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.entityType == "EKAMPUS"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.entityType == "IP"); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i14.HeaderComponent, [i10.ConfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i1.ɵccf("app-header", i14.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };

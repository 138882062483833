import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './pages/login/login.component';
import {InspectorComponent} from './dashboard/inspector/inspector.component';
import {ProfileComponent} from './dashboard/inspector/profile/profile.component';
import {InspectorExportComponent} from './dashboard/inspector/inspector-export/inspector-export.component';
import {AdministratorComponent} from './dashboard/administrator/administrator.component';
import {PageNonTrouveeComponent} from './pages/page-non-trouvee/page-non-trouvee.component';
import {AdminProfileComponent} from './dashboard/administrator/admin-profile/admin-profile.component';
import {ClientComponent} from './dashboard/administrator/client/client.component';
import {UserComponent} from './dashboard/administrator/user/user.component';
import {SuperviseurComponent} from './dashboard/administrator/superviseur/superviseur.component';
import {EditUserComponent} from './dashboard/administrator/user/edit-user/edit-user.component';
import {VerificateurComponent} from './dashboard/administrator/verificateur/verificateur.component';
import {IntervenantComponent} from './dashboard/administrator/intervenant/intervenant.component';
import {ResultIntervenantComponent} from './dashboard/inspector/result-intervenant/result-intervenant.component';
import {ForgetComponent} from './pages/forget/forget.component';
import {NewPasswortComponent} from './pages/new-passport/new-passwort.component';
import { CertificationComponent } from './dashboard/administrator/certification/certification.component';
import { VerificationComponent } from './dashboard/administrator/verification/verification.component';
import { AddCertificationComponent } from './dashboard/administrator/certification/add-certification/add-certification.component';
import { EditCertificationComponent } from './dashboard/administrator/certification/edit-certification/edit-certification.component';
import { AddVerificationComponent } from './dashboard/administrator/verification/add-verification/add-verification.component';
import {SousCertificationComponent} from './dashboard/administrator/certification/sous-certification/sous-certification.component';
// tslint:disable-next-line:max-line-length
import {EditSousCertificationComponent} from './dashboard/administrator/certification/sous-certification/edit-sous-certification/edit-sous-certification.component';
import {EditVerificationComponent} from './dashboard/administrator/verification/edit-verification/edit-verification.component';
import {SitesCertificationComponent} from './dashboard/administrator/certification/sites-certification/sites-certification.component';
// tslint:disable-next-line:max-line-length
import {VerificationCertificationComponent} from './dashboard/administrator/certification/verification-certification/verification-certification.component';
import {ExportComponent} from './dashboard/administrator/export/export.component';
// tslint:disable-next-line:max-line-length
import {SiteSouscertificationComponent} from './dashboard/administrator/certification/sous-certification/site-souscertification/site-souscertification.component';
// tslint:disable-next-line:max-line-length
import {VerificationSouscertificationComponent} from './dashboard/administrator/certification/sous-certification/verification-souscertification/verification-souscertification.component';
import {SsoConnectionComponent} from './pages/sso-connection/sso-connection.component';
import {CertificatsComponent} from './dashboard/administrator/intervenant/certificats/certificats.component';
import {IndexFormComponent} from "./dashboard/administrator/form/index-form/index-form.component";
import {CreateFormComponent} from "./dashboard/administrator/form/create-form/create-form.component";
import {AutoInscriptionComponent} from "./dashboard/administrator/form/auto-inscription/auto-inscription.component";
import {AutoInscriptionStartComponent} from "./dashboard/administrator/form/auto-inscription-start/auto-inscription-start.component";
import {EditFormComponent} from "./dashboard/administrator/form/edit-form/edit-form.component";
import {ListChoiceScreenComponent} from "./dashboard/administrator/choice-screen/list-choice-screen/list-choice-screen.component";
import {EditChoiceScreenComponent} from "./dashboard/administrator/choice-screen/edit-choice-screen/edit-choice-screen.component";
import {CreateChoiceScreenComponent} from "./dashboard/administrator/choice-screen/create-choice-screen/create-choice-screen.component";
import {EntityGuard} from "./guards/entity.guard";

//IP
import {CatalogManagementComponent} from "./dashboard/administrator/catalog-management/catalog-management.component";
import {CatalogListComponent} from "./dashboard/administrator/catalog-list/catalog-list.component";
import {CreateCatalogManagementComponent} from "./dashboard/administrator/catalog-management/create-catalog-management/create-catalog-management.component";
import {EditCatalogManagementComponent} from "./dashboard/administrator/catalog-management/edit-catalog-management/edit-catalog-management.component";
import {CreateChapitreComponent} from "./dashboard/administrator/catalog-management/chapitre/create-chapitre/create-chapitre.component";
import {EditChapitreComponent} from "./dashboard/administrator/catalog-management/chapitre/edit-chapitre/edit-chapitre.component";



const routes: Routes = [
  {path: '', component : LoginComponent},
  {path: 'loginwithtoken/:id/:token', component : LoginComponent},
  {path: 'dashboard/inspector', component : InspectorComponent},
  {path: 'dashboard/inspector/profile', component : ProfileComponent},
  {path: 'dashboard/inspector/intervenant/status/:id/:verfication_id/:verificateur_id', component : ResultIntervenantComponent},
  {path: 'dashboard/administrator', component : AdministratorComponent},
  {path: 'dashboard/administrator/profile', component : AdminProfileComponent},
  {path: 'dashboard/administrator/clients', component : ClientComponent},
  {path: 'dashboard/administrator/superviseurs', component : SuperviseurComponent},
  {path: 'dashboard/administrator/verificateurs', component : VerificateurComponent},
  {path: 'dashboard/administrator/intervenants', component : IntervenantComponent},
  {path: 'dashboard/administrator/utilisateurs', component : UserComponent},
  {path: 'dashboard/administrator/utilisateurs/editer/:id', component : EditUserComponent},
  {path: 'dashboard/administrator/certifications', component : CertificationComponent},
  {path: 'dashboard/administrator/certifications/site/:id', component : SitesCertificationComponent},
  {path: 'dashboard/administrator/certifications/verification/:id', component : VerificationCertificationComponent},
  {path: 'dashboard/administrator/sous-certifications', component : SousCertificationComponent},
  {path: 'dashboard/administrator/sous-certifications/sites/:id', component : SiteSouscertificationComponent},
  {path: 'dashboard/administrator/sous-certifications/verifications/:id', component : VerificationSouscertificationComponent},
  {path: 'dashboard/administrator/sous-certifications/editer/:id', component : EditSousCertificationComponent},
  {path: 'dashboard/administrator/certification/ajouter', component : AddCertificationComponent},
  {path: 'dashboard/administrator/certification/editer/:id', component : EditCertificationComponent},
  {path: 'dashboard/administrator/verifications', component : VerificationComponent},
  {path: 'dashboard/administrator/verification/editer/:id', component : EditVerificationComponent},
  {path: 'dashboard/administrator/verification/ajouter', component : AddVerificationComponent},
  {path: 'dashboard/administrator/exports', component : ExportComponent},
  {path: 'password/forget', component : ForgetComponent},
  {path: 'password/forget/:details', component : NewPasswortComponent},
  {path: 'v1/login/sso_connection/:user_id/:user_token', component : SsoConnectionComponent},
  {path: 'dashboard/administrator/choice_screen', component : ListChoiceScreenComponent},
  {path: 'dashboard/administrator/choice_screen/edit/:id', component : EditChoiceScreenComponent},
  {path: 'dashboard/administrator/choice_screen/create', component : CreateChoiceScreenComponent},

  {path: 'dashboard/administrator/apprenant/certificats', component : CertificatsComponent},
  {path: 'dashboard/administrator/apprenant/certificats/:id', component : CertificatsComponent},
  {path: 'dashboard/inspector/inspector-export', component : InspectorExportComponent},

  //ONLY EKAMPUS
  {path: 'dashboard/administrator/formulaire', component : IndexFormComponent, canActivate: [EntityGuard], data: { requiredEntityType: 'EKAMPUS' }},
  {path: 'dashboard/administrator/formulaire/create', component : CreateFormComponent, canActivate: [EntityGuard], data: { requiredEntityType: 'EKAMPUS' }},
  {path: 'dashboard/administrator/formulaire/editer/:id', component : EditFormComponent, canActivate: [EntityGuard], data: { requiredEntityType: 'EKAMPUS' }},
  {path: 'v1/form/auto-inscription', component : AutoInscriptionComponent, canActivate: [EntityGuard], data: { requiredEntityType: 'EKAMPUS' }},
  {path: 'v1/form/auto-inscription/:details', component : AutoInscriptionStartComponent, canActivate: [EntityGuard], data: { requiredEntityType: 'EKAMPUS' }},

  //ONLY IP
  {path: 'dashboard/administrator/catalogue-management', component : CatalogManagementComponent, canActivate: [EntityGuard], data: { requiredEntityType: 'IP' }},
  {path: 'dashboard/administrator/catalogue-management/create', component : CreateCatalogManagementComponent, canActivate: [EntityGuard], data: { requiredEntityType: 'IP' }},
  {path: 'dashboard/administrator/catalogue-management/edit/:id', component : EditCatalogManagementComponent, canActivate: [EntityGuard], data: { requiredEntityType: 'IP' }},
  {path: 'dashboard/administrator/catalogue-chapitre/:id_catalogue/create', component : CreateChapitreComponent, canActivate: [EntityGuard], data: { requiredEntityType: 'IP' }},
  {path: 'dashboard/administrator/catalogue-chapitre/:id_catalogue/edit/:id', component : EditChapitreComponent, canActivate: [EntityGuard], data: { requiredEntityType: 'IP' }},
  {path: 'dashboard/administrator/apprenant/catalogue-list/:langue', component : CatalogListComponent, canActivate: [EntityGuard], data: { requiredEntityType: 'IP' }},


  //RETOUR ERREUR
  { path: '**', component: PageNonTrouveeComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import {User} from '../models/user.model';
import {ConfigService} from './config.service';
import {Observable, throwError} from 'rxjs';
// @ts-ignore
const token = JSON.parse(localStorage.getItem('tokenCurrentConnected'));
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'}),
};

@Injectable({
  providedIn: 'root'
})
export class ExportationService {

  constructor(private http: HttpClient, private config: ConfigService) { }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error}, ` +
        `body was: ${error.error}`);
    }
    console.log(error);
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  private extractData(res: Response) {
    const body = res;
    return body || { };
  }
  exportation(data): Observable<any> {
    return this.http.post(this.config.urlDEV + '/talensoft/exportation', data, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }
}

import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../../services/config.service';



@Component({
  selector: 'app-forget',
  templateUrl: './forget.component.html',
  styleUrls: ['./forget.component.css']
})
export class ForgetComponent implements OnInit {

  // tslint:disable-next-line:max-line-length
  constructor(private configService: ConfigService) {
  }
  public entityType = this.configService.entityType;

  ngOnInit() {
  }


}

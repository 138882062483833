import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {VerificationService} from '../../../../../services/verification.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CertificationService} from '../../../../../services/certification.service';
import {UserService} from '../../../../../services/user.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {SubCertificationService} from '../../../../../services/sub-certification.service';

@Component({
  selector: 'app-site-souscertification',
  templateUrl: './site-souscertification.component.html',
  styleUrls: ['./site-souscertification.component.css']
})
export class SiteSouscertificationComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  public profile_id = parseInt(localStorage.getItem('profile_id'));
  // tslint:disable-next-line:variable-name
  list_site: any;
  // tslint:disable-next-line:variable-name
  count_site: any;
  // tslint:disable-next-line:variable-name
  public sous_cert_id = this.activatedRoute.snapshot.url[4].path;
  // tslint:disable-next-line:max-line-length
  constructor(private subCertificationService: SubCertificationService, private activatedRoute: ActivatedRoute, private certificationService: CertificationService, private userService: UserService, private spinner: NgxSpinnerService, private el: ElementRef, private renderer: Renderer2, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.get_list_site_sous_certification(this.sous_cert_id);
  }
  // tslint:disable-next-line:variable-name
  get_list_site_sous_certification(sous_cert_id) {
    this.spinner.show();
    this.subCertificationService.getListSiteForSubCErt(sous_cert_id)
      .subscribe(res => {
        // @ts-ignore
        this.count_site = res.data.length;
        // @ts-ignore
        this.list_site = res.data;
        this.spinner.hide();
      }, (err) => {
        console.log(err);
        this.spinner.hide();
      });
  }
}

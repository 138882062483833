<app-header></app-header>
<app-sidebar></app-sidebar>
<div class="main-content">
  <div class="page-content">
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-flex align-items-center justify-content-between">
            <h4 class="mb-0 font-size-18">
              <i class="bx bx-home-circle"></i>  Bienvenue dans votre espace
              <!--<app-fildariane></app-fildariane> -->Certikap
            </h4>
            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item"><a href="javascript: void(0);">Accueil</a></li>
                <li class="breadcrumb-item active">Création d'un nouveau catalogue
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->
      <div class="row" style="padding-bottom: 5px">
        <a href="javascript:window.history.back();" class="btn btn-primary waves-effect waves-light btn-sm mb-3"><i class="mdi mdi-chevron-left ml-1"></i> Retour </a>
      </div>
      <div class="row">
        <div class="card col-12">
          <div class="card-body">
            <h4 class="card-title">Ajout d'un catalogue</h4>
            <div class="form-group row">
              <label class="col-md-2 col-form-label">Titre</label>
              <div class="col-md-10">
                <input class="form-control" type="text" [(ngModel)]="Catalogue.titre">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-2 col-form-label">Description</label>
              <div class="col-md-10">
                <textarea required="" class="form-control" rows="5" [(ngModel)]="Catalogue.description"></textarea>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-2 col-form-label">Langue</label>
              <div class="col-md-10">
                <div>
                  <input type="radio" id="français" name="français" value="français" [(ngModel)]="Catalogue.langue"/>
                  <label class="ml-2" for="français">Français</label>
                </div>
                <div>
                  <input type="radio" id="anglais" name="anglais" value="anglais" [(ngModel)]="Catalogue.langue"/>
                  <label class="ml-2" for="français">Anglais</label>
                </div>
              </div>
            </div>

            <div class="form-group text-center">
              <button type="button" class="btn btn-primary waves-effect waves-light" (click)="createCatalogue()">
                Ajouter
              </button>
            </div>
          </div>
        </div>
        <!-- end row -->
        <!-- end row -->
      </div>
      <!-- container-fluid -->
    </div>
  </div>
</div>
<!-- End Page-content -->
<app-footer></app-footer>

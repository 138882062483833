<ng-container *ngIf="profile_id==11">
  Super administrateur
</ng-container>
<ng-container *ngIf="profile_id==12 || profile_id== 1">
  Administrateur de site
</ng-container>
<ng-container *ngIf="profile_id==2 || profile_id==4 || profile_id==5 || profile_id==6 || profile_id==9 || profile_id==10">
  Superviseur
</ng-container>
<ng-container *ngIf="profile_id==14 || profile_id== 3">
  Apprenant
</ng-container>

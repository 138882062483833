<app-header></app-header>
<app-sidebar></app-sidebar>
<div class="main-content">
  <div class="page-content">
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-flex align-items-center justify-content-between">
            <h4 class="mb-0 font-size-18">
              <i class="bx bx-home-circle"></i>  Bienvenue dans votre espace
              <!--<app-fildariane></app-fildariane> -->Certikap
            </h4>
            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item"><a href="javascript: void(0);">Accueil</a></li>
                <li class="breadcrumb-item active">Création d'un chapitre
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->
      <div class="row" style="padding-bottom: 5px">
        <a href="javascript:window.history.back();" class="btn btn-primary waves-effect waves-light btn-sm mb-3"><i class="mdi mdi-chevron-left ml-1"></i> Retour </a>
      </div>
      <div class="row">
        <div class="card col-12">
          <div class="card-body">
            <h4 class="card-title">Ajout d'un chapitre</h4>
            <div class="form-group row mt-3">
              <label class="col-md-2 col-form-label">Titre</label>
              <div class="col-md-10">
                <input class="form-control" type="text" [(ngModel)]="Chapitre.titre">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-2 col-form-label">Description</label>
              <div class="col-md-10">
                <textarea required="" class="form-control" rows="5" [(ngModel)]="Chapitre.description"></textarea>
              </div>
            </div>
            <div class="form-group col-md-12">
              <label class="col-md-2 col-form-label">Choix des Session(s)</label>
              <div class="col-md-12">
                <input class="form-control mt-3" type="text" id="example-text-input" placeholder="Veuillez entrer le nom de la session à rechercher" [(ngModel)]="vQuery" (keyup)="session_title_value($event)">
                <div *ngIf="FormSessionsArray.length >= 1" class="form-group row">
                  <label class="col-md-6 col-form-label">Session(s) ajoutée(s) :</label>
                  <div class="col-md-12">
                    <div cdkDropListGroup class="drop-list-group row-12">
                      <form [formGroup]="FormSessions">
                        <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                          <ng-container *ngFor="let sessionform of FormSessionsArray.controls; let i = index">
                          <ng-container *ngFor="let session of allSessions; let i = index">
                          <ng-container *ngIf="sessionform.value == session.id" >
                                <div class="example-box" cdkDrag [cdkDragData]="session" [attr.data-index]="i">
                                  <mat-accordion class="col-md-12 pl-0 pr-0">
                                    <mat-expansion-panel [expanded]="false">
                                      <mat-expansion-panel-header>
                                        <mat-panel-title>
                                          <div class="session-draggable">
                                          {{ session.title }}
                                        </div>
                                        </mat-panel-title>
                                      </mat-expansion-panel-header>
                                      <div class="form-group row">
                                        <label class="col-md-2">Description</label>
                                        <div class="col-md-10">
                                          <textarea class="col-md-12" style="width: 100%" rows="5" (input)="updateSessionDescription($event.target.value, session)">{{ session.description }}</textarea>
                                        </div>
                                      </div>
                                      <div class="form-group row">
                                        <label class="col-md-2">Durée</label>
                                        <div class="col-md-3">
                                          <input type="text" value="{{session.duree}}" (input)="changeDuree(session.id,$event.target.value)" >
                                          <input type="hidden">
                                        </div>
                                      </div>
                                      <div class="form-group row">
                                        <label class="col-md-2">Type</label>
                                        <div class="col-md-1 form-check">
                                          <input class="form-check-input" id="{{session.id}}" type="radio" name="type_{{session.id}}" value="initial" [checked]="session.type == 'initial'" (change)="changeType(session.id, 'initial')">
                                          <label class="form-check-label">
                                            Initial
                                          </label>
                                        </div>
                                        <div class="col-md-1">
                                          <input class="form-check-input" id="{{session.id}}" type="radio" name="type_{{session.id}}" value="recyclage" [checked]="session.type == 'recyclage'" (change)="changeType(session.id, 'recyclage')">
                                          <label class="form-check-label">
                                            Recyclage
                                          </label>
                                        </div>
                                      </div>
                                    </mat-expansion-panel>
                                  </mat-accordion>
                                </div>
                          </ng-container>
                          </ng-container>
                          </ng-container>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div class="table-responsive mt-3 mb-3">
                  <table class="table mb-0">
                    <thead>
                    <tr>
                      <th [class.active]="order === 'session.title'"
                          (click)="setOrder('session.title')">Nom
                        <span [hidden]="reverse">▼</span
                        ><span [hidden]="!reverse">▲</span></th>
                      <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let session of Sessions |  orderBy: order:reverse:false | filter:vQuery | paginate: { itemsPerPage: 10, currentPage: page } | slice:0:10; let i=index">
                      <td>{{session.title}}</td>
                      <td>
                        <div class="col-md-20">
                          <ng-container *ngIf="check_value(session.id) == true">
                            <input class="form-check-input" type="checkbox" checked (change)=" checkSession(session.id, $event.target.checked);" #c  value="{{session.id}}">
                            <label class="form-check-label" [style.color]="c.checked || check_value(session.id) == true ? '#0000FF':'' "></label>
                          </ng-container>
                          <ng-container *ngIf="check_value(session.id) == false">
                            <input class="form-check-input" type="checkbox" (change)=" checkSession(session.id, $event.target.checked);" #c value="{{session.id}}">
                            <label class="form-check-label" [style.color]="c.checked ? '#0000FF':'' " ></label>
                          </ng-container>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-md-12 text-center">
                  <pagination-controls
                    previousLabel="Précedent"
                    nextLabel="Suivant"
                    responsive="true"
                    (pageChange)="page = $event"></pagination-controls>
                </div>
              </div>
            </div>

            <div class="form-group text-center">
              <button type="button" class="btn btn-primary waves-effect waves-light" (click)="createChapitre()">
                Ajouter
              </button>
            </div>
          </div>
        </div>
        <!-- end row -->
        <!-- end row -->
      </div>
      <!-- container-fluid -->
    </div>
  </div>
</div>
<!-- End Page-content -->
<app-footer></app-footer>

import { Component, OnInit } from '@angular/core';
import {Session} from "../../../../models/session.model";
import Swal from "sweetalert2";
import {Groupe} from "../../../../models/groupe.model";
import {CatalogueService} from "../../../../services/catalogue.service";
import {SessionService} from "../../../../services/session.service";
import {FormArray, FormBuilder, FormControl} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {Catalogue} from "../../../../models/catalogue.model";
import {ConfigService} from "../../../../services/config.service";


@Component({
  selector: 'app-edit-catalogue-session',
  templateUrl: './edit-catalog-management.component.html',
  styleUrls: ['./edit-catalog-management.component.css']
})
export class EditCatalogManagementComponent implements OnInit {

  constructor(private catalogueService: CatalogueService,
              private sessionService: SessionService,
              private activatedRoute: ActivatedRoute,
              private configService: ConfigService,
              private formBuilder: FormBuilder,
              private router: Router) { }

  //Initialisation
  Catalogue = new Catalogue();
  CatalogueOlder = new Catalogue();

  public profile_id = parseInt(localStorage.getItem('profile_id'));
  //create ChoiceScreen
  responseMessage : string;

  ngOnInit() {
    this.ShowChoiceScreen(this.activatedRoute.snapshot.url[4].path)
  }


  ShowChoiceScreen(id) {
    this.catalogueService.getCatalogue(id)
      .subscribe(res => {
        this.Catalogue = res.catalogue;
        this.CatalogueOlder = { ...res.catalogue };
      }, (err) => {
        Swal.fire(
          'Erreur',
          'Une erreur s\'est produite lors de la récupération des informations',
          'error'
        );
      });
  }

  //UPDATE Catalogue
  updateCatalogue(id) {
    // tslint:disable-next-line:max-line-length
    if (this.Catalogue.titre === undefined || this.Catalogue.titre.trim() === '') {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Le titre est manquant'
      });
      return false;
      // tslint:disable-next-line:align
    }
    else if (this.Catalogue.description === undefined || this.Catalogue.description.trim() === '') {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Veuillez mettre une description',
      });
      return false;
      // tslint:disable-next-line:align
    }
    else if (this.CatalogueOlder.titre == this.Catalogue.titre
      && this.CatalogueOlder.description == this.Catalogue.description
      && this.CatalogueOlder.langue == this.Catalogue.langue
    ) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Aucune modification n\'a eu lieu',
      });
      return false;
    }
    else{
      const body = {
        titre: this.Catalogue.titre,
        description: this.Catalogue.description,
        langue : this.Catalogue.langue,
      };
      this.catalogueService.editCatalogue(id,body)
        .subscribe(res => {
          if(res.success == false){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: res.message
            });
          }
          else{
            // @ts-ignore: Unreachable code error
            this.Catalogue.titre = '';
            this.Catalogue.description = '';
            Swal.fire({
            title: 'Catalogue modifié !',
            text: res.message,
            icon: 'success',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Revenir sur la liste des catalogues',
            cancelButtonText: 'Rester sur la page'
          }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigate(['/dashboard/administrator/catalogue-management']);
            }
            else{
              window.location.reload();
              this.ShowChoiceScreen(this.activatedRoute.snapshot.url[4].path);
            }
          });
        }}, (err) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: this.responseMessage
          });
        });
    }
  }



}

import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {NgxSpinnerService} from 'ngx-spinner';
import Swal from 'sweetalert2';
import {SiteService} from '../../../services/site.service';
import {ConfigService} from '../../../services/config.service';
import { OrderPipe } from 'ngx-order-pipe';
import {ExportToCsv} from 'export-to-csv';
import {LoginService} from '../../../services/login.service';
import {Router} from '@angular/router';
import {tryCatch} from 'rxjs/internal-compatibility';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css']
})
export class ClientComponent implements OnInit {
  // tslint:disable-next-line:max-line-length
  constructor(private loginService: LoginService,  private router: Router, private orderPipe: OrderPipe, private userService: UserService, private spinner: NgxSpinnerService, private siteService: SiteService, private configService: ConfigService) {
    this.sortedCollection = orderPipe.transform(this.users, 'verifications.name');
  }
  page = 1;

  // tslint:disable-next-line:max-line-length
  public showLoaderExport: boolean;
  order = 'verifications.name';
  reverse = false;
  sortedCollection: any[];
  public pageList = [];
  // tslint:disable-next-line:variable-name
  public user_sites_label: any;
  // tslint:disable-next-line:variable-name radix
  public profile_id = parseInt(localStorage.getItem('profile_id'));
  // tslint:disable-next-line:variable-name radix
  public site_connected_id = parseInt(localStorage.getItem('site_id'));
  // tslint:disable-next-line:variable-name
  public user_conntected = JSON.parse(localStorage.getItem('currentUser'));
  // tslint:disable-next-line:max-line-length variable-name
  public admin_login = this.user_conntected.login;
  public users: any[];
  public Allusers: any;
  // tslint:disable-next-line:variable-name
  public user_sites: any;
  public userToExport: any[];
  // tslint:disable-next-line:variable-name
  public count_client: any;
  show = 10;
  // tslint:disable-next-line:variable-name
  number_page: any;
  vQuery: string;
  addingSuccess: boolean;

  // tslint:disable-next-line:variable-name
  site_id: any;
  sites: any;

  // tslint:disable-next-line:variable-name
  showLoadConnection: boolean;
  showLoadList: boolean;

  // tslint:disable-next-line:variable-name
  showLoader = true;

  // tslint:disable-next-line:variable-name
  showLabel = true;
  // tslint:disable-next-line:variable-name
  displayBody =  true;
  isTousChecked: boolean = true;
  isActifsChecked: boolean = false;
  isNonActifsChecked: boolean = false;
  public entityType = this.configService.entityType;

  ngOnInit() {
    this.configService.checkUSerAccessByProfile(this.profile_id);
    this.get_list_clients();
    this.get_list_sites();
  }
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }

  get_list_sites() {
    this.siteService.getSite()
      .subscribe(res => {
        this.sites = res.data;
      }, (err) => {
        console.log(err);
      });
  }

  get_client_sites(id_site, profile) {
    this.spinner.show();
    this.siteService.get_site_client(id_site, profile)
      .subscribe(res => {
        this.count_client = res.data.length;
        this.users = res.data;
        this.Allusers = [ ...this.users ];
        this.afficherTousLesUtilisateurs();
        this.spinner.hide();
      }, (err) => {
        console.log(err);
      });
  }

  get_user_sites(id_user) {
    this.showLoader = true;
    this.userService.get_user_site_list(id_user)
      .subscribe(res => {
        // @ts-ignore
        this.user_sites_label = res.data;
        this.showLoader = false;
      }, (err) => {
        console.log(err);
      });
  }

  get_list_clients() {
    this.spinner.show();
    this.userService.getListClient()
      .subscribe(res => {
        // @ts-ignore
        this.count_client = res.data.length;
        // @ts-ignore
        this.users = res.data;
        this.Allusers = [ ...this.users ];
        this.spinner.hide();
      }, (err) => {
        console.log(err);
        this.spinner.hide();
      });
  }

  increaseShow() {
    this.spinner.show();
    this.show += 10;
    this.get_list_clients();
    this.spinner.hide();
  }

  delete_client(id_client: any) {
    Swal.fire({
      title: 'Etes-vous sûr(e) ?',
      text: 'Cette action est irréversible',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui supprimer',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.value) {
        // @ts-ignore
        this.userService.deleteClient(id_client)
          .subscribe(res => {
            this.addingSuccess = true;
            this.get_list_clients();
          }, (err) => {
            console.log(err);
          });
        Swal.fire(
          'Supprimé !',
          'client supprimé avec succès !',
          'success'
        );
      }
    });
  }

  filterSite(value) {
    this.get_client_sites(value, this.profile_id);
  }

  get_user_sites_for_export(id_user) {
    this.userService.get_user_site_list(id_user)
      .subscribe(res => {
        return res;

      }, (err) => {
        console.log(err);
      });
  }
  exporter() {
    this.showLoaderExport = true;
    this.spinner.show();
    this.userService.getClient_export()
      .subscribe(res => {
        // @ts-ignore
        this.userToExport =  this.users;
        this.spinner.hide();

        const dataToExport = [];
        const options = {
          fieldSeparator: ';',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true,
          showTitle: true,
          title: 'Exportation des administrateurs de site',
          filename: 'liste_administrateurs_site_' + this.formatDate(),
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: false,
          // tslint:disable-next-line:max-line-length
          headers: ['Noms', 'Prénoms', 'Sites', 'Date de début', 'Date de fin', 'Statut']
        };
        this.userToExport.forEach((rdata, index_user) => {
          // tslint:disable-next-line:variable-name
          let nameSite = '';
          // tslint:disable-next-line:variable-name
          rdata.sites.forEach((rsite, index_site) => {
              nameSite += rsite.name + ' -- ';
            });
          const data = {
            nom: rdata.user.name,
            prenom: rdata.user.firstname,
            site: nameSite.slice(0, -3),
            date_debut: rdata.user.start_date === '01/01/1970' ? '' : rdata.user.start_date,
            date_fin: rdata.user.end_date === '01/01/1970' ? '' : rdata.user.end_date,
            status: rdata.user.active === 1 ? 'Actif' : 'Non actif',
          };
          dataToExport.push(data);
        });
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(dataToExport);
        this.showLoaderExport = false;
      }, (err) => {
        console.log(err);
      });
  }

  formatDate() {
    // tslint:disable-next-line:one-variable-per-declaration
    const d = new Date();
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    const hour = d.getHours();
    const minute = d.getMinutes();
    const seconde = d.getSeconds();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    const dateJoin = [year, month, day].join('');
    const hourJOin = [hour, minute, seconde].join('');
    return [dateJoin, hourJOin].join('_');
  }
  // tslint:disable-next-line:variable-name

  // tslint:disable-next-line:variable-name
  prise_en_main(login_t, email_t) {
    this.showLoadConnection = true;
    this.spinner.show();
    const body = {
      login: login_t,
      email: email_t
    };
    // @ts-ignore
    this.userService.take_in_hand(body)
      .subscribe(res => {
        this.loginService.logout_take_in_hand();
        // @ts-ignore
        if (res.status === 'success') {
          // @ts-ignore
          localStorage.setItem('currentUser', JSON.stringify(res.user));
          localStorage.setItem('takeInHand', 'yes');
          localStorage.setItem('admin_login', this.admin_login);
          // @ts-ignore
          localStorage.setItem('tokenCurrentConnected', JSON.stringify(res.token));
          // @ts-ignore
          this.loginService.currentUserSubject.next(res.user);
          // @ts-ignore
          localStorage.setItem('profile_id', res.profile_id);
          // @ts-ignore
          localStorage.setItem('site_id', res.site_id);
          // @ts-ignore
          if (res.profile_id === 10) {
            window.location.reload();
            this.router.navigate(['dashboard/inspector']);
            return false;
          }
          window.location.reload();
          this.router.navigate(['dashboard/administrator']);
          return false;
        }
        this.spinner.hide();
      }, (err) => {
        console.log(err);
      });
  }

  handlePageChange(event) {
    this.page = event;
  }

  get_count_client() {
    this.userService.getCountClient()
      .subscribe(res => {
        // @ts-ignore
        this.number_page = res.page_total;

        for (let i = 1; i <= this.number_page; i++) {
          const page = {
            page: 'Page ' + i,
            id:  i,
          };
          this.pageList.push(page);
        }
      }, (err) => {
        console.log(err);
      });
  }

  // tslint:disable-next-line:variable-name
  listingSiteUser(user_id, profile_id) {
    this.displayBody = false;
    this.showLoader = true;
    this.showLabel = false;
    this.userService.getUserSiteAnd_Profil_InFront(user_id, profile_id)
      .subscribe(res => {
        this.displayBody = true;
        this.showLabel = true;
        // @ts-ignore
        this.user_sites = res.sites;
        this.showLoader = false;
      }, (err) => {
        console.log(err);
      });
  }

  afficherTousLesUtilisateurs() {
    // Réinitialiser la liste des utilisateurs selon votre logique
    this.users = [...this.Allusers];
    this.count_client = this.users.length;

    // Définir isTousChecked sur true et désactiver les autres boutons
    this.isTousChecked = true;
    this.isActifsChecked = false;
    this.isNonActifsChecked = false;
  }

  // Fonction pour filtrer les utilisateurs non actifs
  filtrerUtilisateurs(actif : number) {
    this.users = this.Allusers.filter((data) => data.user.active === actif);
    this.count_client = this.users.length;

    // Définir isTousChecked sur false
    this.isTousChecked = false;
  }

}

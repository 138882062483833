import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {CertificationService} from '../../../../services/certification.service';
import {UserService} from '../../../../services/user.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-sites-certification',
  templateUrl: './sites-certification.component.html',
  styleUrls: ['./sites-certification.component.css']
})
export class SitesCertificationComponent implements OnInit {

  // tslint:disable-next-line:max-line-length
  certId: any;

  // tslint:disable-next-line:max-line-length variable-name
  count_sub_certifification: any;
  constructor(private certificationService: CertificationService, private userService: UserService, private spinner: NgxSpinnerService, private el: ElementRef, private renderer: Renderer2, private router: Router, private route: ActivatedRoute) { }
  // tslint:disable-next-line:variable-name radix
  public profile_id = parseInt(localStorage.getItem('profile_id'));
  public certificats: any;
  // tslint:disable-next-line:variable-name
  public count_certificat: any;
  show = 10;
  vQuery: string;
  addingSuccess: boolean;
// tslint:disable-next-line:variable-name
  list_certificat: any;

  ngOnInit() {
  }

}
